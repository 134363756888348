<template>
  <div class="static">
    <div class="stat">

    </div>
    <div class="contents">
      <p>{{ title }}</p>
      <p>{{ amount }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CircularStat",
  props: {
    amount: String,
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.static {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contents {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  //left:0;
  //top:0;
  //right: 0;
  //bottom: 0;
}

.stat {
  transition: 1s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
  align-items: center;
  width: 250px;
  min-height: 250px;
  border: double 5px transparent;
  border-radius: 50%;
  background-image: linear-gradient(#0a1128, #0a1128),
  radial-gradient(circle at top, #febc00, #19196f);
  background-filter: blur(4px);
  background-origin: border-box;
  background-clip: content-box, border-box;

  p {
    font-size: clamp(10px, 1vw, 14px);
  }

  &:hover {
    background-image: linear-gradient(#0a1128, #0a1128),
    radial-gradient(circle at bottom, #febc00, #19196f);
    transform: rotate(180deg);
  }
}

@media (max-width: 500px) {
  .stat {
    width: 200px;
    min-height: 200px;
  }


}
</style>
