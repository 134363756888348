<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal">
        <!--        <img-->
        <!--            @click="closeModale"-->
        <!--            class="close-icon"-->
        <!--            src="../../../assets/profile/closeIcon.svg"-->
        <!--            alt=""-->
        <!--        />-->
        <span class="message-title">   درحال اتصال مجدد... </span>
        <div class="message">
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <!--          <p>کد ۶ رقمی ارسال شده به ایمیل را وارد نمایید.</p>-->
          <!--          <p>کد ارسالی به ایمیل</p>-->
        </div>
        <!--        <verification />-->
        <!--        <button class="yes">تایید</button>-->
      </div>
    </div>
  </transition>
</template>

<script>
// import Verification from "../../tools/Verification.vue";
export default {
  name: "ConnectionModal",
  components: {
    // Verification
  },
  data() {
    return {
      close: false,
    };
  },
  methods: {
    closeModale() {
      this.$emit("closeModal");
    },
    logOut() {
      this.$logout();
      // this.$router.push('/login')
    },
  },
  created: function () {
    window.addEventListener("click", (event) => {
      if (!event.target.closest(".modal , .exit")) {
        this.$emit("closeModal", this.close);
      } else {
        return true;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(111, 111, 112, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 250;
}

.modal {
  display: flex;
  gap: 70px;
  flex-direction: column;
  align-items: center;

  min-width: 367px;
  min-height: 276px;
  background: #001e3d;
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 15px;
}

.close-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.message-title {
  font-weight: bold;
  font-size: 18px;
  color: #febc00;
}

.message {
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}


.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #febc00;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 400px) {
  .modal {
    width: 90%;
    min-width: 300px;
  }
}
</style>
