<template>
  <div class="">
    <div class="terms">
      <div class="icon">
        <InlineSvg :src="require('../../assets/public/terms.svg')"></InlineSvg>
      </div>
      <div class="terms-text" v-html="privacyPolicy">

      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import Footer from "../sharing-components/footer";

export default {
  name: "Terms",
  metaInfo:{
    title:'قوانین تاپننس',
    links:[{rel:'canonical', href:'https://topnance.com/terms'}],

    meta:[{property:'og:title', content:'قوانین تاپننس'},
      {name:'description' ,content:'موارد ذیل قوانین، مقررات  و شرایط استفاده از خدمات سایت تاپ ننس بوده و کلیه کاربران این سایت با انجام ثبت نام، کلیه شرایط و مقررات این متن (به عنوان توافق نامه) را می پذیرند.موضوع   توافق نامه حاضر، حقوق، تکالیف و مسئولیت‌ها و قوانین و دستورالعمل‌های لازم جهت استفاده‌ی کاربر، از خدمات سایت تاپ ننس می باشد که بستری برای معامله‌ی ارزهای دیجیتال را فراهم کرده است. استفاده از خدمات سایت topnance.com (ثبت‌نام، خرید، فروش ارزهای دیجیتال و سایر خدمات آن) به این معنی است که کاربر، ضمن مطالعه مفاد این توافق‌نامه، مفاد آن را کاملاً فهمیده و پذیرفته ‌است. در غیر این‌صورت، حق استفاده از خدمات topnance.com را ندارد'},
      {property:'og:title', content:'قوانین تاپننس'},
      {name:'twitter:card', content:'summary'},
      {name:'twitter:description', content:'موارد ذیل قوانین، مقررات  و شرایط استفاده از خدمات سایت تاپ ننس بوده و کلیه کاربران این سایت با انجام ثبت نام، کلیه شرایط و مقررات این متن (به عنوان توافق نامه) را می پذیرند.موضوع   توافق نامه حاضر، حقوق، تکالیف و مسئولیت‌ها و قوانین و دستورالعمل‌های لازم جهت استفاده‌ی کاربر، از خدمات سایت تاپ ننس می باشد که بستری برای معامله‌ی ارزهای دیجیتال را فراهم کرده است. استفاده از خدمات سایت topnance.com (ثبت‌نام، خرید، فروش ارزهای دیجیتال و سایر خدمات آن) به این معنی است که کاربر، ضمن مطالعه مفاد این توافق‌نامه، مفاد آن را کاملاً فهمیده و پذیرفته ‌است. در غیر این‌صورت، حق استفاده از خدمات topnance.com را ندارد'},
      {name:'twitter:title', content:'قوانین تاپننس'},
      {name:'twitter:image', content:'https://topnance.com/logo.png'},
      {property:'og:locale', content:'fa_IR'},
      {property:'og:type', content:'website'},
      {property:'og:title', content:'قوانین تاپننس'},
      {property:'og:url', content:'https://topnance.com/terms'},
    ]
  },
  data() {
    return {
      privacyPolicy: ''
    }
  },
  components: {InlineSvg, Footer},
  methods: {
    async getInfo() {
      this.state.loading=true;
      const res = await this.$axios.get('/exchange-info')


      this.privacyPolicy = res.content[0].privacyPolicy
    }
  },
  mounted() {
    this.getInfo()
  }
};
</script>

<style lang="scss" scoped>
.terms {
  padding: 0 120px;
  padding-bottom: 50px;
  background: #0a1128;
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .terms-text {
    color: white;
    font-size: clamp(16px, 1vw, 12px);
    padding: 40px 24px;
    padding-bottom: 128px;
    background: #141b31;
    border-radius: 16px;

    .nobitex-title {
      margin-top: 8%;
      text-align: center;
      color: #febc00;
    }
  }
}

@media screen and (max-width: 1000px) {
  .terms {
    padding: 20px;
  }
}

@media screen and (max-width: 500px) {
  .terms {
    .icon {
      transform: scale(0.5);
    }
  }
}
</style>
