<template>
  <div class="login">

    <table class="login-table">

      <thead>
      <tr>
        <th class="th">زمان</th>
        <th class="th tablet">آی پی</th>
        <th class="th tablet">نوع دستگاه</th>
        <th class="th phone">پلتفرم</th>
        <th class="th phone">نوع مرورگر</th>
        <th class="th">وضعیت</th>
      </tr>
      </thead>
      <perfect-scrollbar @ps-y-reach-end="check" ref="scroll">
        <tbody>
        <tr class="table-row" v-for="(item, index) in tableContents" :key="index">
          <td class="text"> {{ $G2J(item.loginRequestedDateTime, 'faDateTime') }}</td>
          <td class="text tablet">{{ item.ipAddress }}</td>
          <td class="text tablet">{{ item.deviceType }}</td>
          <td class="text phone">{{ item.osType }}</td>
          <td class="text">{{ item.browser }}</td>
          <td class="phone">
            <inline-svg v-if="item.isSuccessful" :src="require('../../../assets/profile/icons/tick.svg')"></inline-svg>
            <inline-svg v-if="item.isSuccessful ===false "
                        :src="require('../../../assets/profile/icons/xmark.svg')"></inline-svg>

          </td>
        </tr>
        </tbody>
      </perfect-scrollbar>
    </table>


  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "LoginHistory",
  components: {
    InlineSvg,

  },
  data() {
    return {
      lastPage: 1,
      tableContents: [],
      page: 0,
      loading: false
      // table: [
      //   {
      //     time: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
      //     ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
      //     platform: "Desktop",
      //     os: "Windows 10.0",
      //     browser: "Chrome 91.0.4472.114",
      //     status: require("../../../assets/profile/icons/tick.svg"),
      //   },
      //   {
      //     time: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
      //     ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
      //     platform: "Desktop",
      //     os: "Windows 10.0",
      //     browser: "Chrome 91.0.4472.114",
      //     status: require("../../../assets/profile/icons/tick.svg"),
      //   },
      //   {
      //     time: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
      //     ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
      //     platform: "Desktop",
      //     os: "Windows 10.0",
      //     browser: "Chrome 91.0.4472.114",
      //     status: require("../../../assets/profile/icons/tick.svg"),
      //   },
      //   {
      //     time: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
      //     ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
      //     platform: "Desktop",
      //     os: "Windows 10.0",
      //     browser: "Chrome 91.0.4472.114",
      //     status: require("../../../assets/profile/icons/tick.svg"),
      //   },
      //   {
      //     time: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
      //     ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
      //     platform: "Desktop",
      //     os: "Windows 10.0",
      //     browser: "Chrome 91.0.4472.114",
      //     status: require("../../../assets/profile/icons/tick.svg"),
      //   },
      //   {
      //     time: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
      //     ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
      //     platform: "Desktop",
      //     os: "Windows 10.0",
      //     browser: "Chrome 91.0.4472.114",
      //     status: require("../../../assets/profile/icons/tick.svg"),
      //   },
      //   {
      //     time: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
      //     ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
      //     platform: "Desktop",
      //     os: "Windows 10.0",
      //     browser: "Chrome 91.0.4472.114",
      //     status: require("../../../assets/profile/icons/tick.svg"),
      //   },
      //   {
      //     time: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
      //     ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
      //     platform: "Desktop",
      //     os: "Windows 10.0",
      //     browser: "Chrome 91.0.4472.114",
      //     status: require("../../../assets/profile/icons/tick.svg"),
      //   },
      //   {
      //     time: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
      //     ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
      //     platform: "Desktop",
      //     os: "Windows 10.0",
      //     browser: "Chrome 91.0.4472.114",
      //     status: require("../../../assets/profile/icons/tick.svg"),
      //   },
      //   {
      //     time: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
      //     ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
      //     platform: "Desktop",
      //     os: "Windows 10.0",
      //     browser: "Chrome 91.0.4472.114",
      //     status: require("../../../assets/profile/icons/tick.svg"),
      //   },
      //   {
      //     time: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
      //     ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
      //     platform: "Desktop",
      //     os: "Windows 10.0",
      //     browser: "Chrome 91.0.4472.114",
      //     status: require("../../../assets/profile/icons/tick.svg"),
      //   },
      //   {
      //     time: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
      //     ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
      //     platform: "Desktop",
      //     os: "Windows 10.0",
      //     browser: "Chrome 91.0.4472.114",
      //     status: require("../../../assets/profile/icons/tick.svg"),
      //   },
      //   {
      //     time: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
      //     ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
      //     platform: "Desktop",
      //     os: "Windows 10.0",
      //     browser: "Chrome 91.0.4472.114",
      //     status: require("../../../assets/profile/icons/tick.svg"),
      //   },        {
      //     time: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
      //     ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
      //     platform: "Desktop",
      //     os: "Windows 10.0",
      //     browser: "Chrome 91.0.4472.114",
      //     status: require("../../../assets/profile/icons/tick.svg"),
      //   },        {
      //     time: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
      //     ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
      //     platform: "Desktop",
      //     os: "Windows 10.0",
      //     browser: "Chrome 91.0.4472.114",
      //     status: require("../../../assets/profile/icons/tick.svg"),
      //   },        {
      //     time: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
      //     ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
      //     platform: "Desktop",
      //     os: "Windows 10.0",
      //     browser: "Chrome 91.0.4472.114",
      //     status: require("../../../assets/profile/icons/tick.svg"),
      //   },        {
      //     time: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
      //     ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
      //     platform: "Desktop",
      //     os: "Windows 10.0",
      //     browser: "Chrome 91.0.4472.114",
      //     status: require("../../../assets/profile/icons/tick.svg"),
      //   },        {
      //     time: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
      //     ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
      //     platform: "Desktop",
      //     os: "Windows 10.0",
      //     browser: "Chrome 91.0.4472.114",
      //     status: require("../../../assets/profile/icons/tick.svg"),
      //   },
      //
      //
      //
      //
      // ],


    };
  },
  methods: {
    async check() {
      if (this.page <= this.lastPage && !this.loading) { //for scroll loading
        this.loading = true
        this.page++ //for scroll loading
        const [res,] = await this.$http.get('/users/login-history', {
          params: {
            size: 10,
            page: this.page
          }
        })
        if (res) {
          this.lastPage = res.totalPages
          this.tableContents = [...this.tableContents, ...res.content] //for scroll loading
          // this.tableContents = res.content //for pagination
        }
        this.loading = false
      }
    },
  },
  mounted() {
    this.check()

  }
};
</script>

<style lang="scss" scoped>


.login {
  background: #0f1640;
  border-radius: 16px;
  height: 564px;
  max-height: 564px;

  .login-table {
    width: 100%;
    padding: 20px;
    max-height: 524px;
    overflow: scroll;

    .login-table::-webkit-scrollbar {
      display: none;
    }

    tbody {
      display: block;
      height: 100%;
      max-height: 450px;
      //overflow: scroll;
      .table-container::-webkit-scrollbar {
        display: none;
      }

    }

    tbody::-webkit-scrollbar {
      display: none;
    }

    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed; /* even columns width , fix width of table too*/
    }

    thead {
      width: calc(100% - 1em) /* scrollbar is average 1em/16px width, remove it from thead width */
    }

    table {
      width: 400px;
      height: 524px;
    }

    .th {
      color: #febc00;
      font-size: clamp(12px, 1vw, 16px);
    }

    .table-row {
      text-align: center;
      padding: 20px;
      height: 50px;

      .text {
        font-size: clamp(12px, 1vw, 16px);
      }

      .tablet {
      }

      .phone {
      }
    }
  }
}


@media screen and (max-width: 768px) {
  .tablet {
    display: none;
  }
  @media screen and (max-width: 450px) {
    .phone {
      display: none;
    }
  }
}
</style>
