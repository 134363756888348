<template>
  <div class="trade-tables">
    <div class="prices">
      <prices-table/>
    </div>
    <div class="buy-depth">
      <buy-depth :key="'buy'+ state.changeOrder + $route.params.tradeTo + $route.params.tradeFrom"/>
    </div>
    <div class="sell-depth">
      <sell-depth :key="'sell'+ state.changeOrder + $route.params.tradeTo + $route.params.tradeFrom"/>
    </div>

  </div>
</template>

<script>
import PricesTable from "@/components/profile/trade/PricesTable";
import BuyDepth from "@/components/profile/trade/BuyDepth";
import SellDepth from "@/components/profile/trade/SellDepth";

export default {
  name: "TradeTables",
  components: {SellDepth, BuyDepth, PricesTable}
}
</script>

<style lang="scss" scoped>
.trade-tables {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  row-gap: 8px;


}

</style>
<style lang="scss">
@media (max-width: 1431px) {
  .trade-tables {
    flex-direction: row;
  }
}

@media screen and (max-width: 485px) {

}

</style>