  <template>
  <div class="">

    <div class="market container">
      <div class="page-title">
        <div class="titles">
          <p>بازارها</p>
          <div class="search-bar">
            <input type="text" placeholder="جستجو" v-model="regex" />
            <button>

            </button>
          </div>
        </div>
<div class="image-holder d-flex mb-3">



    <div v-if="image" class="d-flex flex-column align-items-center">
    <img  :src="image" alt="" width="200" height="153" class="align-self-center">
    <h4 class="text-center">{{coin}}</h4>
  </div>


    <img v-if="image ===''"  src="../../assets/public/market.png" alt="market"  width="100%" height="100%"/>

</div>
      </div>
      <!-- <CryptoTable/> -->
      <market-table class="mb-2" :regex="regex"  @copyImage="getImage" @setDefaultImage="defaultImage"/>
    </div>
    <Footer />
  </div>
</template>

<script>
// import CryptoTable from "@/components/home-page/CryptoTable";
import Footer from "@/components/sharing-components/footer";
import MarketTable from "./MarketTable.vue";


export default {
  name: "Market",
  metaInfo:{
    title:'بازارها',
    links:[{rel:'canonical', href:'https://topnance.com/market'}],
    meta:[{property:'og:title', content:'بازارها'},
      {property:'og:title', content:'بازارها'},
      {property:'og:locale', content:'fa_IR'},
      {property:'og:type', content:'website'},
      {property:'og:title', content:'بازارها'},
        {name:'twitter:card', content:'summary'},
        {name:'twitter:description', content:'بازارها'},
        {name:'twitter:title', content:'بازارها'},
        {name:'twitter:image', content:'https://topnance.com/logo.png'},
      {property:'og:url', content:'https://topnance.com/market'},
    ]
  },
  data() {
    return {
      regex: "",
      image:'',
      coin:'',
    };
  },
  methods:{
    defaultImage(){
      // console.log('hi default image')
      // this.image = require('@/assets/public/market.png');
      // this.coin ='';
    },
    getImage(image){
 let img = this.$newCoins[image.relatedCoin].link;
      let coin = this.$newCoins[image.relatedCoin].persianName;
 console.log(img);
 this.image = img;
 this.coin =coin;

    }
  },
  components: {

    // CryptoTable
    Footer,
    MarketTable,
  },

};
</script>

<style lang="scss" scoped>
.mb-2{
  margin-bottom: 2rem;
}
.market {
  padding: 6% 0;
}

.title {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.page-title {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .titles {
    max-width: 453px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .search-bar {
      padding: 2px 10px;
      margin-top: 10px;
      margin-bottom: 12px;
      width: 100%;
      max-width: 310px;
      max-height: 40px;
      justify-content: center;
      align-items: center;
      border: 1px solid #279bc0;
      box-sizing: border-box;
      border-radius: 16px;
      display: flex;

      > input {
        background: transparent;
        border: 0;
        width: 100%;
      }

      > button {
        background: transparent;
        border: 0;

        > svg {
          margin-top: 2px;
          margin-left: 5px;
        }
      }
    }

    > p {
      font-size: clamp(20px, 2vw, 40px);
      color: #febc00;
    }
  }
}
.image-holder{
  max-width: 200px;
  max-height: 200px;

}
@media (max-width: 600px) {
  .search-bar {
    width: 50% !important;
  }
  .page-title {
    img {
      display: none;
    }
  }
}
</style>
