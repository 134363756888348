<template>
  <div class="stats">
    <div class="container">
      <CircularStat :amount=totaltrad title="تعداد معاملات"/>
      <CircularStat :amount=$toLocal(tradprice,0) title="ارزش معاملات انجام شده"/>
      <CircularStat :amount=userscount title="تعداد کاربران"/>

    </div>
  </div>
</template>

<script>
import CircularStat from "@/components/tools/CircularStat";

export default {
  name: "Stats",
  data() {
    return {
      userscount: '0',
      tradprice: '0',
      totaltrad: '0'
    }
  },
  components: {CircularStat},
  methods: {
    async getInfo() {
      const res = await this.$axios('trades/market-info-home')
      this.userscount = res.baseDTO.userCount
      this.tradprice = res.baseDTO.tradesValue
      this.totaltrad = res.baseDTO.tradesAmount
    }
  },
  mounted() {
    this.getInfo()
  }
}
</script>

<style lang="scss" scoped>

.stats {


  display: flex;

  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;

  align-items: center;
  background: #141B31;
  background-size: cover;
  min-height: 447px;

  .container {
    max-width: 1440px;
    backdrop-filter: blur(200px);
    min-height: 449px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

  }

  &::before {
    content: '';
    border-radius: 4px;
    position: absolute;
    z-index: -1;
    width: 100%;

    height: 438px;
    background-color: transparent;
    background-image: linear-gradient(#19196F 0%, #FEBC00 100%);
    filter: blur(20px);

    transition: 0.5s ease;
  }

}

@media screen and (max-width: 800px) {
  .stats {
    .stat {
      min-width: unset !important;
      min-height: unset !important;
      width: 200px;
      height: 200px;
    }
  }

  .container {
    flex-direction: column;
    padding: 10px 0px;
  }

}
</style>