<template>
  <div class="tables-container">
    <div class="tables">
      <p class="title">اخرین معاملات</p>
      <div class="btn-table">
        <div class="table">
          <table>

            <tr class="tr-head">
              <td class="gray">مقدار({{ $coins[tradeFrom].code }})</td>
              <td class="gray">
                <span>قیمت</span>
                <span>({{ $coins[tradeTo].persianName }})</span>
              </td>
              <td class="gray">زمان</td>
            </tr>

            <tbody>

            <tr v-for="(tableContent , index) in tableContents" :key="index">
              <td class="number-font">
                {{ $toLocal(tableContent.amount, $decimal[tradeFrom]) }}
              </td>
              <td :class="{'sell' : tableContent.orderType.includes('BUY') , 'buy' : tableContent.orderType.includes('SELL')}"
                  class="number-font">
                {{ $toLocal(tableContent.unitPrice, (tradeTo === 'TOMAN' ? 3: 3)) }}
              </td>

              <td class="number-font">
                {{ $G2J(tableContent.createdAtDateTime, 'time') }}
              </td>
            </tr>
            </tbody>

          </table>
          <div v-if="!tableContents.length">
            <p style="width:100%;text-align:center;color:red">در حال حاضر اطلاعاتی وجود ندارد</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {tradeTo, tradeFrom} from "@/library/reuseableFunction";

export default {
  name: 'Marketnumbers',
  data() {
    return {
      switchbtn: false,
      tableContents: [],
      loading: false,
      alive: true
    }
  },
  computed: {
    tradeTo,
    tradeFrom
  },
  methods: {
    async getLastTrades() {
      const res = await this.$axios.get('/trades/last-trades?type=P2P', {
        params: {
          marketType: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
          size: 20
        }
      })
      this.tableContents = res.content
      this.loading = false
    },
    async loop() {
      // this.state.loading = true
      this.loading = true

      while (this.alive) {
        await this.getLastTrades()
        this.loading = false
        await this.$sleep(5000)
      }
    }
  },
  mounted() {
    this.alive = true
    if (this.state.loop) {
      this.loop()
    } else {
      this.getLastTrades()
    }
  },
  beforeDestroy() {
    this.alive = false
  }

}
</script>

<style lang="scss" scoped>
.tr-head {
  background: var(--blue-bg);
  position: sticky;
  top: 0;
  z-index: 10;

}

thead {
  td {
    font-weight: 400;

    font-size: 12px;
  }
}

tbody {
  tr {
    cursor: pointer;
  }

  td {
    height: 35px;
    font-weight: 400;
    font-size: clamp(10px, 2vw, 14px);

  }
}

.table {
  position: relative;
  box-sizing: border-box;
  padding: 10px;
  height: 100%;
  // max-height: 285px;
  //overflow: scroll;

}

.tables-container {
  border-radius: 4px;
  z-index: 5;
  width: 100%;
  max-width: 238px;
}

.tables {
  .title {
    margin: 8px 8px 0 0;
    font-size: 16px;
  }

  display: flex;
  flex-direction: column;
  max-height: 404px;
  height: 100%;
}

.btn-table {

  height: 50%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &.btn-table::-webkit-scrollbar {
    display: none;
  }
}

.table {
  display: flex;
  flex-direction: column;
}


@media only screen and (max-width: 1068px) {
  .tables-container {

    max-width: none;
  }
}
</style>