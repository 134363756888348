<template>
  <div class="timer" v-if="finished">
  </div>
  <div class="timer" v-else>
    {{ display }}
  </div>
</template>

<script>
import {DateTime, Duration} from "luxon";

export default {
  data() {
    return {
      now: DateTime.local().set({milliseconds: 0}),
      end: DateTime.local().set({milliseconds: 0}).plus({seconds: 120}),
      tick: null,
    };
  },
  watch: {
    now() {
      if (this.finished) {
        clearInterval(this.tick);
      }
    },
  },
  computed: {
    remaining() {
      return this.end.diff(this.now).toObject();
    },
    display() {
      return Duration.fromObject(this.remaining).toFormat("mm:ss");
    },
    finished() {
      return this.now >= this.end;
    },
  },
  mounted() {
    this.tick = setInterval(() => {
      this.now = DateTime.local().set({milliseconds: 0});
    }, 1000);
  },
  methods: {}
};
</script>

<style>
.timer {
  margin-top: 2px;
  width: 100%;
  color: #febc00;
  font-size: 12px;
}
</style>
