import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ContactUs from "../components/public-page/ContactUs.vue";
import Terms from "../components/public-page/Terms.vue";
import AboutUs from "../components/public-page/AboutUs.vue";
import FAQ from "../components/public-page/FAQ.vue";
import Dummy from "@/components/Dummy";
import Profile from "../views/profile";
import Dashboard from "@/components/profile/dashboard/Dashboard";
import Otc from "../components/profile/otc/Otc.vue";
import Settings from "../components/profile/settings/Settings.vue";
import InviteFriends from "../components/profile/invite/InviteFriends.vue";
import Wallet from "../components/profile/wallet/Wallet.vue";
import History from "../components/profile/history/History.vue";
import Authentication from "@/components/login-signup/Authentication";
import Wages from "@/components/public-page/Wages";
import Market from "@/components/public-page/Market";
import Trade from "@/components/profile/trade/Trade";
import Account from "@/components/profile/account/Account";
import Finotech from "../components/profile/account/Fiontech.vue";
import walletEx from "../components/profile/wallet/walletEx.vue";
import coins from '../components/home-page/coins.vue'
import Meta from 'vue-meta'

const that = Vue.prototype;

Vue.use(VueRouter);
Vue.use(Meta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
    refreshOnceOnNavigation: true
})

const routes = [
    {path: "*", redirect: "/"},
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/fromgoogle",
        name: "FromGoogle",
        component: () => import("../components/public-page/FromGoogle.vue"),
    },
    {
        path: '/guide/',
        name: 'Guide',
        component: () => import('../components/public-page/guide.vue'),
    },
    {
        path: "/dummy",
        name: "Dummy",
        component: Dummy,
    },
    {
        path: "/contact-us/",
        name: "ContactUs",
        component: ContactUs,
    },
    {
        path: "/terms/",
        name: "Terms",
        component: Terms,
    },
    {
        path: "/about-us/",
        name: "AboutUs",
        component: AboutUs,
    },
    {
        path: "/markets/",
        name: "Markets",
        component: Market,
    },
    {
        path: "/faq",
        name: "FAQ",
        component: FAQ,
    },
    {
        path: "/market/:coin/",
        params: true,
        props: true,
        name: 'coins',
        component: coins
    },
    {
        path: "/wages",
        name: "Wages",
        component: Wages,
    },
    {
        path: "/redirect-from-banks",
        redirect: "/profile/redirect-from-banks",
    },
    {
        path: "/auth",
        name: Authentication,
        component: Authentication,
    },
    {
        path: "/sign-up",
        name: "SignUp",
        component: Authentication,
    },
    {
        path: "/sign-up/email-verification",
        name: "EmailVerification",
        component: Authentication,
    },
    {
        path: "/login",
        name: "Login",
        component: Authentication,
    },
    {
        path: "/login/send-mail",
        name: "SendMail",
        component: Authentication,
    },

    {
        path: "/login/send-phone",
        name: "SendPhone",
        component: Authentication,
    },
    {
        path: "/login/forget",
        name: "Forget",
        component: Authentication,
    },
    {
        path: "/login/forget-otp",
        name: "ForgetOTP",
        component: Authentication,
    },
    {
        path: "/login/forget/verify",
        name: "NewPassword",
        component: Authentication,
    },
    {
        path: "/profile",
        name: "profile",
        component: Profile,
        children: [
            {
                path: "",
                name: "dashboard",
                component: Dashboard,
            },
            {
                path: "otc",
                name: "otc",
                component: Otc,
            },
            {
                path: "settings",
                name: "settings",
                component: Settings,
            },
            {
                path: "invite",
                name: "invite",
                component: InviteFriends,
            },
            {path: "wallet", name: "wallet", component: Wallet},
            {path: "walletEx", name: "walletEx", component: walletEx},
            {
                path: "history",
                name: "history",
                component: History,
            },
            {
                path: "trade",
                redirect: "trade/TOMAN/BITCOIN",
                name: "trade",
                component: Trade,
            },
            {
                path: "trade/TETHER/TETHER",
                redirect: "trade/TOMAN/TETHER",
            },
            {
                path: "trade/TOMAN/TOMAN",
                redirect: "trade/TOMAN/TETHER",
            },
            {
                path: "trade/:tradeTo/:tradeFrom",
                component: Trade,
                name: "trade",
                props: true,
            },
            {
                path: "account",
                name: "Account",
                component: Account,
            },
            {
                path: "fino-verifying",
                name: "Finotech",
                component: Finotech,
            },
            {
                path: "redirect-from-banks",
                name: "RedirectFromBank",
                component: () =>
                    import("../components/profile/wallet/RedirectFromBank.vue"),
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {x: 0, y: 0};
    },
});

//the routes that disabled for supervisors.
const disabledRoutes = [];
/*
 * this is the guard for routes that shouldn't
 * load without Login
 * */
const forLogin = [
    "dashboard",
    "Account",
    "otc",
    "trade",
    "history",
    "wallet",
    "invite",
    "settings",
    "profile",
];
//Users that did not authorized must not access these pages
const unAuthorUser = [
    "history",
    "wallet",
    "dashboard",
    "wallet",
    "walletEx",
    "otc",
    // "trade",
];
const otp = [
    // "SendPhone", "SendMail"
];
const auth = [
    // 'Login',
    // "SignUp",
    // "Forget",
];

router.beforeEach(async (to, from, next) => {
    that.state.loading = true;


    if (!that.state.coins) {
        await that.$getCoins();
    }


    if (that.state.token) {
        if (!that.state.gotten) {
            that.state.loading = true;
            await that.$getuserInfo();
            that.state.loading = true;
            await that.$getFees();
        }

        // if(that.state.token && that.state.userInfo.authorized){
        //     next()
        // }
        // if(that.state.token && that.state.userInfo.authorized){
        //     next({name: "Account"});
        // }
        that.state.loading = true;
        if (that.state.userInfo.supervisor && disabledRoutes.includes(to.name)) {
            that.$error(
                "",
                "با عرض پوزش قابلیت مورد نظر برای بازار گردان غیر فعال است"
            );
            next(from);
        }
        if (from.name === "Blog") {
            await window.open(to.fullPath, "_self");
        } else if (otp.includes(to.name) || auth.includes(to.name)) {
            next({name: "HomePage"});
        } else if (to.name === "Trade") {
            if (!to.params.tradeFrom || !to.params.tradeTo) {
                next("/profile/trade/TOMAN/BITCOIN");
            } else {
                try {
                    that.state.loading = true;
                    await that.$axios(
                        `/markets/${to.params.tradeFrom}_${to.params.tradeTo}`
                    );
                    next();
                } catch (e) {
                    if (e.response.data.message === "MARKET_IS_NOT_ACTIVE") {
                        if (otp.includes(from.name)) {
                            next({name: "HomePage"});
                        } else {
                            await that.$error("بازار غیرفعال است");
                            next(from);
                        }
                    }
                }
            }
        } else {
            if (that.state.userInfo.authorized) {
                next();
            } else {
                if (otp.includes(from.name) && to.name !== "Account") {
                    next({name: "Account"});
                } else if (unAuthorUser.includes(to.name)) {
                    that.state.loading = false;
                    const res = await that.$error(
                        "عدم دسترسی",
                        "برای دسترسی به این بخش نیاز است ابتدا حساب شما تکمیل و تایید شود",
                        "error",
                        "حساب کاربری"
                    );
                    if (res.isConfirmed) {
                        next("/profile/account");
                    } else {
                        next(from);
                    }
                } else next();
            }
        }
    } else {
        if (
            (otp.includes(to.name) && !Vue.$cookies.isKey("username")) ||
            forLogin.includes(to.name)
        )
            next({name: "Login"});
        else {
            next();
        }
    }
    that.state.loading = false;
});

export default router;
