<template>
  <div class="history">
    <div class="btns">

      <div class="radio">
        <input
            id="radio-1"
            name="radio"
            type="radio"
            @change="activeTrade"
            checked
        />
        <label for="radio-1" class="radio-label">معاملات </label>
      </div>

      <div class="radio">
        <input id="radio-2" name="radio" type="radio" @change="activeRial"/>
        <label for="radio-2" class="radio-label">تراکنش ریالی</label>
      </div>

      <div class="radio">
        <input id="radio-3" name="radio" type="radio" @change="activeBtc"/>
        <label for="radio-3" class="radio-label">تراکنش رمز ارزی</label>
      </div>
      <div class="radio">
        <input id="radio-4" name="radio" type="radio" @change="activeLogin"/>
        <label for="radio-4" class="radio-label">لاگین</label>
      </div>
    </div>
    <TradeHistory v-if="trade"/>
    <TransactionRial v-if="rial"/>
    <TransActionBTC v-if="btc"/>
    <LoginHistory v-if="login"/>
  </div>
</template>

<script>
import TradeHistory from "./TradeHistory.vue";
import TransactionRial from "@/components/profile/history/TransactionRial";
import TransActionBTC from "@/components/profile/history/TransActionBTC";
import LoginHistory from "@/components/profile/history/LoginHistory";

export default {
  name: "History",
  components: {TradeHistory, TransactionRial, TransActionBTC, LoginHistory},
  data() {
    return {
      trade: true,
      rial: false,
      btc: false,
      login: false,
    };
  },
  methods: {
    activeTrade() {
      this.trade = true;
      this.rial = false;
      this.btc = false;
      this.login = false;
    },
    activeRial() {
      this.rial = true;
      this.trade = false;
      this.btc = false;
      this.login = false;
    },
    activeBtc() {
      this.btc = true;
      this.rial = false;
      this.trade = false;
      this.login = false;
    },
    activeLogin() {
      this.login = true;
      this.rial = false;
      this.trade = false;
      this.btc = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.history {
  display: flex;
  flex-direction: column;
  width: 100%;

  row-gap: 28px;
  padding: 3%;

  .btns {
    $color1: #279bc0;
    $color2: #fff;
    display: flex;
    column-gap: 20px;
    flex-wrap: wrap;

    .radio {
      input[type="radio"] {
        position: absolute;
        opacity: 0;

        label {
          font-size: clamp(12px, 1vw, 16px);
        }

        + .radio-label {
          &:before {
            font-size: clamp(12px, 1vw, 16px);
            margin-top: 4px;
            margin-left: 4px;
            content: "";
            background: $color2;
            border-radius: 100%;
            border: 1px solid darken($color1, 25%);
            display: inline-block;
            width: 20px;
            height: 20px;
            position: relative;
            top: -0.2em;
            box-shadow: inset 0 0 0 5.5px $color1;
            vertical-align: top;
            cursor: pointer;
            text-align: center;
            transition: all 250ms ease;
          }
        }

        &:checked {
          + .radio-label {
            color: #febc00;

            &:before {
              background: #febc00;
              box-shadow: inset 0 0 0 5.5px #febc00;
            }
          }
        }

        &:focus {
          + .radio-label {
            &:before {
              outline: none;
              border-color: #febc00;
            }
          }
        }

        &:disabled {
          + .radio-label {
            &:before {
              box-shadow: inset 0 0 0 4px $color1;
              border-color: darken($color1, 25%);
              background: darken($color1, 25%);
            }
          }
        }

        + .radio-label {
          &:empty {
            &:before {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .history {
    padding: 0;

    .btns {
      flex-direction: column;
      gap: 10px;
    }
  }
}
</style>
