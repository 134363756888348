<template>
  <div class="footer">
    <div class="container">
      <div class="social">
        <img class="logo" alt="logo" src="../../assets/public/52.png" width="100%" height="40"/>
        <div class="social-media">
          <a :href="info.extraDataList.find(key=>key==='instagram')">
            <InlineSvg
                :src="require('../../assets/public/instagram-logo.svg')"
            ></InlineSvg>
          </a>
          <a :href="info.extraDataList.find(key=>key==='telegram')">
            <InlineSvg
                :src="require('../../assets/public/telegram-logo.svg')"
            ></InlineSvg>
          </a>
          <a :href="info.extraDataList.find(key=>key==='whatsapp')">
            <InlineSvg
                :src="require('../../assets/public/whatsapp-logo.svg')"
            ></InlineSvg>
          </a>
        </div>
      </div>
      <div class="guides">
        <ul>
          <li class="title-list">راهنما</li>
          <li v-for="(item, index) in guides" :key="index">
            <a :href="item.link" @click.prevent="$router.push(item.link)">
              {{ item.title }}
            </a>
          </li>
        </ul>
      </div>
      <div class="fast-access">
        <ul>
          <li class="title-list">دسترسی سریع</li>
          <li >
            <a href="https://topnance.com/blog" @click="$router.push('/blog')">اخبار</a>
            <a href="" @click.prevent="$router.push('/guide')">آموزش</a>
            <a href="" @click.prevent="$router.push('/wages')">کارمزد</a>
            <a href="" @click.prevent="$router.push('/about-us')">درباره ی ما</a>
          </li>
        </ul>
      </div>
      <div class="contact-us">
        <p id="title">تماس با ما</p>
        <p>{{ info.address }}</p>
        <p>{{ info.number }}</p>
        <p>{{ info.email }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "FooterComponent",
  components: {
    InlineSvg,
  },
  data() {
    return {
      info: {},
      guides: [
        {
          title: "راهنمای استفاده",
          link: "/faq",
        },
        {
          title: "سوالات متداول",
          link: "/guide",
        },
        {
          title: "قوانین و مقررات",
          link: "/terms",
        },
        {
          title: " پشتیبانی ",
          link: "/contact-us",
        },
      ],
      fastAccess: [
        {
          title: "آموزش",
          link: "/faq",
        },
        {
          title: "کارمزد",
          link: "/wages",
        },
        {
          title: "درباره ما ",
          link: "about-us",
        },
      ],
    };
  },
  methods: {
    async getInfo() {
      const res = await this.$axios.get('/exchange-info')
      // console.log('exchange-info',res.content[0])
      this.info = res.content[0] || {
        address: '',
        appAndroid: '',
        appIOS: '',
        email: '',
        instagram: '',
        linkedin: '',
        number: '',
        telegram: '',
        twitter: ''
      }
    }
  },
  mounted() {
    this.getInfo()
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  background: #0f1640;

  .container {
    flex-wrap: wrap;
    padding: 0 10px;
    min-height: 340px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .social {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;

      > img {
        //width: 150px;
        height: 186px;
      }

      .social-media {
        display: flex;
        justify-content: center;
gap: 3em;
        //> svg {
        //  margin: 36px 10px;
        //}
      }
    }

    .guides {
      > ul {
        .title-list {
          color: white;
          font-size: clamp(14px, 1vw, 16px);
          font-weight: 600;
        }

        > li {
          display: flex;
          flex-direction: column;

          > a {
            font-weight: 400;
            margin-top: 16px;
            font-size: clamp(14px, 1vw, 16px);
          }
        }
      }
    }

    .fast-access {
      > ul {
        > p {
          color: white;
          font-size: clamp(16px, 1vw, 14px);
          font-weight: 600;
        }

        > li {
          display: flex;
          flex-direction: column;

          > a {
            font-weight: 400;
            margin-top: 16px;
          }
        }
      }
    }

    .contact-us {
      display: flex;
      flex-direction: column;
      min-height: 231px;

      #title {
        font-weight: 600;
      }

      > p {
        color: white;
        font-weight: 400;
        margin-top: 16px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .footer {
    padding: 10px 20px;
    gap: 3em;
  }
}

@media(max-width: 500px){
  .footer {
    padding:0;
  }

  .container {
    padding: 0 10px;
column-gap: 10%;
    row-gap: 0;
  }


.social-media{
  svg {
    width: 16px !important;
    height: 16px !important;
  }
}




}
</style>
