<template>
  <transition name="modal-fade" v-if="closeIt">
    <div class="modal-backdrop" @click.self="closeModale">
      <div class="modal">
        <img
            @click="closeModale"
            class="close-icon"
            src="../../../assets/profile/closeIcon.svg"
            alt=""
        />
        <div class="message">
          <span class="message-title">
            برداشت {{ $coins[relatedCoin].persianName }}
          </span>
          <div class="warning">
            <div class="logo">
              <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_559_4792)">
                  <path
                      d="M11.8402 0C9.83061 0 7.86618 0.645139 6.1953 1.85383C4.52442 3.06253 3.22213 4.78049 2.45311 6.79048C1.68409 8.80047 1.48288 11.0122 1.87492 13.146C2.26697 15.2798 3.23466 17.2398 4.65562 18.7782C6.07659 20.3166 7.88701 21.3642 9.85795 21.7886C11.8289 22.2131 13.8718 21.9952 15.7284 21.1627C17.585 20.3301 19.1718 18.9202 20.2883 17.1113C21.4047 15.3023 22.0006 13.1756 22.0006 11C21.9977 8.08359 20.9263 5.28753 19.0215 3.22531C17.1166 1.16309 14.534 0.00315432 11.8402 0V0ZM11.8402 20.1667C10.1655 20.1667 8.52851 19.629 7.13611 18.6218C5.74371 17.6146 4.65847 16.1829 4.01762 14.5079C3.37677 12.8329 3.20909 10.9898 3.53579 9.21167C3.8625 7.43351 4.66891 5.80017 5.85305 4.51819C7.03718 3.23621 8.54587 2.36317 10.1883 2.00947C11.8308 1.65577 13.5332 1.8373 15.0804 2.5311C16.6275 3.22491 17.9499 4.39982 18.8803 5.90727C19.8106 7.41472 20.3072 9.187 20.3072 11C20.3047 13.4303 19.4119 15.7604 17.8245 17.4789C16.2372 19.1974 14.085 20.164 11.8402 20.1667Z"
                      fill="#E63756"
                  />
                  <path
                      d="M11.8408 4.5835C11.6163 4.5835 11.4009 4.68007 11.2421 4.85198C11.0833 5.02389 10.9941 5.25705 10.9941 5.50016V12.8335C10.9941 13.0766 11.0833 13.3098 11.2421 13.4817C11.4009 13.6536 11.6163 13.7502 11.8408 13.7502C12.0654 13.7502 12.2808 13.6536 12.4396 13.4817C12.5983 13.3098 12.6875 13.0766 12.6875 12.8335V5.50016C12.6875 5.25705 12.5983 5.02389 12.4396 4.85198C12.2808 4.68007 12.0654 4.5835 11.8408 4.5835Z"
                      fill="#E63756"
                  />
                  <path
                      d="M12.6875 16.5002C12.6875 15.9939 12.3085 15.5835 11.8408 15.5835C11.3732 15.5835 10.9941 15.9939 10.9941 16.5002C10.9941 17.0064 11.3732 17.4168 11.8408 17.4168C12.3085 17.4168 12.6875 17.0064 12.6875 16.5002Z"
                      fill="#E63756"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_559_4792">
                    <rect width="22" height="22" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <p></p>
            <div class="warning-text">
              <p>
                درصورت تمایل به برداشت موجودی کیف پول های خود،درخواست خودرا
                اینجا ثبت نمایید.
              </p>
              <p>درخواست ها به صورت دستی و سپس پرداخت خواهند شد.</p>
              <p>کارمزدهای معمول انتقال از مبلغ برداشت کسر خواهد شد.</p>
            </div>
          </div>

        </div>


        <div class="amount">

          <template>
            <label  class="selectd-net-lable">
              <div class="selectd-net" @click="drop = !drop">
                <span v-if="selected_net">{{ selected_net }}</span>
                <span v-else>انتخاب شبکه</span>
              </div>
              <div class="dropdown" v-if="drop">
                <div v-for="(net , index) in nets" :key="index" @click="selectFun(index)">
                  {{ net.tokenType }}
                </div>
              </div>
            </label>
          </template>


          <input
              @input="amount = $toLocal(amount)"
              v-model="amount"
              name="wallet"
              type="text"
              :placeholder="`مقدار برداشت (${$coins[relatedCoin].code})`"
              class="normal-input"
          />
          <div class="eth-fee" v-if="selected_net">
            <div class="fee">
              <p>
                کارمزد انتقال در شبکه اتریوم : {{ wage }}
                {{ $coins[relatedCoin].code }}
              </p>
            </div>
            <div class="min">
              <p>حداقل میزان برداشت : {{ min }}</p>
            </div>
            <div class="min">
              <p>حداکثر میزان برداشت : {{ max }}</p>
            </div>
          </div>
          <p>
            موجودی قابل برداشت شما
            <span class="price">{{ $toLocal($S2N(cash), 3) }}</span>
            {{ $coins[relatedCoin].code }}
          </p>
        </div>
        <input
            class="normal-input"
            placeholder="کیف پول مقصد"
            name="target"
            type="text"
            v-model="walletAddress"
        />
        <div class="code-sent">
          <input
              class="normal-input"
              placeholder="کد ارسال شده را وارد کنید"
              v-if="continu"
              name="code"
              type="text"
              v-model="code"
          />

          <div class="timer" v-if="continu"> {{ countDown }}</div>
        </div>
        <button v-if="otpInput && countDown!==0" class="cont" @click="submitWithdrawRequest" >
          <!-- v-if="otpInput && countDown!==0" -->
          برداشت
        </button>
        <button v-if="otpInput===false" class="cont" @click="withdraw" :disabled="!!disabled">
          <!-- v-if="otpInput===false && countDown==0" -->
          <span v-if="!!disabled">{{ disabled }}</span>
          <span v-else> ارسال کد تایید</span>
        </button>
        <button v-if="otpInput===true && countDown===0" class="submit" @click.prevent="send">
          ارسال مجدد
        </button>

      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ExitModal",
  components: {},
  props: {
    relatedCoin: {
      default: "",
    },
    cash: {
      default: 0,
    },
    wage: {
      default: 0,
    },
    role: {
      default: {},
    },
    exchange: {
      default: false,
    },
    nets: {
      default: [],
    },
  },
  data() {
    return {
      closeIt: true,
      continu: false,
      submit: false,
      success: false,
      amount: "",
      walletAddress: "",
      local: false,
      token: false,
      transaction: {
        amount: "",
        destinationWalletAddress: "",
        relatedCoin: "",
          tokenType: "",
        code: "",
        // walletType: this.exchange ? "EXCHANGE" : "P2P",
      },
      towStepAuthenticationType: "",
      otpInput: false,
      counter: false,
      timeOut: "",
      countDown: 120,
      code: "",
      selected_net: "",
      txIdIsNeeded: false,
      drop: false,
      enable: false,
    };
  },
  computed: {
    disabled() {
      let Errors = ''
      if (!this.selected_net) {
        Errors = 'انتخاب شبکه'
      } else if (!this.$S2N(this.amount)) {
        Errors = "مقدار برداشت را وارد کنید"
      } else if (this.$S2N(this.amount) > this.$S2N(this.cash)) {
        Errors = "مقدار برداشت بیشتر از موجودی کیف پول است"
      } else if (this.$S2N(this.amount) > this.$S2N(this.max)) {
        Errors = "مقدار برداشت بیشتر از حداکثر میزان برداشت می باشد"
      } else if (this.$S2N(this.amount) < this.$S2N(this.min)) {
        Errors = "مقدار برداشت کمتر از حداقل میزان برداشت می باشد"
      } else if (!this.walletAddress) {
        Errors = "آدرس کیف پول مقصد را وارد کنید"
      }
      // return (
      //   !this.$S2N(this.amount) ||
      //   !this.walletAddress ||
      //   this.$S2N(this.amount) > this.$S2N(this.cash) ||
      //   this.$S2N(this.amount) > this.$S2N(this.max) ||
      //   this.$S2N(this.amount) < this.$S2N(this.min)
      // );
      return Errors
      // ( !this.enable )
      // (!this.selected_net)
    },
    // min() {
    //   return this.role.;
    // },
    // max() {
    //   return this.role.maxWithdrawAmount;
    // },
    danger() {
      return (
          "حداقل " +
          this.$toLocal(this.min) +
          " " +
          this.$coinLabel[this.relatedCoin] +
          "\n" +
          "حداکثر " +
          this.$toLocal(this.max) +
          " " +
          this.$coinLabel[this.relatedCoin]
      );
    },
  },
  methods: {
    countDownTimer() {
      this.continu = true;
      if (this.countDown > 0) {
        this.timeOut = setTimeout(() => {
          this.countDown--;
          this.countDownTimer();
        }, 1000);
      } else {
        this.$error("زمان شما به اتمام رسید", "");
      }
    },
    closeModale() {
      this.$emit("closeModaleEmit");
    },
    async withdraw() {
      if (!this.disable && !this.state.loading) {
        this.state.loading = true;
        (this.transaction.amount = this.$S2N(this.amount)),
            (this.transaction.destinationWalletAddress = this.walletAddress),
            (this.transaction.relatedCoin = this.relatedCoin);
        if ("TETHER" === this.relatedCoin) {
          this.transaction.cryptoNetwork = this.token ? "ERC20" : "TRC20";
        }
        const res = await this.$axios.post("/users/send-code");
        this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType;
        this.otpInput = true;
        if (this.towStepAuthenticationType !== "GOOGLE_AUTHENTICATOR") {
          this.counter = true;
          this.countDownTimer();
        }
      }
    },
    async send() {
      this.state.loading = true;
      this.code = "";

      const a = {
        EMAIL: "ایمیل",
        SMS: "شماره",
      };
      const res = await this.$axios.post("/users/send-code");
      this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType;
      if (res.message === "code sent successfully") {
        this.$error(
            "",
            "کد به " + a[this.towStepAuthenticationType] + " شما ارسال شد",
            "success"
        );
        if (this.countDown === 0) this.countDown = 120;
        this.countDownTimer();
        this.state.loading = false;
      }
    },
    selectFun(index) {

      console.log(this.nets[index].withdrawalFee)
      this.selected_net = this.nets[index].tokenType;
      this.transaction.tokenType = this.selected_net;
      this.txIdIsNeeded = this.nets[index].txIdIsNeeded;
      this.enable = this.nets[index].withdrawEnable;
      this.wage = this.nets[index].withdrawalFee
      this.min = this.nets[index].minWithdraw
      this.max = this.nets[index].maxWithdraw
      this.drop = false;
    },
    async submitWithdrawRequest() {
      this.state.loading = true;
      this.transaction.code = this.code;
      const [res] = await this.$http.post(
          "/wallets/withdrawal-request",
          this.transaction
      );
      if (res) {
        this.$emit("closeModaleEmit");
        this.$error("درخواست شما با موفقیت ثبت شد", "", "success");
        ++this.state.requestChanged;
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeOut);
  },
};
</script>

<style lang="scss" scoped>
.eth-fee {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .fee,
  .min {
    > p {
      font-size: 14px;
      color: #e63756;
    }
  }
}

.selectd-net-lable {
  position: relative;
  z-index: 2;

  .selectd-net {
    cursor: pointer;
    background: transparent;
    border: 1px solid #279bc0;
    border-radius: 4px;
    height: 38px;
    width: 100%;
    color: var(--font-strong);
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    align-items: center;
  }

  .dropdown {
    position: absolute;
    width: 100%;
    top: 48px;
    background: #001E3D !important;
    border: 1px solid #279bc0;
    border-radius: 4px;
    padding: 10px;

    div {
      cursor: pointer;
    }
  }
}

.normal-input {
  color: white;
  padding: 0 15px;
  width: 100%;
  min-height: 36px;
  border: 0.5px solid #279bc0;
  border-radius: 4px;
  background: transparent;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(111, 111, 112, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}

.modal {
  display: flex;
  row-gap: 44.5px;
  flex-direction: column;
  align-items: center;

  min-width: 367px;
  max-width: 461px;
  min-height: 276px;
  background: #141b31;
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 15px;
}

.close-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.message {
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .message-title {
    text-align: center;
    font-size: 18px;
    color: #febc00;
  }

  .warning {
    display: flex;
    column-gap: 5px;

    .warning-text {
      display: flex;
      flex-direction: column;

      > p {
        font-size: 14px;
        color: #9c9fc0;
      }
    }
  }

}

.amount {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;

  > p {
    font-size: 12px;
    color: #9c9fc0;
  }

  .price {
    color: #279bc0;
  }
}

.code-sent {
  flex-direction: column;
  display: flex;
  width: 100%;
  row-gap: 4px;
}

.cont {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 32px;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  background: #279bc0;
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 32px;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  background: #e63756;
}

@media screen and (max-width: 768px) {
  .modal {
    overflow: auto;
    width: 90%;
    min-width: 300px;
    height: 90%;
  }
}
</style>
