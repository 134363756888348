<template>
  <div class="profile">
    <sidebar/>

      <router-view class="content"/>

  </div>
</template>

<script>
import Sidebar from "@/components/profile/sidebar";

export default {
  name: "profile",
  components: {Sidebar},
};
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
  gap: 10px;

  background: #0a1128;

  .content {
    width: 100%;
    padding: 10px;
  }
}
</style>
