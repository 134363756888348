<template>

  <div class="crypto-table">
    <loading-modal v-if="!coins.length"/>
    <div class="head">
      <span> انتخاب بازار بر اساس </span>
      <div class="unit-container">
        <div :class="{ 'active-unit': !toman }" @click="toman = false ; getData() ">
          تتر USDT
        </div>
        <div :class="{ 'active-unit': toman }" @click="toman = true ; getData()">
          تومان IRT
        </div>
      </div>
    </div>
    <div class="table-container">
      <table>
        <tr class="tr-hrad">
          <td>نام</td>
          <td>آخرین قیمت</td>
          <td class="tablet">تغییرات</td>
          <td class="tablet">آخرین معاملات</td>
          <td class="tablet">حجم معاملات</td>
          <td class="tablet">تغییر هفتگی</td>
          <td class="tablet">نمودار هفتگی</td>
          <td class="tablet">عملیات</td>
        </tr>

        <tr v-if="!regex" class="tr-body"  @mouseover="copyImage({relatedCoin:'USDT'})" @mouseleave="$emit('setDefaultImage')" >
          <td @click="goto(usdt.relatedCoin)">
            <div class="image-container">
              <img :src="$newCoins[usdt.relatedCoin].link" :alt=" btc.relatedCoin" width="38px">

              <span>{{ $newCoins[usdt.relatedCoin].persianName }}</span> <span class="tablet">({{ usdt.relatedCoin }})</span>
              <!--              {{$newCoins }}-->
            </div>
          </td>
          <td class="tablet">{{ usdt.lastPrice.toLocaleString() }}</td>
          <td class="tablet" :class="{
              positive: usdt.last24HourChange > 0,
              negative: usdt.last24HourChange < 0,
            }">{{ $toLocal(usdt.last24HourChange, 3) }} %
            <span v-if="usdt.last24HourChange > 0">+</span>
            <!-- <span v-if="coin.last24HourChange < 0 "> - </span> -->

          </td>
          <td class="tablet">{{ usdt.last24HourTrades }}</td>
          <td>
            <span v-if="toman">{{
                $toLocal($toLocal(usdt.last24HourVolume,3))
              }}</span>
            <span v-else>{{ $toLocal(usdt.last24HourVolume,$decimal['TETHER']) }}</span>
          </td>
          <td
              class="tablet"
              :class="{
              positive: usdt.last24HourChange > 0,
              negative: usdt.last24HourChange < 0,
            }"
          >
            {{ $toLocal(usdt.last24HourChange, 1) }} %
            <span v-if="usdt.last24HourChange > 0">+</span>
            <!-- <span v-if="coin.last24HourChange < 0 "> - </span> -->
          </td>
          <td class="tablet">
            <TrendChart :trend='usdt.lastWeekData'/>
          </td>
          <td>
            <button
                class="table-btn tablet"
                @click="goto(usdt.relatedCoin)"
            >
              خرید و فروش
            </button>
          </td>
        </tr>

        <tr v-if="!regex" class="tr-body"  @mouseover="copyImage({relatedCoin:'BTC'})" @mouseleave="$emit('setDefaultImage')">
          <td @click="goto(btc.relatedCoin)">
            <div class="image-container">
              <img :src="$newCoins[btc.relatedCoin].link" :alt=" btc.relatedCoin" width="38px">

              <span>{{ $newCoins[btc.relatedCoin].persianName }}</span> <span class="tablet">({{ btc.relatedCoin }})</span>
              <!--              {{$newCoins }}-->
            </div>
          </td>
          <td class="tablet">{{ btc.lastPrice.toLocaleString() }}</td>
          <td class="tablet" :class="{
              positive: btc.last24HourChange > 0,
              negative: btc.last24HourChange < 0,
            }">{{ $toLocal(btc.last24HourChange, 3) }} %
            <span v-if="btc.last24HourChange > 0">+</span>
            <!-- <span v-if="coin.last24HourChange < 0 "> - </span> -->

          </td>
          <td class="tablet">{{ btc.last24HourTrades }}</td>
          <td>
            <span v-if="toman">{{
                $toLocal($toLocal(btc.last24HourVolume,3))
              }}</span>
            <span v-else>{{ $toLocal(btc.last24HourVolume,$decimal['TETHER']) }}</span>
          </td>
          <td
              class="tablet"
              :class="{
              positive: btc.last24HourChange > 0,
              negative: btc.last24HourChange < 0,
            }"
          >
            {{ $toLocal(btc.last24HourChange, 1) }} %
            <span v-if="btc.last24HourChange > 0">+</span>
            <!-- <span v-if="coin.last24HourChange < 0 "> - </span> -->
          </td>
          <td class="tablet">
            <TrendChart :trend='btc.lastWeekData'/>
          </td>
          <td>
            <button
                class="table-btn tablet"
                @click="goto(btc.relatedCoin)"
            >
              خرید و فروش
            </button>
          </td>
        </tr>

        <tr class="tr-body" v-for="(coin, index) in getCrypto()" :key="index" @mouseover="copyImage(coin)" @mouseleave="$emit('setDefaultImage')">
          <td @click="goto(coin.relatedCoin)">
            <div class="image-container">
              <img :src="$newCoins[coin.relatedCoin].link" :alt=" coin.relatedCoin" width="38px">

              <span>{{ $newCoins[coin.relatedCoin].persianName }}</span> <span class="tablet">({{ coin.relatedCoin }})</span>
<!--              {{$newCoins }}-->
            </div>
          </td>
          <td class="tablet">{{ coin.lastPrice.toLocaleString() }}</td>
          <td class="tablet" :class="{
              positive: coin.last24HourChange > 0,
              negative: coin.last24HourChange < 0,
            }">{{ $toLocal(coin.last24HourChange, 3) }} %
            <span v-if="coin.last24HourChange > 0">+</span>
            <!-- <span v-if="coin.last24HourChange < 0 "> - </span> -->

          </td>
          <td class="tablet">{{ coin.last24HourTrades }}</td>
          <td>
            <span v-if="toman">{{
                $toLocal($toLocal(coin.last24HourVolume,3))
              }}</span>
            <span v-else>{{ $toLocal(coin.last24HourVolume,$decimal['TETHER']) }}</span>
          </td>
          <td
              class="tablet"
              :class="{
              positive: coin.last24HourChange > 0,
              negative: coin.last24HourChange < 0,
            }"
          >
            {{ $toLocal(coin.last24HourChange, 1) }} %
            <span v-if="coin.last24HourChange > 0">+</span>
            <!-- <span v-if="coin.last24HourChange < 0 "> - </span> -->
          </td>
          <td class="tablet">
            <TrendChart :trend='coin.lastWeekData'/>
          </td>
          <td>
            <button
                class="table-btn tablet"
                @click="goto(coin.relatedCoin)"
            >
              خرید و فروش
            </button>
          </td>
        </tr>
      </table>
      <div class="footer" v-if="$route.path === '/'">
        <a href="/markets/" @click.prevent="$router.push('/markets/')">نمایش همه</a>
      </div>
    </div>
  </div>
</template>

<script>
// import coins from "@/library/cryptoMarketPage.js";
import TrendChart from "@/components/tools/TrendChart.vue";
import LoadingModal from "@/components/LoadingModal";
import {
  Loop
} from "@/library/reuseableFunction";

export default {
  name: "CryptoTable",
  components: {
    TrendChart,
    LoadingModal
  },
  props: ['regex'],
  data() {
    return {
      // cryptoList: coins,
      toman: true,
      loop: undefined,

      coins: [],
      allCoins:[],
      imageCoin :'',
      currency: "toman",

      TomanCoin: 'market_type=BITCOIN_TOMAN' +
          '&market_type=BITCOIN_CASH_TOMAN' +
          '&market_type=ETHEREUM_TOMAN' +
          '&market_type=LITE_COIN_TOMAN' +
          '&market_type=BINANCE_COIN_BSC_TOMAN' +

          '&market_type=STELLAR_TOMAN' +
          '&market_type=TRON_TOMAN' +
          '&market_type=CELO_TOMAN' +
          '&market_type=RIPPLE_TOMAN' +
          '&market_type=DOGE_COIN_TOMAN' +
          '&market_type=MATIC_TOMAN' +

          '&market_type=TETHER_TOMAN',
      //get coins tether
      TetherCoin: 'market_type=BITCOIN_TETHER' +
          '&market_type=BITCOIN_CASH_TETHER' +
          '&market_type=ETHEREUM_TETHER' +
          '&market_type=LITE_COIN_TETHER' +
          '&market_type=BINANCE_COIN_BSC_TETHER' +
          '&market_type=MATIC_TETHER' +

          '&market_type=STELLAR_TETHER' +
          '&market_type=TRON_TETHER' +
          '&market_type=RIPPLE_TETHER' +
          '&market_type=DOGE_COIN_TETHER' +
          '&market_type=CELO_TETHER',
      btc:'',
      usdt:'',

    };
  },

  methods: {
    getCurrency(value) {
      this.items[0].class = 'deActive'
      this.items[1].class = 'deActive'
      this.items[value].class = 'active'
      this.setData()
    },
    goto(item) {
console.log(item)
      // this.$router.push('/profile/trade/' + (this.toman ? 'TOMAN' : 'TETHER') + `/${this.$newCoins[item].coin}`)
      this.$router.push(`/market/${this.$newCoins[item].coin}/`)

    },
    async getData() {
      // this.state.loading=true;
      const res = await this.$axios.get('/trades/market-info?weeklyDataIncluded=true&page=1&size=1000&sortBy=PERSIAN')
      //content is the market
      // console.log(res.content)\
      this.allCoins=res.content;
      this.coins = res.content.filter(c=>c.relatedCoin!=='BTC' && c.relatedCoin!=='USDT')
      this.btc=this.toman?res.content.find((c)=>c.marketType ==='BITCOIN_TOMAN'):res.content.find((c)=>c.marketType ==='BITCOIN_TETHER')
      console.log(this.btc)
          this.usdt=res.content.find((c)=>c.relatedCoin==='USDT')
    },
    getCrypto() {
      let coins = this.allCoins;
if(!this.regex){
  coins =this.coins;
}

       this.toman? coins = coins.filter(coin => coin.marketType.includes('TOMAN')): coins = coins.filter(coin => coin.marketType.includes('TETHER'))
      return coins.filter(coin => (coin.relatedCoin.match(this.regex.toLowerCase())   || this.$newCoins[coin.relatedCoin].persianName.match(this.regex)) || coin.relatedCoin.match(this.regex.toUpperCase()) || this.$newCoins[coin.relatedCoin].coin.match(this.regex.toUpperCase()) || this.$newCoins[coin.relatedCoin].coin.match(this.regex.toLowerCase()))

    },
    copyImage(coin){

   this.$emit('copyImage',coin)
    },
  

    // getCrypto(){
    //   console.log(this.coins[0])
    //   return this.coins
    // },

    beforeLoop() {
      // this.loading = true
      if (this.state.loop) {
        this.loop = new Loop(this.getData, 5000, 'realPrice' + (this.toman ? 'Toman' : 'Tether'))
        this.loop.start()
      } else {
        this.getData()
      }
    }

  },

  mounted() {
    this.beforeLoop()
    this.getData()

  },
  beforeDestroy() {
    this.loop.stop();
  }

}

</script>

<style lang="scss" scoped>
.crypto-table {
  max-width: 1440px;
  margin: 0 auto;

}

.head,
.table-container {
  max-height: 600px;
  overflow: scroll;
  background-color: #141b31;
}

.head {
  width: 453px;
  min-height: 68px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  flex-wrap: wrap;
  row-gap: 20px;

  span {
    margin-top: 10px;
  }

  .unit-container {
    width: 200px;
    height: 33px;
    display: flex;
    background: rgba(156, 159, 192, 0.1);
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;


    > div {
      flex-grow: 1;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
    }

    .active-unit {
      background: #279bc0;
      border-radius: 8px;
      color: #ffffff;
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
}

.table-container {
  width: 100%;
  // height: 643px;
  position: relative;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;

  table {
    width: 100%;
    // max-width: 1000px;
    margin: 0 auto;
    border-collapse: collapse;
  }

  .tr-hrad {
    color: #ffffff;
    font-size: clamp(12px, 1vw, 16px);
    text-align: center;
    height: 82px;
    position: sticky;
    background-color: #141b31;
    top: 0;

    .tablet {
    }

    .phone {
    }
  }

  .tr-hrad td:first-child {
    text-align: right;
    padding-right: 60px;
  }

  .tr-hrad td:last-child {
    padding-left: 60px;
  }

  .tr-body {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
    height: 82px;
    font-size: clamp(12px, 1vw, 16px);

    .tablet {
    }

    .phone {
    }

    .image-container {
      height: 100%;
      display: flex;
      flex-grow: 1;
      align-items: center;
      column-gap: 10px;
    }

    .table-btn {
      background: linear-gradient(0deg, #febc00, #febc00), #19196f;
      border-radius: 4px;
      color: #0a1128;
      font-size: clamp(12px, 1vw, 16px);
      height: 40px;
      width: 136px;
    }
  }

  .tr-body:last-child {
    border-bottom: none;
  }

  .tr-body td:first-child {
    text-align: right;
    padding-right: 60px;
  }

  .tr-body td:last-child {
    padding-left: 60px;
  }
}

.footer {
  display: flex;
  justify-content: center;
  min-height: 82px;
  align-items: center;

  > a {
    font-size: clamp(14px, 1vw, 18px);
    font-weight: 700;
    color: #febc00;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 1100px) {
  .crypto-table {
    padding: 10px;
  }
  .tablet {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .head {
  max-width: 100%;
}
  .table-container{
    border-top-left-radius: 0;
  }
  .phone {
    display: none;
  }
  .image-container {
    img {
      width: 1rem;
      height: 1rem;
    }
  }
}
</style>
