<template>
  <div class="items d-flex flex-column">
 <div class="search d-flex justify-content-between">
   <input type="text"   placeholder="جستجو" v-model="regex">

 </div>
<div class="coins-container">


  <div class="coins" v-for="(item, index) in search(wallets)" :key="index">

    <div class="symbol">
      <img :src="$coins[item[0]].link" :alt="item[0]" width="28" height="28"/>
      <p>{{ $coins[item[0]].persianName }}</p>
      <p class="brand">{{ $coins[item[0]].code }}</p>
    </div>
    <div class="inventory">
      <div class="inv">
        <p class="key">موجودی</p>



        <p class="value">
          {{ $toLocal(item[1].credit, $decimal[item[0]]) }}
          {{ $coins[item[0]].persianName }} </p>
      </div>
      <div class="val">
        <p class="key">ارزش</p>
        <p class="value">
          {{ $toLocal(item[1].wholePriceOfCredit, 2) }}
          تومان
        </p>
      </div>
    </div>
    <div class="blocked">
      <p>بلاک شده</p>
      <p class="value">{{ $toLocal(item[1].blockedCredit, $decimal[item[0]]) }}   <img src="../../../assets/public/lock.svg" alt="" width="14" height="14">   </p>

    </div>
    <div class="btns">
      <button class="buy" @click="openDepModal([item[0], credit(item[1])] , index)">واریز</button>
      <button class="sell" @click="openWithModal([item[0], credit(item[1])] , index)">برداشت</button>

    </div>
  </div>









</div>



    <DipositModal v-if="modal==='DepositBit'" @closeModaleEmit="closeModal" :related-coin="relatedCoinVar" :cash="cash"
                  @two-fa="openTwoStepModal" :wage="wage" :nets="nets" :exchange='false'/>
    <WidthdrawlModal :related-coin="relatedCoinVar" :cash="cash" :role="role" :wage="wage"
                     v-if="modal==='WithdrawalBit'" @closeModaleEmit="modal=''" @two-fa="openTwoStepModal" :nets="nets"
                     :exchange='false'/>
    <SuccessModal v-if="modal==='success'" :msgType="msg" @closeModaleEmit="closeModal"/>
    <RialDipositModal v-if="modal==='DepositeToman'" @closeModaleEmit="closeModal" :cash="tomancash" :role="role"/>
    <RialwithdrawlModal v-if="modal==='WidthdrawalToman'" @closeModaleEmit="closeModal" :cash="tomancash"
                        @two-fa="openTwoStepModal" :role="role"/>
  </div>
</template>

<script>
import DipositModal from "./DipositModal.vue";
import WidthdrawlModal from "./WidthdrawlModal.vue";
import RialDipositModal from "@/components/profile/wallet/RialDipositModal";
import RialwithdrawlModal from "@/components/profile/wallet/RialwithdrawlModal";
import SuccessModal from './SuccessModal.vue';

export default {
  name: "CoinsWallet",
  components: {
    DipositModal,
    WidthdrawlModal,
    RialDipositModal,
    RialwithdrawlModal,
    SuccessModal,

  },
  data() {
    return {
      regex:'',
      msg: {
        wit: false,
        dipo: false,
      },

      buttons: [{
        title: "برداشت",
        class: "withdrawal-btn",
        open: 'WithdrawalBit'
        //  click : () => this.deposit()
      },
        {
          title: "واریز",
          class: "deposite-btn",
          open: 'DepositBit'
        },
        {
          title: "معامله",
          class: "trade-btn",
          link: "trade"
        }
      ],
      tomanBTN: [{
        title: "واریز",
        class: "deposite-toman",
        open: 'DepositeToman'
      },
        {
          title: "برداشت",
          class: "widthdrawal-toman",
          open: 'WidthdrawalToman'
        }
      ],
      nav: [{
        title: "ارز"
      },
        {
          title: "موجودی",
          title2: "معادل تومان"
        },
        {
          title: "عملیات"
        }
      ],
      showWithModal: false,
      showDepModal: false,
      showRWithModal: false,
      showRDepModal: false,
      wallets: [],
      relatedCoinVar: '',
      cash: 0,
      tomancash: 2,
      twoStep: '',
      transaction: {},
      coins: [],
      wage: 0,
      role: {},
      loading: false,
      modal: ''

    }
  },
  computed: {
    amount() {
      return e => this.$toLocal(e.credit) || '0'
    },
    price() {
      return e => this.$toLocal(e[0] === 'TOMAN' ? e[1].totalBalance : e[1].wholePriceOfCredit, 0) || '0'
    },
    blocked() {
      return e => this.$toLocal(e[1].blockedCredit) || '0'
    },
    credit() {
      return e => this.$toLocal(e.credit) || '0'
    },
  },

  methods: {
    search(input){
      if(this.regex){
        return input.filter(item=>item[0].match(this.regex.toUpperCase()) || this.$coins[item[0]].code.match(this.regex.toUpperCase()) || this.$coins[item[0]].persianName.match(this.regex)  )
      }


else{
        return input
      }

    },
    passData() {
      this.$emit('passRole', this.role)
      this.$emit('passToman', this.tomancash)
    },
    OpenModal(index, cash) {
      this.cash = cash
      this.wage = this.coins.find((a) => a.name === "TOMAN").withdrawalFee
      this.role = this.coins.find((a) => a.name === "TOMAN")
      this.modal = this.tomanBTN[index].open
    },
    OpenModalBit(e, index) {
      this.modal = this.buttons[index].open
      this.relatedCoinVar = e
      this.cash = e[1]
      this.wage = this.coins.find((a) => a.name === e[0]).tokenTypeDetails[0].withdrawalFee
      this.role = this.coins.find((a) => a.name === e[0])
    },
    openWithModal(e) {
        let nets = this.coins.find(item=>item.name ===e[0]).tokenTypeDetails


      console.log('nets',nets)
      console.log('nets',nets)
      this.nets = this.coins.find((a) => a.name === e[0]).tokenTypeDetails
      this.relatedCoinVar = e[0]
      this.cash = e[1]
      this.wage = this.coins.find((a) => a.name === e[0]).withdrawalFee
      this.role = this.coins.find((a) => a.name === e[0])
      this.modal = 'WithdrawalBit'
      //console.log('role', this.role)
console.log(e)
    },
    openDepModal(e) {
      this.relatedCoinVar = e
      // this.cash = e[1]
      // this.wage = this.coins.find((a) => a.name === e[0]).transferFee
      this.modal = 'DepositBit'
      //console.log(this.coins)
      this.nets = this.coins.find((a) => a.name === e[0]).tokenTypeDetails
      console.log('coins',this.coins)
      console.log('nets',this.nets)
    },
    closeModal(value) {
      this.modal = value
    },
    depositModal(e) {
      this.relatedCoinVar = e
      if (e[0] !== 'TOMAN') {
        this.showDepModal = true
      } else {
        this.showRDepModal = true
      }
    },
    withdrawModal(e) {
      this.relatedCoinVar = e[0]
      this.cash = e[1]
      this.wage = this.coins.find((a) => a.name === e[0]).withdrawalFee
      if (e[0] !== 'TOMAN') {
        this.showWithModal = true
      } else {
        this.showRWithModal = true
      }
    },
    openTwoStepModal(e) {
      this.transaction = e[0]
      this.twoStep = e[1]
    },
    closemodal() {
      this.showWithModal = false
      this.showDepModal = false
      this.showRWithModal = false
      this.showRDepModal = false
      this.twoStep = ''
    },
    async getWallets() {
      this.state.loading = true
      // const coinsList="ETHEREUM,BITCOIN_CASH,TRON,RIPPLE,TETHER,TOMAN,BITCOIN"
      this.loading = true
      const [res,] = await this.$http.get('/wallets/customer-addresses')

      if (res) {
        let entireRes = Object.entries(res)
        const sortCoins = this.$walletSortCoins
        entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
        let wallets = entireRes.filter(a=> a[0] !== 'TOMAN')
        wallets = wallets.sort((a, b) => a[1].credit < b[1].credit)
        console.log(wallets)
        this.wallets = wallets;

        this.tomancash = res["TOMAN"].credit

        //console.log('response', res)

      }
      this.loading = false

    },
    async submitWithdrawRequest(code) {
      this.state.loading = true
      this.transaction.code = code
      const [res,] = await this.$http.post(`/wallets/withdrawal-request`, this.transaction)
      if (res) {
        this.$error('درخواست شما با موفقیت ثبت شد', '', 'success')
        ++this.state.requestChanged
        this.closemodal()
      }
    },
    async getData() {
      this.state.loading = true
      const res = await this.$axios.get('/coins/details')
      this.coins = res.content

    },
    async checkWallet(e) {
      let query = {
        relatedCoin: e
      }
      if (e === 'TETHER') {
        const res = await this.$error('', 'شبکه مورد نظر را انتخاب کنید', 'info', 'اتریومی', 'ترونی')
        if (res.dismiss !== 'backdrop') {
          query.tokenType = res.isConfirmed ? 'ERC20' : 'TRC20'
        } else {
          return;
        }
      }
      if (this.$etcChannelCoin.includes(e)) {
        query.txid = await prompt('TxId : شناسه تراکنش')
        if (!query.txid) {
          return;
        }
      }
      this.state.loading = true
      const res = await this.$axios.get('/wallets/check-confirmed-transaction', {
        params: query
      })
      let text
      if (res.baseDTO.amount) {
        ++this.state.requestChanged
        text = `مقدار ${this.$toLocal(res.baseDTO.amount) || 0} ${this.$coinLabel[e]} واریز شده و تایید ${res.baseDTO.transactionIsDone ? 'شده' : 'نشده'}`
      } else {
        text = 'مقدار واریزی شناسایی نشد'
      }
      this.$error('', text, res.baseDTO.transactionIsDone ? 'success' : 'info')
    },
    goto(e) {
      this.$router.push('/profile/trade/' + 'TOMAN' + `/${e}`)
    },

  },
  async mounted() {
    this.state.loading = true
    await this.getWallets()
    await this.getData()
    //console.log(this.toman);
    await this.passData()
  },
};
</script>

<style lang="scss" scoped>
.blocked{
  display: flex;
  gap: 4px;
  //position: absolute;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;


p{
  font-size: clamp(8px,1vw,12px);

}

}
.items{
  position: relative;
  width: 100%;
}
.search{
  z-index: 10;
  background: #0f1640;
  position: sticky;
  top: 0;
  border: 1px solid #3D49CC;
  align-items: center;
  padding:0 .5rem;
  border-radius: 8px;
  max-height: 40px;
  input{
    background: transparent !important;
    width: 100%;
    border: none !important;
    height: 40px !important;
  }

}
.items{
  display: flex;
  flex-direction: column;
  position: relative;
}



.coins-container{
  margin-top: 3em;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  gap: 8px;
  flex-wrap: wrap;
}

.coins {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;
  padding: 16px 12px;
z-index: 2;
  width: 100%;
  max-width: 327px;
  //flex-grow: 1;
  //   flex-shrink: 1;
  //   flex-basis: 328px;
  //height: 190px;
  background: #0f1640;
  border-radius: 8px;

  .symbol {
    display: flex;
    align-items: center;
    column-gap: 8px;

    .brand {
      color: #febc00;
    }
  }

  .inventory {
    display: flex;
   gap: 1em;
    flex-direction: column;
    width: 100%;

    .inv,
    .val {
      display: flex;
      justify-content: space-between;

      .value {
        color: #9c9fc0;
      }
    }
  }

  .btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 8px;
    z-index: 4;

    .buy,
    .sell {
      width: 100%;
      //min-width: 148px;
      flex:1 1 auto;
      min-height: 36px;
      border-radius: 8px;
      color: white;
      font-size: 16px;
    }

    .buy {
      background: #26c22c;
    }

    .sell {
      background: #e63756;
    }
  }
}

//@media (max-width: 629px) {
//  .coins {
//    max-width: unset;
//    min-width: 100% !important;
//
//  }
//  .items {
//    width: 100% !important;
//    max-width: unset;
//  }
//  .first-column, .second-column {
//    width: 100% !important;
//  }
//
//}

@media screen and (max-width: 700px) {
  .coins-container{
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .coins{
    width: 100%;
    max-width: unset;
  }
  .items {
    //width: 90%;

    //.coins {
    //  width: 100%;
    //  max-width: unset;
    //
    //  .btns {
    //    .buy,
    //    .sell {
    //      min-width: unset;
    //      width: 100%;
    //    }
    //  }
    //}
  }
}

@media (max-width: 450px){
  .coins-container{
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }
}
</style>
