<template>
  <div class="crypto-table">
    <div class="head">
        <span>
            انتخاب بازار بر اساس
        </span>
      <div class="unit-container">
        <div :class="{'active-unit' : !toman}" @click="toman = false ; getData()">
          تتر USDT
        </div>
        <div :class="{'active-unit' : toman}" @click="toman = true; getData()">
          تومان IRT
        </div>
      </div>
    </div>
    <div class="table-container">
      <table>
        <tr class="tr-hrad">
          <td>نام</td>
          <td>آخرین قیمت</td>
          <td class="tablet">تغییرات</td>
          <td class="tablet">نمودار هفتگی</td>
          <td class="tablet">عملیات</td>
        </tr>
        <tr class="tr-body" v-for="(coin , index) in coins" :key="index">
          <td>
            <div class="image-container ">
              <img :src="'/coins/'+ $coin2Snake[coin.relatedCoin] + '.png'" :alt=" coin.relatedCoin" width="38px">

              <span>{{ $coinLabel[coin.relatedCoin] }}</span>
              <span class="tablet">({{ coin.relatedCoin }})</span>
            </div>
          </td>
          <td>
            <span v-if="toman">{{ $toLocal(coin.lastPrice) }}</span>
            <span v-else>{{ $toLocal(coin.lastPrice) }}</span>
          </td>
          <td
              class="tablet"
              :class="{'positive' : coin.last24HourChange > 0 , 'negative' : coin.last24HourChange < 0}">
            <span v-if="coin.last24HourChange > 0">+</span>
            <!-- <span v-if="coin.last24HourChange < 0">-</span> -->
            {{ $toLocal(coin.last24HourChange) }}%


          </td>
          <td class="tablet">
            <TrendChart :trend='coin.lastWeekData'/>
          </td>
          <td>
            <button class="table-btn tablet" @click="goto($coin2Snake[coin.relatedCoin])">خرید و فروش</button>
          </td>
        </tr>
      </table>
      <div class="footer" v-if="$route.path===('/')">
        <a href="/markets" @click.prevent="$router.push('/markets')">نمایش همه</a>
      </div>
    </div>
  </div>
</template>

<script>
// import crpto from '@/library/crypto.js'
import TrendChart from '@/components/tools/TrendChart.vue'
import {
  Loop
} from "@/library/reuseableFunction";

export default {
  name: "CryptoTable",

  components: {
    TrendChart
  },
  data() {
    return {
      // cryptoList: crpto,
      toman: true,
      loop: undefined,
      coins: [],
      //get coins toman
      currency: "toman",
      TomanCoin: 'market_type=BITCOIN_TOMAN' +
          '&market_type=BITCOIN_CASH_TOMAN' +
          '&market_type=ETHEREUM_TOMAN' +
          '&market_type=LITE_COIN_TOMAN' +
          '&market_type=BINANCE_COIN_BSC_TOMAN' +
          '&market_type=MATIC_TOMAN'
      ,
      //get coins tether
      TetherCoin: 'market_type=BITCOIN_TETHER' +
          '&market_type=BITCOIN_CASH_TETHER' +
          '&market_type=ETHEREUM_TETHER' +
          '&market_type=LITE_COIN_TETHER' +
          '&market_type=BINANCE_COIN_BSC_TETHER' +
          '&market_type=MATIC_TETHER',

    }
  },
  methods: {
    async getData() {
      // this.state.loading=true;
      const res = await this.$axios.get('/trades/market-info?' + (this.toman ? this.TomanCoin : this.TetherCoin),
          {
            params: {
              weeklyDataIncluded: true,
            }
          })
      //content is the market
      this.coins = res.content.sort()
      console.log(res.content)
    },
    beforeLoop() {
      // this.loading = true
      if (this.state.loop) {
        this.loop = new Loop(this.getData, 5000, 'realPrice' + (this.toman ? 'Toman' : 'Tether'))
        this.loop.start()
      } else {
        this.getData()
      }
    },
    goto(e) {

      this.$router.push('/profile/trade/' + (this.toman ? 'TOMAN' : 'TETHER') + `/${e}`)
    },
  },
  mounted() {

    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.crypto-table {
  max-width: 1440px;
  margin: 0 auto;
}

.head,
.table-container {
  background-color: #141B31;
}

.head {
  width: 453px;
  min-height: 68px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  flex-wrap: wrap;
  row-gap: 20px;

  span {
    margin-top: 10px;
  }


  .unit-container {
    width: 200px;
    height: 33px;
    display: flex;
    background: rgba(156, 159, 192, 0.1);
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;

    > div {
      flex-grow: 1;
      text-align: center;
      color: #FFFFFF;
      cursor: pointer;
    }

    .active-unit {
      background: #279BC0;
      border-radius: 8px;
      color: #FFFFFF;
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
}

.table-container {
  width: 100%;
  // height: 643px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;

  table {
    width: 100%;
    // max-width: 1000px;
    margin: 0 auto;
    border-collapse: collapse;
  }

  .tr-hrad {
    color: #FFFFFF;
    font-size: clamp(12px, 1vw, 16px);
    text-align: center;
    height: 82px;

    .tablet {
    }

    .phone {
    }


  }

  .tr-head td:first-child {
    text-align: right;
    padding-right: 60px;
  }

  .tr-head td:last-child {
    padding-left: 60px;
  }

  .tr-body {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
    height: 82px;
    font-size: clamp(12px, 1vw, 16px);

    .tablet {
    }

    .phone {
    }


    .image-container {
      height: 100%;
      display: flex;
      flex-grow: 1;
      align-items: center;
      column-gap: 10px;
    }

    .table-btn {
      background: linear-gradient(0deg, #FEBC00, #FEBC00), #19196F;
      border-radius: 4px;
      color: #0A1128;
      font-size: clamp(12px, 1vw, 16px);
      height: 40px;
      width: 136px;
    }
  }

  .tr-body:last-child {
    border-bottom: none;
  }

  .tr-body td:first-child {
    text-align: right;
    padding-right: 60px;
  }

  .tr-body td:last-child {
    padding-left: 60px;
  }
}

.footer {
  display: flex;
  justify-content: center;
  min-height: 82px;
  align-items: center;

  > a {
    font-size: clamp(14px, 1vw, 18px);
    font-weight: 700;
    color: #FEBC00;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 1100px) {
  .crypto-table {
    padding: 10px;
  }
  .tablet {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .head {
    max-width: 100%;
  }
  .table-container{
    border-top-left-radius: 0;
  }


  img{
    width: 1rem !important;
    height: 1rem !important;
  }

}
</style>