<template>
  <div class="navbar active">
    <div class="container">
      <div class="nav">
        <div class="img">
          <a v-if="!$route.path.includes('/profile')" class="menu-btn">
            <InlineSvg
                :color="true"
                :fill="'gold'"
                :height="38"
                :src="require('../../assets/public/hamburger.svg')"
                :width="38"
                @click.prevent="show = true"
            ></InlineSvg>
          </a>
          <img
              alt=""
              src="../../assets/public/51.png"
              @click.prevent="$router.push('/')"
          />
        </div>
        <ul>
          <li v-for="(item, index) in items" :key="index">
            <a :href="item.link" @click.prevent="$router.push(item.link)">
              {{ item.title }}
            </a>

          </li>
          <li>
            <a href="/fa/blog">بلاگ</a>
          </li>
        </ul>
      </div>
      <div v-if="state.token" class="avatar"        @click.prevent="isAccess = !isAccess">
        <span
        ><svg

            fill="none"
            height="22"
            viewBox="0 0 21 22"
            width="21"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                clip-rule="evenodd"
                d="M14.318 7.63825C14.318 8.6509 13.9157 9.62207 13.1997 10.3381C12.4836 11.0542 11.5125 11.4564 10.4998 11.4564C9.48718 11.4564 8.51601 11.0542 7.79996 10.3381C7.08391 9.62207 6.68164 8.6509 6.68164 7.63825C6.68164 6.62561 7.08391 5.65444 7.79996 4.93839C8.51601 4.22234 9.48718 3.82007 10.4998 3.82007C11.5125 3.82007 12.4836 4.22234 13.1997 4.93839C13.9157 5.65444 14.318 6.62561 14.318 7.63825V7.63825ZM12.4089 7.63825C12.4089 8.14457 12.2078 8.63016 11.8498 8.98818C11.4917 9.34621 11.0061 9.54734 10.4998 9.54734C9.9935 9.54734 9.50792 9.34621 9.14989 8.98818C8.79187 8.63016 8.59073 8.14457 8.59073 7.63825C8.59073 7.13193 8.79187 6.64634 9.14989 6.28832C9.50792 5.9303 9.9935 5.72916 10.4998 5.72916C11.0061 5.72916 11.4917 5.9303 11.8498 6.28832C12.2078 6.64634 12.4089 7.13193 12.4089 7.63825V7.63825Z"
                fill="#19196F"
                fill-rule="evenodd"
            />
            <path
                clip-rule="evenodd"
                d="M14.318 7.63825C14.318 8.6509 13.9157 9.62207 13.1997 10.3381C12.4836 11.0542 11.5125 11.4564 10.4998 11.4564C9.48718 11.4564 8.51601 11.0542 7.79996 10.3381C7.08391 9.62207 6.68164 8.6509 6.68164 7.63825C6.68164 6.62561 7.08391 5.65444 7.79996 4.93839C8.51601 4.22234 9.48718 3.82007 10.4998 3.82007C11.5125 3.82007 12.4836 4.22234 13.1997 4.93839C13.9157 5.65444 14.318 6.62561 14.318 7.63825V7.63825ZM12.4089 7.63825C12.4089 8.14457 12.2078 8.63016 11.8498 8.98818C11.4917 9.34621 11.0061 9.54734 10.4998 9.54734C9.9935 9.54734 9.50792 9.34621 9.14989 8.98818C8.79187 8.63016 8.59073 8.14457 8.59073 7.63825C8.59073 7.13193 8.79187 6.64634 9.14989 6.28832C9.50792 5.9303 9.9935 5.72916 10.4998 5.72916C11.0061 5.72916 11.4917 5.9303 11.8498 6.28832C12.2078 6.64634 12.4089 7.13193 12.4089 7.63825V7.63825Z"
                fill="#FEBC00"
                fill-rule="evenodd"
            />
            <path
                clip-rule="evenodd"
                d="M10.5 0.00195312C4.70114 0.00195312 0 4.70309 0 10.502C0 16.3008 4.70114 21.002 10.5 21.002C16.2989 21.002 21 16.3008 21 10.502C21 4.70309 16.2989 0.00195312 10.5 0.00195312ZM1.90909 10.502C1.90909 12.497 2.58968 14.3335 3.73036 15.792C4.53145 14.74 5.5649 13.8875 6.75 13.301C7.9351 12.7145 9.23976 12.4099 10.562 12.411C11.8672 12.4098 13.1555 12.7065 14.3286 13.2785C15.5018 13.8505 16.5289 14.6828 17.3317 15.7119C18.1587 14.6271 18.7156 13.361 18.9562 12.0183C19.1968 10.6756 19.1142 9.29496 18.7153 7.99053C18.3164 6.68609 17.6125 5.49541 16.6621 4.51699C15.7116 3.53857 14.5418 2.80055 13.2495 2.36398C11.9571 1.92742 10.5794 1.80487 9.23032 2.00647C7.88123 2.20807 6.59953 2.72803 5.49128 3.52332C4.38303 4.31861 3.48009 5.36637 2.85717 6.57991C2.23425 7.79345 1.90926 9.13788 1.90909 10.502V10.502ZM10.5 19.0929C8.52786 19.0958 6.61525 18.4174 5.08582 17.1723C5.70143 16.291 6.52082 15.5715 7.47427 15.0749C8.42773 14.5783 9.48702 14.3194 10.562 14.3201C11.6237 14.3193 12.6701 14.5717 13.6146 15.0565C14.5591 15.5413 15.3743 16.2444 15.9925 17.1074C14.4511 18.3929 12.507 19.0957 10.5 19.0929V19.0929Z"
                fill="#19196F"
                fill-rule="evenodd"
            />
            <path
                clip-rule="evenodd"
                d="M10.5 0.00195312C4.70114 0.00195312 0 4.70309 0 10.502C0 16.3008 4.70114 21.002 10.5 21.002C16.2989 21.002 21 16.3008 21 10.502C21 4.70309 16.2989 0.00195312 10.5 0.00195312ZM1.90909 10.502C1.90909 12.497 2.58968 14.3335 3.73036 15.792C4.53145 14.74 5.5649 13.8875 6.75 13.301C7.9351 12.7145 9.23976 12.4099 10.562 12.411C11.8672 12.4098 13.1555 12.7065 14.3286 13.2785C15.5018 13.8505 16.5289 14.6828 17.3317 15.7119C18.1587 14.6271 18.7156 13.361 18.9562 12.0183C19.1968 10.6756 19.1142 9.29496 18.7153 7.99053C18.3164 6.68609 17.6125 5.49541 16.6621 4.51699C15.7116 3.53857 14.5418 2.80055 13.2495 2.36398C11.9571 1.92742 10.5794 1.80487 9.23032 2.00647C7.88123 2.20807 6.59953 2.72803 5.49128 3.52332C4.38303 4.31861 3.48009 5.36637 2.85717 6.57991C2.23425 7.79345 1.90926 9.13788 1.90909 10.502V10.502ZM10.5 19.0929C8.52786 19.0958 6.61525 18.4174 5.08582 17.1723C5.70143 16.291 6.52082 15.5715 7.47427 15.0749C8.42773 14.5783 9.48702 14.3194 10.562 14.3201C11.6237 14.3193 12.6701 14.5717 13.6146 15.0565C14.5591 15.5413 15.3743 16.2444 15.9925 17.1074C14.4511 18.3929 12.507 19.0957 10.5 19.0929V19.0929Z"
                fill="#FEBC00"
                fill-rule="evenodd"
            />
          </svg>
        </span>

        <span >   {{ state.userInfo.firstName }}
        {{ state.userInfo.lastName }}
</span>
        <span>
           <InlineSvg
               :color="true"
               :fill="'gold'"
               :height="38"
               :src="require('../../assets/public/dropdown.svg')"


           ></InlineSvg>

      </span>
        <transition name="fadeHeight">
          <div class="dropdown-menu" v-if="isAccess">
            <span v-for="(nav,index) in access" :key="index"
                  @click.prevent="$router.push(nav.link);isAccess=false">{{ nav.title }}</span>
            <span @click.prevent="isLogout=true">خروج</span>
          </div>
        </transition>
      </div>
      <div v-else class="login-signup">

        <template v-if="!state.token">
          <a
              class="btn"
              :class="{ 'active-btn': $route.name == 'Login' }"
              href=""
              @click.prevent="$router.push('/login')"
          >ورود</a
          >
          <a
              :class="{ 'active-btn': $route.name == 'SignUp' }"
              class="btn"
              href=""
              @click.prevent="$router.push('/sign-up')"
          >ثبت نام
          </a>
        </template>
        <template v-else>
          <a
              class="btn token"
              href=""
              @click.prevent="$router.push('/profile')"
          > ورود</a
          >
        </template>
      </div>
      <transition name="fade">
        <div v-if="show" class="menu">
          <h1 class="close-ham" @click.prevent="show = false">X</h1>

          <ul v-for="(item, index) in items" :key="index">
            <li>
              <router-link :to="item.link">
                {{ item.title }}
              </router-link>
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <logout-modal v-if="isLogout" @closeModal="isLogout=false"/>
    <dummy/>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import Dummy from '../Dummy.vue';
import LogoutModal from '../profile/LogoutModal.vue';

export default {
  name: "Navbar",

  data() {
    return {
      items: [

        {
          title: "بازارها",
          link: "/markets/",
        },
        {
          title: "قوانین",
          link: "/terms/",
        },
        {
          title: "تماس با ما",
          link: "/contact-us/",
        },
        {
          title: "درباره ی ما",
          link: "/about-us/",
        },
        {
          title: "راهنمای استفاده ",
          link: "/faq/",
        },
        {
          title: "سوالات متداول ",
          link: "/guide/",
        },
      ],
      access: [
        {
          title: 'داشبورد',
          link: '/profile'
        },
        {
          title: "حساب کاربری",
          link: "/profile/account",
        },

        {
          title: "کیف پول",
          link: "/profile/wallet",
        },
        {
          title: "صفحه اصلی",
          link: "/",
        },
      ],

      show: false,
      isAccess: false,
      isLogout: false,
    };
  },
  components: {
    InlineSvg,
    LogoutModal,
    Dummy,
  },
  methods: {},

  mounted() {
    // console.log(this.state.userInfo)
  },
  created: function () {
    window.addEventListener("click", (event) => {
      if (!event.target.closest(" .menu-btn , .avatar")) {
        this.show = false;
        this.isAccess = false
      } else {
        return true;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.dropdown-menu {
  min-width: 130px;
  padding: clamp(5px, 1vw, 5px);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  border-radius: 8px;
  gap: 10px;
  width: 100%;
  z-index: 10;
  background: var(--blue-bg);
  cursor: pointer;
}

.token {
  width: unset !important;
  padding: 0 10px;
}

.active-btn {
  border: 1px solid #febc00 !important;
  border-radius: 5px !important;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 400;
  background-color: #0f1640;

  .container {
    padding: 0 10px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: clamp(11px, 1vw, 16px);

    .nav {
      display: flex;
      column-gap: 50px;
      justify-content: center;

      .img {
        > img {
          cursor: pointer;
          margin-top: 4px;
          width: 43.8px;
          height: 43.4px;
        }

        .menu-btn {
          display: none;
        }
      }

      ul {
        display: flex;
        column-gap: 50px;
        align-items: center;


      }
    }

    .login-signup {
      > img {
        width: 55.8px;
        height: 63.4px;
        display: none;
      }

      text-align: center;
      display: flex;
      align-items: center;

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: clamp(12px, 1vw, 18px);
        border: 0;
        transition: 1s all ease;
        width: 101px;
        min-height: 35px;
      }

      .btn:hover {
        border: 1px solid #febc00;
        border-radius: 5px;
      }
    }

    .avatar {
      transition: all 1s ease;
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      column-gap: 8px;
      color: #febc00;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .menu {
      display: none;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease-out;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }


  .fadeHeight-enter-active,
  .fadeHeight-leave-active {
    transition: all 0.9s;
    max-height: 230px;
  }

  .fadeHeight-enter,
  .fadeHeight-leave-to {
    opacity: 0;
    max-height: 0px;
  }


}

@media screen and (max-width: 1000px) {
  .navbar {
    .container {
      .nav {
        .img {
          padding: 0 5%;
          display: flex;
          align-items: center;

          .menu-btn {
            display: block;
          }
        }

        ul {
          display: none;
        }
      }

      .login-signup {
        > img {
          display: block;
        }

        .btn {
          margin: 0 10px;
          max-width: 50px;
          padding: 4px;
        }
      }

      .menu {
        z-index: 1;
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        width: 150px;
        height: auto;
        position: fixed;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgb(2, 2, 60);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        overflow: auto;

        border: 1px solid rgba(255, 255, 255, 0.18);

        ul {
          padding: 0 2em;

          li {
            display: block;
            color: white;
          }
        }

        .close-ham {
          font-size: clamp(24px, 1vw, 40px);
          color: #febc00;
          margin: 5px 10px;
          z-index: 2;
          cursor: pointer;
          top: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .menu {
    padding: 10px;
    row-gap: 5px;
    height: 100%;
  }
}
</style>
