import { render, staticRenderFns } from "./Otc.vue?vue&type=template&id=bdb3dcb0&"
import script from "./Otc.vue?vue&type=script&lang=js&"
export * from "./Otc.vue?vue&type=script&lang=js&"
import style0 from "./Otc.vue?vue&type=style&index=0&id=bdb3dcb0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports