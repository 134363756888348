<template>
  <div class="forget" @submit.prevent="submition">
    <form class="form">
      <h1>درخواست رمز عبور جدید</h1>
      <p>لطفا ایمیل خود را وارد کنید.</p>


      <input class="input-email" type="text" placeholder="ایمیل" v-model="email">

      <button id="btn">تایید</button>

    </form>
  </div>
</template>

<script>

const goto = {
  EMAIL: '/login/forget-otp',
  // SMS: '/',
  // GOOGLE_AUTHENTICATOR: '/',
}

export default {
  name: "Forget",
  data() {
    return {
      email: ''
    }
  },
  methods: {
    async checkMail() {
      if (!this.$validEmail(this.email) || !this.email) {
        this.emailError = 'ایمیل صحیح وارد نمایید'
        this.$error('مقادیر اشتباه', 'ایمیل وارد شده صحیح نیست', '', '', '', '2500')
        return Promise.reject('repeat failed : ' + this.email)
      } else return this.emailError = ''

    },


    async submition() {
      this.checkMail()
      if (!this.emailError) {
        const res = await this.$axios.post('/users/forget-password?email=' + this.email)
        if (res.message === 'code sent successfully.') {
          this.state.userInfo.email = this.email;
          this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
          this.state.userInfo.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType;
        } else {
          this.state.userInfo.towStepAuthenticationType = 'EMAIL'
        }
        await this.$router.push(goto[res.baseDTO.towStepAuthenticationType])


      }


    }
  }
}
</script>

<style lang="scss" scoped>
.forget {
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: clamp(20px, 2vw, 24px);
    font-weight: 500;
  }

  p {
    align-self: flex-start;
  }
}

.input-email {
  color: white;
  padding: 0 15px;
  width: 100%;
  min-height: 36px;
  border: 0.5px solid #279bc0;
  border-radius: 4px;
  background: transparent;
}
</style>