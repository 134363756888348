<template>
  <div class="main-container">
    <div class="title">
      <img :src="$coins[target].link" width="32" height="32" alt="coin">
      <h1 style="font-size: 24px;display: inline">

        {{ $coins[target].persianName }}</h1>
    </div>
    <div class="price-info">
      <div class="info">
        <div v-for="(item,index) in info" :key="index" :class="item.class">
          <span>{{ item.title }} :</span>
          <span>{{ $toLocal(item.value, 3) }}</span>

        </div>
        <div class="buttons">
          <button class="btn" v-for="(button,index) in buttons" :class="button.class" :key="index"
                  @click.prevent="$router.push({name:'otc'})">{{ button.title }}
          </button>
        </div>
      </div>
      <vue-trading-view
          class="chart"
          :key="target"

          :options="{
                          symbol: target !== 'TETHER' ? 'KUCOIN:' + $coins[target].code + 'USDT' : 'BINANCEUS:USDTUSD',

              width:'100%',
              height:'400px',
             theme:'dark',
                      timezone: 'Asia/Tehran',
                      style: '1',
                      hide_top_toolbar:true,
                      hide_legend:true,
                      hide_bottom_toolbar:false,
                      hide_legend:false,
                      locale: 'fa_IR',
                      enable_publishing: false,
                      withdateranges: true,
                      range: 'ytd',
                      hide_side_toolbar: true,
                      allow_symbol_change: true,
                      enabled_features: [
                          'header_fullscreen_button'
                          ],
                      disabled_features: [
                          'border_around_the_chart',
                          'remove_library_container_border',
                          ],
                      loading_screen:{
                          backgroundColor:'#F2F6FF',
                          foregroundColor: '#F2F6FF',
                          },
                      overrides: {
                          background: '#F2F6FF'
                      }
          }"
      />
    </div>
    <div v-if="blog" v-html="blog" class="blog">

    </div>
  </div>
</template>
<script>
import VueTradingView from 'vue-trading-view/src/vue-trading-view.vue'

export default {
  name: "coins",
  components: {
    VueTradingView
  },
  data() {
    return {

      last24HourChange: {key: 'last24HourChange', title: 'درصد تغییرات', value: '', class: ''},
      buyPrice: {key: 'buyPrice', title: 'قیمت خرید', value: '', class: 'green'},
      sellPrice: {key: 'sellPrice', title: 'قیت فروش', value: '', class: 'red'},
      info: [],
      blog: ''
    }

  },
  computed: {
    target() {
      return this.$route.params.coin
    },
    buttons() {
      return [{
        title: `خرید ${this.$coins[this.$route.params.coin].persianName}`,
        class: 'buy-btn'
      }, {title: `فروش  ${this.$coins[this.$route.params.coin].persianName}`, class: 'sell-btn'}]
    }
  },
  methods: {
    async getPrice() {
      const res = await this.$axios('/trades/market-info', {
        params: {
          market_type: `${this.target}_TOMAN`,
          weeklyDataIncluded: false
        }
      })
      const result = res.content[0];
      this.last24HourChange.value = result[this.last24HourChange.key];
      this.buyPrice.value = result[this.buyPrice.key];
      this.sellPrice.value = result[this.sellPrice.key];
      this.info = [this.last24HourChange, this.buyPrice, this.sellPrice]


    },
    async getBlog() {
      let res = await this.$axios('/blog')
      res = res.content.find(a=>a.title ===this.target)
      console.log('title',res);
      this.blog = res.body;
    }
  },
  mounted() {
    this.getPrice()
    this.getBlog()
  }


}
</script>
<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  gap: 1em;
}

.red {
  color: red;
}

.green {
  color: green;
}

.blog {
  width: 100%;
}

.main-container {
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin: 2em auto;
  max-width: 1440px;
  border-radius: 12px;
  background: #141b31;
  padding: 1em;
}

.price-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .chart, .info {
    flex: 1 1 280px;
  }

  .info {
    padding: 1em 3em;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 6em;

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    div:last-child {
      justify-content: stretch;
      align-self: flex-end;
      gap: 1em;

      button {
        flex: 1 1 50%;
        height: 48px;
      }
    }
  }

}
</style>