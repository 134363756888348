<template>
  <div class="settings">
    <div class="verifications">
      <h1>شناسایی دوعاملی</h1>
      <p>تایید هویت دو مرحله ای جهت ورود به حساب کاربری و درخواست برداشت :</p>

      <div>
        <form @change="showModal(options.findIndex(e => e.model === twoStep))">
          <div class="radio" v-for="(options , index) in options" :key="index">
            <input type="radio" class="modal-btn" v-model="twoStep" :value="options.model"/>
            <label>
              {{ options.title }}
            </label>
          </div>
        </form>
      </div>

    </div>
    <div class="change-password">

      <change-pass/>

    </div>
    <SMSModal v-if="options[1].modal" @closeModal='closeP' @submit="submitted($event)" :mobile="mobile"
              :two-step="modal"/>
    <EmailModal v-if="options[0].modal" @closeModal='closeE' @submit="submitted($event)" :qr-link="qrLink"
                :two-step="modal"/>
    <google-modal v-if="options[2].modal" @closeModal='closeG' @submit="submitted($event)" :qr-code="qrCode"
                  :qr-link="qrLink"
                  :two-step="modal"/>
  </div>
</template>

<script>
import ChangePass from './changePass.vue';
// import InputPassword from "../../tools/InputPassword.vue";
import EmailModal from "./EmailModal.vue";
import GoogleModal from "./GoogleModal.vue";
import SMSModal from "./SMSModal.vue";

export default {
  components: {
    //  InputPassword,
    SMSModal, EmailModal, GoogleModal,
    ChangePass
  },
  data() {
    return {
      twoStep: this.state.towStepAuthenticationType,
      qrCode: '',
      qrLink: '',
      mobile: '',
      completedModal: false,
      completedText: '',
      modal: '',
      options: [{
        title: "ایمیل",
        modal: false,
        model: 'EMAIL'
      },
        {
          title: "پیامک",
          modal: false,
          model: 'SMS'
        },
        {
          title: "شناسایی دو عاملی گوگل",
          modal: false,
          model: 'GOOGLE_AUTHENTICATOR'
        }
      ]
    }
  },
  methods: {
    //   OpenModal(index) {
    //       this.options[index].modal=true
    //   },
    closeG(value) {
      this.options[2].modal = value
    },
    closeP(value) {
      this.options[1].modal = value
    },
    closeE(value) {
      this.options[0].modal = value
    },
    async getTwoStepAuth() {
      this.twoStep = this.state.userInfo.towStepAuthenticationType

    },
    async showModal(index) {
      this.state.loading = true
      const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=${this.twoStep}`).catch(() => {
        this.getTwoStepAuth()
      })
      console.log(res)
      if (res) {
        this.mobile = res.baseDTO.starTwoStepAuthentication || ''
        this.qrCode = res.baseDTO.file || ''
        this.qrLink = res.baseDTO.qrCodeLink || ''
        this.options[index].modal = true
      }
      this.getTwoStepAuth()
    },
    async submitted(e) {
      this.completedText = e;
      await this.$error('', e, 'success')
      await this.$getuserInfo()
      this.getTwoStepAuth()
    },
  },
  mounted() {
    this.getTwoStepAuth()
  }
};
</script>

<style lang="scss" scoped>


.settings {
  > h1 {
    color: #febc00;
    font-weight: 500;
    font-size: clamp(14px, 1vw, 24px);
  }

  width: 100%;
  // padding: 0 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  gap: 28px;


  .verifications {
    $color1: #279bc0;
    $color2: #fff;

    form {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    display: flex;

    .radio {
      display: flex;

      label {
        margin-right: 16px;
        font-size: clamp(12px, 1vw, 16px);
      }

      input[type='radio'] {

      }

      input[type='radio']:after {

        width: 20px;
        height: 20px;
        border-radius: 15px;
        top: -2px;
        left: 2px;
        position: relative;
        background-color: $color1;;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 0px solid white;
      }

      input[type='radio']:checked:after {
        width: 20px;
        height: 20px;
        border-radius: 15px;
        top: -2px;
        left: 2px;
        position: relative;
        background-color: #FFFFFF;
        content: '';
        display: inline-block;
        visibility: visible;
        box-shadow: inset 0 0 0 5px $color1;
        // border: 2px solid white;
      }

    }

    flex-wrap: wrap;
    width: 100%;
    border-radius: 16px;
    row-gap: 16px;

    > h1 {
      color: #febc00;
      font-weight: 500;
      font-size: 24px;
    }

    padding: 20px;
    display: flex;
    flex-direction: column;
    background: #0f1640;
  }

  .change-password {
    gap: 16px;
    width: 100%;
    border-radius: 16px;

    > h1 {
      color: #febc00;
      font-weight: 500;
      font-size: 24px;
    }

    padding: 20px;
    background: #0f1640;
    display: flex;
    flex-direction: column;

    .inputs {
      gap: 16px;
      justify-content: space-between;
      display: flex;
    }

    .submit {
      align-self: flex-end;
      width: 168px;
      height: 32px;
      background: #279bc0;
      border-radius: 4px;
      color: white;
    }
  }
}

@media screen and (max-width: 768px) {
  .settings {
    padding: 2%;
  }
  .change-password {
    .inputs {
      flex-direction: column;
    }
  }
}
</style>
