<template>
  <!--  <div class="send-phone container">-->
  <div class="phone">
    <TwoFactor
        type="شناسایی دو عاملی"
        title="کد شش رقمی ارسال شده به موبایل خود را وارد کنید"
        :showingInput="false"
        target="/profile"
    >
      <verification/>
    </TwoFactor>
  </div>
  <!--    <div class="icon">-->
  <!--      <InlineSvg-->
  <!--        :src="require('../../assets/login-signup/emailcode.svg')"-->
  <!--      ></InlineSvg>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
import TwoFactor from "../tools/TwoFactor.vue";
// import InlineSvg from "vue-inline-svg";
import Verification from "../tools/Verification.vue";

export default {
  components: {
    TwoFactor,
    // InlineSvg,
    Verification,
  },
};
</script>

<style lang="scss" scoped>
.phone {
  display: flex;
  justify-content: center;

}

</style>
