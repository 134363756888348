<template>
  <div class="">
    <div class="container">
      <div class="wages">
        <div class="banner">
          <div class="box">
            <h1>
              کارمزد ها و هزینه خدمات
            </h1>

            <p>
              کارمزد معاملات تاپننس به صورت درصد از مبلغ کل معامله است و محاسبه آن بر اساس ملاحظات زیر صورت می گیرد:
            </p>

          </div>
          <img src="../../assets/public/wages.png">
        </div>
        <div class="user-levels">
          <p>سطوح کاربری</p>
          <div class="level-table">
            <table>
              <thead>
              <tr>
                <th>سطح کاربری</th>
                <th>میزان معامله در سه ماه اخیر</th>
                <th>کارمزد</th>
                <th>کارمزد تتری</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in levels" :key="index">
                <td class="text-center">{{
                    $levels[item.userAccountLevel]
                  }}
                </td>
                <td class="text-center">{{
                    $toLocal(item.tradeAmount, 0)
                  }}
                </td>
                <td class="text-center">{{
                    item.wagePercent
                  }}%
                </td>
                <td class="text-center">{{
                    item.wagePercentTether
                  }}%
                </td>
              </tr>

              </tbody>
            </table>
          </div>
        </div>
        <div class="user-wage">
          <div class="d-flex justify-content-between wage-title">
            <p>کارمزد برداشت ارزها</p>
            <input type="text" placeholder="جستجو" v-model="regex">
          </div>
          <div class="wage-table">
            <table>
              <thead class="thead">
              <tr>
                <th>نوع ارز</th>
                <th class="mobile">شبکه</th>
                <th>حداقل میزان قابل برداشت</th>
                <th>کارمزد</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in searchWages()" :key="index">
                <td class="text-center">
                  <!--                  <img :src="$coins[item.coin].link" alt="" width="18" height="18">-->
                  {{
                    item.persianName
                  }}
                </td>
                <td class="text-center mobile">
                  <div class="d-flex flex-column">

                    <small v-for="(network,networkIndex) in item.tokenTypeDetails" :key='networkIndex'
                           :value="network.tokenType">{{
                        network.tokenType
                      }}

                    </small>

                  </div>


                </td>
                <td class="text-center">
                  <div class="d-flex flex-column">
                    <span v-for="(fee,feeIndex) in item.tokenTypeDetails" :key="feeIndex">
                      <small>({{
                          item.code
                        }})</small>
                   <small>  {{
                       fee.tokenType
                     }} : {{
                       fee.minWithdraw
                     }}</small>


                  </span>
                  </div>
                </td>
                <td class="text-center">
                  <div class="d-flex flex-column">
                    <span v-for="(fee,feeIndex) in item.tokenTypeDetails" :key="feeIndex">
                         <small>  {{
                             fee.tokenType
                           }} :</small>
                    {{
                        fee.withdrawalFee
                      }}   <small>({{
                        item.code
                      }})</small>

                  </span>
                  </div>
                </td>
              </tr>

              </tbody>
            </table>
          </div>
        </div>
        <div class="wage-rules">
          <p>کارمزد برداشت ریالی</p>
          <div class="box">
            <p>برداشت‌های ریالی برای تمامی حساب‌ها، در قالب انتقال پایا و یک بار در روز خواهد بود. بدیهی است مدت زمان
              انجام تسویه ریالی، بسته به زمان درخواست برداشت، در روزهای غیر تعطیل می‌تواند حداکثر تا ۲۴ ساعت به طول
              انجامد.</p>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent/>
  </div>
</template>

<script>
import FooterComponent from "@/components/sharing-components/footer";
//we need api coins
//we need user account Level.
export default {
  components: {FooterComponent},
  metaInfo:{
    title:'کارمزد های تاپننس',
    links:[{rel:'canonical', href:'https://topnance.com/wages'}],
    meta:[{property:'og:title', content:'کارمزد های تاپننس'},
      {name:'description', content:'کارمزد معاملات تاپننس به صورت درصد از مبلغ کل معامله است و محاسبه آن بر اساس ملاحظات زیر صورت می گیرد:'},
      {property:'og:title', content:'کارمزد های تاپننس'},
      {name:'twitter:card', content:'summary'},
      {name:'twitter:description', content:'کارمزد معاملات تاپننس به صورت درصد از مبلغ کل معامله است و محاسبه آن بر اساس ملاحظات زیر صورت می گیرد:'},
      {name:'twitter:title', content:'کارمزد های تاپننس'},
      {name:'twitter:image', content:'https://topnance.com/logo.png'},
      {property:'og:locale', content:'fa_IR'},
      {property:'og:type', content:'website'},
      {property:'og:title', content:'کارمزد های تاپننس'},
      {property:'og:url', content:'https://topnance.com/wages'},
    ]
  },
  data() {
    return {
      regex: '',
      levels: [
        {
          level: 'سطح کاربری ۱',
          amount: 'کمتر از ۲۰ میلیون تومان',
          wage: '۰.۴٪',
        },
        {
          level: 'سطح کاربری ۱',
          amount: 'کمتر از ۲۰ میلیون تومان',
          wage: '۰.۴٪',
        },
        {
          level: 'سطح کاربری ۱',
          amount: 'کمتر از ۲۰ میلیون تومان',
          wage: '۰.۴٪',
        },
        {
          level: 'سطح کاربری ۱',
          amount: 'کمتر از ۲۰ میلیون تومان',
          wage: '۰.۴٪',
        },
        {
          level: 'سطح کاربری ۱',
          amount: 'کمتر از ۲۰ میلیون تومان',
          wage: '۰.۴٪',
        },
        {
          level: 'سطح کاربری ۱',
          amount: 'کمتر از ۲۰ میلیون تومان',
          wage: '۰.۴٪',
        },


      ],
      wages: [
        {
          coin: 'بیت کوین',
          amount: '۰.۱BTC',
          wage: '۰.۱BTC',
        },
        {
          coin: 'بیت کوین',
          amount: '۰.۱BTC',
          wage: '۰.۱BTC',
        },
        {
          coin: 'بیت کوین',
          amount: '۰.۱BTC',
          wage: '۰.۱BTC',
        },
        {
          coin: 'بیت کوین',
          amount: '۰.۱BTC',
          wage: '۰.۱BTC',
        },
        {
          coin: 'بیت کوین',
          amount: '۰.۱BTC',
          wage: '۰.۱BTC',
        },
        {
          coin: 'بیت کوین',
          amount: '۰.۱BTC',
          wage: '۰.۱BTC',
        },
        {
          coin: 'بیت کوین',
          amount: '۰.۱BTC',
          wage: '۰.۱BTC',
        },
        {
          coin: 'بیت کوین',
          amount: '۰.۱BTC',
          wage: '۰.۱BTC',
        },
        {
          coin: 'بیت کوین',
          amount: '۰.۱BTC',
          wage: '۰.۱BTC',
        },
        {
          coin: 'بیت کوین',
          amount: '۰.۱BTC',
          wage: '۰.۱BTC',
        },
        {
          coin: 'بیت کوین',
          amount: '۰.۱BTC',
          wage: '۰.۱BTC',
        },

      ],
      tokenType: '',


    }
  },
  methods: {
    getValue(e) {
      console.log(e.target.value)
      this.tokenType = e.target.value
    },
    async getAccLevel() {
      const res = await this.$axios('/users/account-levels')

      this.levels = res.filter(item => item.userAccountLevel !== 'SUPERVISOR');
    },
    async getWages() {
      const res = await this.$axios('/coins')
      console.log(res.content);
      this.wages = res.content;
    },

    searchWages() {
      if (this.regex) {
        return this.wages.filter(item => item.persianName.match(this.regex) || item.englishName.toUpperCase().match(this.regex.toUpperCase()));
      }
      return this.wages;
    },


  },
  mounted() {
    this.getAccLevel()
    this.getWages()
  }
};

</script>

<style lang="scss" scoped>
//.image-cont{
//  display: flex;
//  gap: 8px;
//  align-items: center;
//  //margin-right: 0.5em;
//  height: 100%;
//justify-content: center;
//}
.wage-title {
  width: 100%;
  justify-content: space-between;
  color: #FEBC00;
}

.thead {
  position: sticky;
  top: 0;
  background: #141B31;
}

input {
  background: transparent;
  border: 1px solid var(--light-blue);
  border-radius: .5em;
  height: 2.5em;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
  gap: 8px;
}

.container {

}

.wages {
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .banner {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;


    .box {
      margin-top: 85px;
      display: flex;
      justify-content: center;
      padding: 60px 80px;
      flex-direction: column;
      row-gap: 24px;
      width: 595px;
      //min-height: 464px;
      border-radius: 16px;
      background: #141B31;


      h1 {
        font-size: clamp(20px, 2vw, 40px);
        color: #FEBC00;
      }
    }
  }

  .user-levels {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    row-gap: 28px;
    width: 100%;

    > p {
      display: flex;
      align-self: flex-start;
      font-size: clamp(18px, 1vw, 24px);
      color: #FEBC00;
    }

    .level-table {
      width: 100%;
      background-color: #141B31;
      border-radius: 16px;

      table {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;

        thead {
          tr {
            th {
              padding: 40px 0px;
              font-size: clamp(16px, 1vw, 18px);
            }
          }
        }

        tbody {
          tr {
            height: 80px;
            border-bottom: 1px solid white;
          }

          tr:last-child {
            border-bottom: none;
          }
        }
      }

    }
  }

  .user-wage {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    row-gap: 28px;
    width: 100%;

    > p {
      display: flex;
      align-self: flex-start;
      font-size: clamp(18px, 1vw, 24px);
      color: #FEBC00;
    }

    .wage-table {
      width: 100%;
      background-color: #141B31;
      border-radius: 16px;
      height: 90vh;
      max-height: 90vh;
      overflow-y: scroll;

      table {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;

        thead {
          tr {
            th {
              padding: 40px 0px;
              font-size: clamp(16px, 1vw, 18px);
            }
          }
        }

        tbody {
          tr {
            height: 80px;
            border-bottom: 1px solid white;
          }

          tr:last-child {
            border-bottom: none;
          }
        }
      }

    }
  }

  .wage-rules {
    margin-bottom: 96px;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    row-gap: 28px;
    width: 100%;

    > p {
      display: flex;
      align-self: flex-start;
      font-size: clamp(18px, 1vw, 24px);
      color: #FEBC00;
    }

    .box {
      padding: 30px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #141B31;
      border-radius: 16px;
      width: 100%;

      > p {
        max-width: 986px;
      }

    }
  }
}

@media screen and (max-width: 600px) {
  .wages {
    .wage-rules {
      margin-bottom: 10px;

      .box {
        padding: 10px;
      }
    }

    .banner {
      .box {
        margin-top: 10px;
        min-height: 100%;
        padding: 10px;
      }

      > img {
        display: none;
      }
    }
  }


}

@media(max-width: 600px) {
  .mobile {
    display: none;
  }
}
</style>
