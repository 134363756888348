<template>
  <div class="home">
    <banner class="element"/>
    <!-- <dummy /> -->
    <crypto-table class="element"/>
    <Carousel class="element"/>
    <steps class="element"/>
    <Stats class="element"/>
    <FAQ class="element"/>
    <Footer/>

  </div>
</template>

<script>
import Footer from "../components/sharing-components/footer";
import Banner from "../components/home-page/banner.vue";
import FAQ from "../components/home-page/FAQ.vue";
import Steps from "../components/home-page/Steps.vue";
import Stats from "@/components/home-page/Stats";
import Carousel from "@/components/home-page/Carousel";
import CryptoTable from "@/components/home-page/CryptoTable.vue";
// import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
import crypto from "../library/News";
// import Dummy from "../Dummy";
export default {
  name: "Home",
  metaInfo: {
    title: 'صرافی تاپننس',
    links: [{rel: 'canonical', href: 'https://topnance.com'}],
    meta: [{property: 'og:title', content: 'خرید ارز دیجیتال ، فروش ارز دیجیتال | صرافی آنلاین تاپننس'},
      {property: 'og:title', content: 'خرید ارز دیجیتال ، فروش ارز دیجیتال | صرافی آنلاین تاپننس'},
      {property: 'og:locale', content: 'fa_IR'},
      {property: 'og:type', content: 'website'},
      {property: 'og:description', content: 'خرید ارز دیجیتال و فروش ارز دیجیتال با بهترین قیمت و کمترین کارمزد در صرافی آنلاین ارز دیجیتال تاپننس؛ معتبرترین و سریع ترین صرافی ارز دیجیتال در ایران'},
      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:description', content: 'خرید ارز دیجیتال و فروش ارز دیجیتال با بهترین قیمت و کمترین کارمزد در صرافی آنلاین ارز دیجیتال تاپننس؛ معتبرترین و سریع ترین صرافی ارز دیجیتال در ایران'},
      {name: 'twitter:title', content: 'صرافی تاپننس'},
      {name: 'twitter:image', content: 'https://www.topnance.com/img/51.0e1a78ff.png'},
      {name: 'og:image', content: 'https://www.topnance.com/img/51.0e1a78ff.png'},
      {property: 'og:url', content: 'https://topnance.com'},
    ]
  },
  components: {
    // Dummy,
    CryptoTable,
    Carousel,
    Stats,
    Banner,
    Steps,
    FAQ,
    Footer,
    // Slide,
    // Hooper,
  },
  data() {
    return {
      coins: crypto,
      hooperSettings: {
        itemsToShow: 3,
        itemsToSlide: 1,
        // centerMode: true,
      },
    };
  },
};
</script>
<style lang="scss" scoped>
// .hooper-slide {
//   width: 40% !important;
// }
.news-container {
  display: flex;
  width: 100%;

  .price-container {
    p {
      font-size: clamp(8px, 1vw, 12px);
    }

    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    column-gap: 15px;
    max-height: 29px;
  }
}

.news {
  z-index: 6;
  position: fixed;
  display: flex;
  bottom: 0;
  width: 100%;
  max-height: 29px;
  background: #0f1640;
}

.home {
  overflow: hidden;
  position: relative;

  .element {
    margin-top: 120px;
  }

  .element:first-child {
    margin-top: 0px;
  }

  .element:nth-child(6) {
    margin-bottom: 120px;
  }

  //.chat-icon {
  //  position: fixed;
  //  bottom: 15px;
  //  right: 15px;
  //}
}

@media (max-width: 1210px) {
  //.news{
  //  display: none;
  //}
}

@media screen and (max-width: 768px) {
  .home {
    .element {
      margin: 30px 0px;
    }

    .element:nth-child(6) {
      margin-bottom: 30px;
    }
  }
}
</style>
