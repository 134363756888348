var crypto = [
    {
        name: "بیت کوین (BTC)",
        ENname: "BTC",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: -0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/BTC.png"),
    },
    {
        name: " لایت کوین (LTC)",
        ENname: "LTC",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: 0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/LITE.png"),
    },
    {
        name: "تتر (THR)",
        ENname: "THR",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: 0.6,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/TETHER.png"),
    },
    {
        name: "دوج کوین (DOGE)",
        ENname: "DOGE",
        price: 875500,
        buyPriceTooman: 22850,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: -0.3,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/DOGE.png"),
    },
    {
        name: " لایت کوین (LTC)",
        ENname: "LTC",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: 0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/LITE.png"),
    },
    {
        name: "تتر (THR)",
        ENname: "THR",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: 0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/TETHER.png"),
    },
    {
        name: "بیت کوین (BTC)",
        ENname: "BTC",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: -0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/BTC.png"),
    },
    {
        name: " لایت کوین (LTC)",
        ENname: "LTC",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: 0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/LITE.png"),
    },
    {
        name: "تتر (THR)",
        ENname: "THR",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: 0.6,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/TETHER.png"),
    },
    {
        name: "دوج کوین (DOGE)",
        ENname: "DOGE",
        price: 875500,
        buyPriceTooman: 22850,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: -0.3,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/DOGE.png"),
    },
    {
        name: " لایت کوین (LTC)",
        ENname: "LTC",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: 0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/LITE.png"),
    },
    {
        name: "تتر (THR)",
        ENname: "THR",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: 0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/TETHER.png"),
    },
    {
        name: "بیت کوین (BTC)",
        ENname: "BTC",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: -0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/BTC.png"),
    },
    {
        name: " لایت کوین (LTC)",
        ENname: "LTC",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: 0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/LITE.png"),
    },
    {
        name: "تتر (THR)",
        ENname: "THR",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: 0.6,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/TETHER.png"),
    },
    {
        name: "دوج کوین (DOGE)",
        ENname: "DOGE",
        price: 875500,
        buyPriceTooman: 22850,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: -0.3,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/DOGE.png"),
    },
    {
        name: " لایت کوین (LTC)",
        ENname: "LTC",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: 0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/LITE.png"),
    },
    {
        name: "تتر (THR)",
        ENname: "THR",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: 0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/TETHER.png"),
    },
    {
        name: "بیت کوین (BTC)",
        ENname: "BTC",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: -0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/BTC.png"),
    },
    {
        name: " لایت کوین (LTC)",
        ENname: "LTC",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: 0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/LITE.png"),
    },
    {
        name: "تتر (THR)",
        ENname: "THR",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: 0.6,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/TETHER.png"),
    },
    {
        name: "دوج کوین (DOGE)",
        ENname: "DOGE",
        price: 875500,
        buyPriceTooman: 22850,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: -0.3,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/DOGE.png"),
    },
];
export default crypto;
