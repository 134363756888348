<template>
  <transition>
    <div class="modal-backdrop" @click.self="$emit('closeModal')">
      <div class="modal">
        <img @click="$emit('closeModal')" class="close-icon" src="@/assets/profile/closeIcon.svg" alt="">
        <span class="modal-title">
                شناسایی دو عاملی
            </span>
        <div class="input-container">
                <span class="describe">
                    کد 6 رقمی ارسال شده به شماره <span dir="ltr">{{ mobile }}</span> را وارد نمایید
                </span>
          <CodeInput :loading="false" @change="onChange" @complete="submit" ref="code-input"/>
          <div>
            <p class="countDown_text">
              {{ countDown }} ثانیه
            </p>
          </div>
        </div>
        <button class="active-btn" v-if="countDown===0" @click.prevent="send">
          ارسال مجدد
        </button>
        <button v-else :disabled=disablebtn
                :class="{'deActive-btn' :this.disablebtn , 'active-btn' : !this.disablebtn}">
          ثبت
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import CodeInput from "vue-verification-code-input";
// import {
//     Copy,
//     reset,
//     paste
// } from "@/library/reuseableFunction";
export default {
  name: 'WithPhone',
  components: {
    CodeInput,
  },
  props: ['twoStep', 'mobile'],
  data() {
    return {
      close: false,
      disablebtn: true,
      amount: '',
      twostepcode: '',
      walletaddress: '',
      smscode: [],
      error: '',
      num: 6,
      otp: {
        code: '',
      },
      timeOut: '',
      countDown: 120,

    }
  },
  methods: {
    closeModale() {
      this.$emit('closeModal', this.close)
    },
    // onComplete() {
    //   this.disablebtn=false
    // },
    countDownTimer() {
      if (this.countDown > 0) {
        this.timeOut = setTimeout(() => {
          this.countDown--
          this.countDownTimer()
        }, 1000)
      } else {
        this.$error('زمان شما به اتمام رسید', '')
      }
    },
    async send() {
      // this.reset()
      this.state.loading = true
      const a = {
        EMAIL: 'ایمیل',
        SMS: 'شماره'
      }
      const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=EMAIL`)
      if (res.message === 'Verification Code Sent') {
        this.$error('', 'کد به ' + a[this.twoStep] + ' شما ارسال شد', 'success')
        if (this.countDown === 0)
          this.countDown = 120
        this.countDownTimer()
      }
    },
    // close() {
    //     this.$emit('close');
    // },
    onChange(e) {
      this.otp.code = e
    },
    async submit() {
      this.disablebtn = false
      // const a = {
      //     'EMAIL': 'ایمیل',
      //     'SMS': 'پیامک',
      //     'GOOGLE_AUTHENTICATOR': 'Google Authenticator'
      // }
      if (this.otp.code.toString().length === 6 && (this.twoStep === 'GOOGLE_AUTHENTICATOR' || this.countDown !== 0)) {
        if (this.$route.name !== 'Wallet') {
          this.state.loading = true
          const [res,] = await this.$http.post(`/users/change-otp?twoStepAuthorization=SMS&code=${this.otp.code}`)
          if (res) {
            // this.reset()
            this.$emit('closeModal')
            this.$emit('submit', 'شناسایی دو مرحله ای شما به ' + 'شماره' + ' تغییر یافت.')
          }
        } else {
          this.$emit('submit', this.otp.code)
        }
      }
    },
    // Copy,
    // paste,
    // reset
  },
  mounted() {
    this.$refs['code-input'].$refs['input_0'][0].focus()
    this.countDownTimer()
    document.body.style.overflow = 'hidden'
  },
  beforeDestroy() {
    clearTimeout(this.timeOut)
    document.body.style.overflow = 'auto'
  },

  // created: function () {
  //     window.addEventListener("click", (event) => {
  //         if (!event.target.closest('.modal , .modal-btn')) {
  //             this.$emit('closeModal', this.close)
  //         }
  //     })
  // },
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(111, 111, 112, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}

.modal {
  display: flex;
  flex-grow: 1;
  max-width: 370px;
  min-height: 286px;
  background: #001E3D;
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.close-icon {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 16px;
}

.modal-title {
  font-weight: bold;
  font-size: 24px;
  color: #FEFEFE;
}

.describe {
  font-size: 12px;
  color: #FEFEFE;
}

.deActive-btn,
.active-btn {
  border-radius: 6px;
  border: none;
  color: #FCFCFC;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  height: 44px;
}

.active-btn {
  background: #19196f;
}

.deActive-btn {
  background: #19196f;
  opacity: 0.7;
}

.countDown_text {
  color: #FCFCFC;
}
</style>
