<template>
  <div id="app">
    <navbar @closeNav="showNav = false"/>
    <dummy/>
<transition
mode="out-in"
 enter-active-class="animate__animated animate__fadeIn"
 leave-active-class = "animate__animated animate__fadeOut"
>
  <router-view/>
</transition>

    <LoadingModal v-if="state.loading"/>
    <connection-modal v-if="!connection"/>
  </div>
</template>

<script>
import Navbar from "./components/sharing-components/Navbar.vue";
import LoadingModal from "@/components/LoadingModal";
import ConnectionModal from "@/components/ConnectionModal";
// import Dummy from './components/Dummy.vue';

export default {
  components: {ConnectionModal, LoadingModal, Navbar,},
  data() {
    return {
      connection:true,
    };
  },
  mounted() {
    window.addEventListener('offline',()=>{this.connection=false})
    window.addEventListener('online',()=>{this.connection=true})
  }
};
</script>

<style lang="scss">
#app {
  max-width: 2000px;
  margin: 0 auto;
}
</style>
