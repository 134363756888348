var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth container"},[_c('div',{staticClass:"forms"},[(_vm.$route.path === '/login')?_c('login'):_vm._e(),(_vm.$route.path === '/sign-up')?_c('sign-up'):_vm._e(),(_vm.$route.path === '/login/send-phone')?_c('send-phone'):_vm._e(),(_vm.$route.path === '/login/send-mail')?_c('send-mail'):_vm._e(),(_vm.$route.path === '/sign-up/email-verification')?_c('email-verification'):_vm._e(),(_vm.$route.path==='/login/forget')?_c('forget'):_vm._e(),(_vm.$route.path === '/login/forget-otp')?_c('forget-otp'):_vm._e(),(_vm.$route.path === '/login/forget/verify')?_c('new-password'):_vm._e()],1),_c('div',{staticClass:"icon"},[(_vm.$route.path === '/login')?_c('InlineSvg',{attrs:{"src":require('../../assets/login-signup/login.svg')}}):_vm._e(),(_vm.$route.path === '/sign-up')?_c('InlineSvg',{attrs:{"src":require('../../assets/login-signup/signup.svg')}}):_vm._e(),(
        _vm.$route.path === '/login/send-phone' ||
        _vm.$route.path === '/login/send-mail' ||
        _vm.$route.path === '/sign-up/email-verification'
      )?_c('InlineSvg',{attrs:{"src":require('../../assets/login-signup/emailcode.svg')}}):_vm._e(),(
        _vm.$route.path === '/login/forget-otp' ||
        _vm.$route.path === '/login/forget' ||
        _vm.$route.path === '/login/forget/verify'
      )?_c('InlineSvg',{attrs:{"src":require('../../assets/login-signup/forget.svg')}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }