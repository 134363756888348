<template>
  <div class="carousel">
    <div class="container">
      <vueper-slides
          class="no-shadow"
          :visible-slides="3"
          :rtl="true"
          :gap="0"
          :bullets="false"
          slide-multiple
          :slide-ratio="1 /3"
          autoplay
          duration="4000"
          :infinite="true"
          :dragging-distance="200"
          :breakpoints="{ 1042: { visibleSlides: 2, slideMultiple: 1 } , 722:{
        visibleSlides: 1, slideMultiple: 1 ,
        slideRatio: 1/2
      }}">

        <template v-for="(news,index) in news">

          <vueper-slide :key="index"
                        :title="news.title.rendered.slice(0,30)+'...'"
                        :content="$G2J(news.date)"
                        :image="news._embedded['wp:featuredmedia'][0].source_url"
                        :link="news.link"
/>


        </template>


      </vueper-slides>
    </div>
  </div>
</template>
<script>
import {VueperSlides, VueperSlide} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: "Carousel",
  components: {VueperSlides, VueperSlide},


  data() {
    return {
      news: [{
        title: "اخبار",
      },
        {
          title: "اخبار",
        },
        {
          title: "اخبار",
        }
      ],
      slides: [{
        image: require('@/assets/public/carrousel.png')
      },
        {
          image: require('@/assets/public/carrousel.png')
        },
        {
          image: require('@/assets/public/carrousel.png')
        },
        {
          image: require('@/assets/public/carrousel.png')
        },
        {
          image: require('@/assets/public/carrousel.png')
        },
        {
          image: require('@/assets/public/carrousel.png')
        },
        {
          image: require('@/assets/public/carrousel.png')
        },
        {
          image: require('@/assets/public/carrousel.png')
        },
        {
          image: require('@/assets/public/carrousel.png')
        },
        {
          image: require('@/assets/public/carrousel.png')
        },
        {
          image: require('@/assets/public/carrousel.png')
        },
        {
          image: require('@/assets/public/carrousel.png')
        },


      ]
    }
  },

  methods: {
    async getNews() {
      this.state.loading = true;
      await fetch('https://topnance.com/fa/wp-json/wp/v2/posts?per_page=8&context=embed&page=1&_embed=true').then(response => response.json()).then(data => this.news = data);
      this.state.loading = false;
    }
  },
  mounted() {
    this.getNews()
  },
}

</script>

<style lang="scss" scoped>
.carousel {

  display: flex;

  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;

  align-items: center;
  background: #141B31;
  background-size: cover;
  min-height: 447px;

  .container {
    max-width: 1440px;
    width: 100%;
    //backdrop-filter: blur(200px);
    min-height: 449px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    flex-wrap: wrap;
    align-items: center;


    &::before {
      content: '';
      border-radius: 4px;
      position: absolute;
      z-index: -1;
      width: 100%;
      max-width: 100%;
      left: 28px;
      height: 438px;
      background-color: transparent;
      background-image: linear-gradient(#19196F 0%, #FEBC00 100%);
      filter: blur(20px);

      transition: 0.5s ease;
    }
  }
}
</style>
<style lang="scss">
.no-shadow {
  width: 100%;
  height: 100%;

  
  
}


.vueperslide__title {
  position: absolute;
  top: 28%;
  left: 18%;
  background: #001E3D;
  border-radius: 8px;
  padding: 1rem;
}

.vueperslide__content {
  background: #3D49CC;
  position: absolute;
  bottom: 30%;
  right: 18%;
  padding: 5px;
  border-radius: 8px;
}


.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--next, .vueperslides__arrows--outside .vueperslides__arrow--prev {
  right: auto;
  left: 2px;

  > svg {
    transform: scale(0.5);
  }

  .vueper-image {

  }
}

.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--prev, .vueperslides__arrows--outside .vueperslides__arrow--next {
  left: auto;
  right: 2px;
}

.vueperslide, .vueperslide__image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 333.3px 230px;
}

</style>

<style lang="scss" scoped>
@media screen and (max-width: 500px) {

  .carousel {
    min-height: 200px;

    .container {
      min-height: 200px;

      &:before {
        display: none;
      }
    }
  }

  .vueperslide, .vueperslide__image {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 95% 100%;

  }
}
</style>