<template>
  <div class="send-mail">
    <form class="form" @submit.prevent="submit">
      <div v-if="(state.towStepAuthenticationType = 'EMAIL')">
        <h1>تایید آدرس ایمیل</h1>
        <p>لطفا کد ارسال شده به ایمیل را وارد کنید.</p>
      </div>

      <div class="pass-container">
        <input
            ref="password"
            :type="type"
            placeholder="گذرواژه جدید"
            class="password-input"
            v-model="otp.password"
        />
        <button class="show-pass" @click.prevent="showingPass">
          <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M14.2432 9.33928C15.5663 7.68724 16.3003 5.52317 16.2992 3.27732C16.2992 3.17567 16.2641 3.07818 16.2016 3.0063C16.1391 2.93442 16.0543 2.89404 15.9659 2.89404C15.8775 2.89404 15.7927 2.93442 15.7302 3.0063C15.6677 3.07818 15.6326 3.17567 15.6326 3.27732C15.6326 5.45268 14.881 7.53894 13.5432 9.07715C12.2055 10.6154 10.3911 11.4795 8.49922 11.4795C6.60734 11.4795 4.79295 10.6154 3.45519 9.07715C2.11743 7.53894 1.36589 5.45268 1.36589 3.27732C1.36589 3.17567 1.33077 3.07818 1.26826 3.0063C1.20574 2.93442 1.12096 2.89404 1.03255 2.89404C0.944148 2.89404 0.859363 2.93442 0.796851 3.0063C0.734339 3.07818 0.69922 3.17567 0.69922 3.27732C0.698119 5.52317 1.4321 7.68724 2.75522 9.33928L0.872553 11.504C0.841511 11.5396 0.816883 11.5819 0.800078 11.6285C0.783274 11.6751 0.774624 11.725 0.774624 11.7754C0.774624 11.8258 0.783274 11.8757 0.800078 11.9223C0.816883 11.9689 0.841511 12.0112 0.872553 12.0468C0.902933 12.083 0.939403 12.1117 0.979745 12.1312C1.02009 12.1507 1.06346 12.1606 1.10722 12.1602C1.15134 12.1603 1.19504 12.1503 1.23578 12.1309C1.27652 12.1114 1.31349 12.0828 1.34455 12.0468L3.22722 9.882C4.57976 11.3133 6.3294 12.1475 8.16589 12.2369V15.3031C8.16589 15.4048 8.20101 15.5023 8.26352 15.5741C8.32603 15.646 8.41081 15.6864 8.49922 15.6864C8.58763 15.6864 8.67241 15.646 8.73492 15.5741C8.79743 15.5023 8.83255 15.4048 8.83255 15.3031V12.2369C10.669 12.1475 12.4187 11.3133 13.7712 9.882L15.6539 12.0468C15.6849 12.0828 15.7219 12.1114 15.7627 12.1309C15.8034 12.1503 15.8471 12.1603 15.8912 12.1602C15.935 12.1606 15.9784 12.1507 16.0187 12.1312C16.059 12.1117 16.0955 12.083 16.1259 12.0468C16.1569 12.0112 16.1816 11.9689 16.1984 11.9223C16.2152 11.8757 16.2238 11.8258 16.2238 11.7754C16.2238 11.725 16.2152 11.6751 16.1984 11.6285C16.1816 11.5819 16.1569 11.5396 16.1259 11.504L14.2432 9.33928Z"
                fill="white"
            />
            <path
                d="M6.63236 8.75052H10.3657C10.6661 8.74971 10.9539 8.61215 11.1663 8.36794C11.3787 8.12372 11.4983 7.79273 11.499 7.44736V4.38113C11.4988 4.04954 11.3881 3.73066 11.1897 3.49001C10.9913 3.24935 10.7203 3.1052 10.4324 3.08717V2.54138C10.4324 1.9518 10.2287 1.38637 9.8661 0.969468C9.50353 0.55257 9.01178 0.318359 8.49902 0.318359C7.98627 0.318359 7.49452 0.55257 7.13195 0.969468C6.76938 1.38637 6.56569 1.9518 6.56569 2.54138V3.09331C6.27774 3.11133 6.00673 3.25549 5.80835 3.49614C5.60997 3.73679 5.49928 4.05567 5.49902 4.38726V7.4535C5.50113 7.7978 5.62138 8.12722 5.83362 8.37011C6.04586 8.613 6.33291 8.74972 6.63236 8.75052ZM7.23236 2.54138C7.23236 2.1551 7.36581 1.78465 7.60335 1.51151C7.8409 1.23837 8.16308 1.08492 8.49902 1.08492C8.83496 1.08492 9.15715 1.23837 9.39469 1.51151C9.63224 1.78465 9.76569 2.1551 9.76569 2.54138V3.09331H7.23236V2.54138ZM6.16569 4.38113C6.16639 4.23906 6.21578 4.10304 6.30315 4.00259C6.39051 3.90213 6.50881 3.84534 6.63236 3.84453H10.3657C10.4892 3.84534 10.6075 3.90213 10.6949 4.00259C10.7823 4.10304 10.8317 4.23906 10.8324 4.38113V7.44736C10.8341 7.51911 10.8234 7.59054 10.8007 7.65742C10.7781 7.7243 10.744 7.78528 10.7005 7.83674C10.657 7.8882 10.6049 7.92911 10.5474 7.95703C10.4899 7.98495 10.4281 7.99932 10.3657 7.99929H6.63236C6.50859 7.99929 6.38989 7.94275 6.30237 7.84212C6.21486 7.74149 6.16569 7.60501 6.16569 7.4627V4.38113Z"
                fill="white"
            />
          </svg>
        </button>
      </div>
      <div class="pass-container">
        <input
            ref="password"
            :type="typeRep"
            placeholder="تکرار گذرواژه جدید"
            class="password-input"
            v-model="repeat"
        />
        <button class="show-pass" @click.prevent="showingPassRep">
          <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M14.2432 9.33928C15.5663 7.68724 16.3003 5.52317 16.2992 3.27732C16.2992 3.17567 16.2641 3.07818 16.2016 3.0063C16.1391 2.93442 16.0543 2.89404 15.9659 2.89404C15.8775 2.89404 15.7927 2.93442 15.7302 3.0063C15.6677 3.07818 15.6326 3.17567 15.6326 3.27732C15.6326 5.45268 14.881 7.53894 13.5432 9.07715C12.2055 10.6154 10.3911 11.4795 8.49922 11.4795C6.60734 11.4795 4.79295 10.6154 3.45519 9.07715C2.11743 7.53894 1.36589 5.45268 1.36589 3.27732C1.36589 3.17567 1.33077 3.07818 1.26826 3.0063C1.20574 2.93442 1.12096 2.89404 1.03255 2.89404C0.944148 2.89404 0.859363 2.93442 0.796851 3.0063C0.734339 3.07818 0.69922 3.17567 0.69922 3.27732C0.698119 5.52317 1.4321 7.68724 2.75522 9.33928L0.872553 11.504C0.841511 11.5396 0.816883 11.5819 0.800078 11.6285C0.783274 11.6751 0.774624 11.725 0.774624 11.7754C0.774624 11.8258 0.783274 11.8757 0.800078 11.9223C0.816883 11.9689 0.841511 12.0112 0.872553 12.0468C0.902933 12.083 0.939403 12.1117 0.979745 12.1312C1.02009 12.1507 1.06346 12.1606 1.10722 12.1602C1.15134 12.1603 1.19504 12.1503 1.23578 12.1309C1.27652 12.1114 1.31349 12.0828 1.34455 12.0468L3.22722 9.882C4.57976 11.3133 6.3294 12.1475 8.16589 12.2369V15.3031C8.16589 15.4048 8.20101 15.5023 8.26352 15.5741C8.32603 15.646 8.41081 15.6864 8.49922 15.6864C8.58763 15.6864 8.67241 15.646 8.73492 15.5741C8.79743 15.5023 8.83255 15.4048 8.83255 15.3031V12.2369C10.669 12.1475 12.4187 11.3133 13.7712 9.882L15.6539 12.0468C15.6849 12.0828 15.7219 12.1114 15.7627 12.1309C15.8034 12.1503 15.8471 12.1603 15.8912 12.1602C15.935 12.1606 15.9784 12.1507 16.0187 12.1312C16.059 12.1117 16.0955 12.083 16.1259 12.0468C16.1569 12.0112 16.1816 11.9689 16.1984 11.9223C16.2152 11.8757 16.2238 11.8258 16.2238 11.7754C16.2238 11.725 16.2152 11.6751 16.1984 11.6285C16.1816 11.5819 16.1569 11.5396 16.1259 11.504L14.2432 9.33928Z"
                fill="white"
            />
            <path
                d="M6.63236 8.75052H10.3657C10.6661 8.74971 10.9539 8.61215 11.1663 8.36794C11.3787 8.12372 11.4983 7.79273 11.499 7.44736V4.38113C11.4988 4.04954 11.3881 3.73066 11.1897 3.49001C10.9913 3.24935 10.7203 3.1052 10.4324 3.08717V2.54138C10.4324 1.9518 10.2287 1.38637 9.8661 0.969468C9.50353 0.55257 9.01178 0.318359 8.49902 0.318359C7.98627 0.318359 7.49452 0.55257 7.13195 0.969468C6.76938 1.38637 6.56569 1.9518 6.56569 2.54138V3.09331C6.27774 3.11133 6.00673 3.25549 5.80835 3.49614C5.60997 3.73679 5.49928 4.05567 5.49902 4.38726V7.4535C5.50113 7.7978 5.62138 8.12722 5.83362 8.37011C6.04586 8.613 6.33291 8.74972 6.63236 8.75052ZM7.23236 2.54138C7.23236 2.1551 7.36581 1.78465 7.60335 1.51151C7.8409 1.23837 8.16308 1.08492 8.49902 1.08492C8.83496 1.08492 9.15715 1.23837 9.39469 1.51151C9.63224 1.78465 9.76569 2.1551 9.76569 2.54138V3.09331H7.23236V2.54138ZM6.16569 4.38113C6.16639 4.23906 6.21578 4.10304 6.30315 4.00259C6.39051 3.90213 6.50881 3.84534 6.63236 3.84453H10.3657C10.4892 3.84534 10.6075 3.90213 10.6949 4.00259C10.7823 4.10304 10.8317 4.23906 10.8324 4.38113V7.44736C10.8341 7.51911 10.8234 7.59054 10.8007 7.65742C10.7781 7.7243 10.744 7.78528 10.7005 7.83674C10.657 7.8882 10.6049 7.92911 10.5474 7.95703C10.4899 7.98495 10.4281 7.99932 10.3657 7.99929H6.63236C6.50859 7.99929 6.38989 7.94275 6.30237 7.84212C6.21486 7.74149 6.16569 7.60501 6.16569 7.4627V4.38113Z"
                fill="white"
            />
          </svg>
        </button>
      </div>
      <div class="verification">
        <CodeInput
            class="code-input"
            @change="onChange"
            @complete="submit"
            :fields="num"
            ref="code-input"
        />
        <div class="timer">
          <div class="timer" v-if="finished">
            <a>ارسال مجدد</a>
          </div>
          <div class="timer" v-else>
            {{ display }}
          </div>
        </div>
      </div>
      <button id="btn" v-if="!finished" @click.prevent="submit">تایید</button>
      <button id="btn2" v-if="finished" @click.prevent="resetCode">
        تلاش دوباره
      </button>
    </form>
  </div>

  <!--    <div class="icon">-->
  <!--      <InlineSvg-->
  <!--        :src="require('../../assets/login-signup/forget.svg')"-->
  <!--      ></InlineSvg>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
// import TwoFactor from "../tools/TwoFactor.vue";
// // import InlineSvg from "vue-inline-svg";
// import Verification from "../tools/Verification.vue";
import CodeInput from "vue-verification-code-input";
import {DateTime, Duration} from "luxon";

export default {
  components: {
    CodeInput,
  },
  data() {
    return {
      type: "password",
      typeRep: "password",
      now: DateTime.local().set({milliseconds: 0}),
      end: DateTime.local().set({milliseconds: 0}).plus({seconds: 5}),
      tick: null,
      num: 6,
      passError: "",
      otp: {
        code: "",
        email: this.state.userInfo.email || this.$cookies.get("username") || "",
        mobile: this.state.userInfo.mobile || this.$cookies.get("mobile") || "",
        password: "",
      },
      repeat: "",
    };
  },
  watch: {
    now() {
      if (this.finished) {
        clearInterval(this.tick);
      }
    },
  },
  mounted() {
    if (!this.$cookies.isKey("username")) {
      this.$router.push("/login");
    }
    if (this.pathMatch !== "google-auth") {
      this.now = DateTime.local().set({milliseconds: 0});
      this.end = DateTime.local()
          .set({milliseconds: 0})
          .plus({seconds: 120});
      this.tick = setInterval(() => {
        this.now = DateTime.local().set({milliseconds: 0});
      }, 1000);
    }

    this.tick = setInterval(() => {
      this.now = DateTime.local().set({milliseconds: 0});
    }, 1000);
  },
  computed: {
    remaining() {
      return this.end.diff(this.now).toObject();
    },
    display() {
      return Duration.fromObject(this.remaining).toFormat("mm:ss");
    },
    finished() {
      return this.now >= this.end;
    },
  },
  methods: {
    showingPass() {
      if (this.type === "password") {
        this.type = "text";
      } else if (this.type === "text") {
        this.type = "password";
      }
    },
    showingPassRep() {
      if (this.typeRep === "password") {
        this.typeRep = "text";
      } else if (this.typeRep === "text") {
        this.typeRep = "password";
      }
    },

    //repeat password Guard
    async checkPassword() {
      if (!this.repeat || !this.otp.password) {
        this.passError = "رمز اجباری می باشد";
        this.$error("", this.passError);
      } else if (this.repeat !== this.otp.password) {
        this.passError = "رمز های عبور باید یکی باشند";
        this.$error("خطای تکرار", this.passError);
      } else {
        if (this.otp.password.length < 8) {
          this.passError = "رمز حداقل باید 8 کارکتر باشد";
          this.$error("", this.passError);
        } else if (!this.$validPass(this.otp.password)) {
          this.passError = "رمز باید ترکیبی از حرف بزرگ و کوچک باشد";
          this.$error("", this.passError);
        } else {
          return (this.passError = "");
        }
      }
    },

    //check password Guard

    //submit code and password and email to BackEnd
    async submit() {
      await this.checkPassword();

      if (!this.passError) {
        this.state.loading=true;

        const res = await this.$axios.post("/users/reset-password/", this.otp);
        if (res) {
          this.$error("رمز عبور با موفقیت تغییر پیدا کرد", "", "success");
          await this.$router.push("/login");

        }
        this.state.loading=false;
        await this.$router.push('/forget');

      }
    },

    onChange(e) {
      this.otp.code = e;
    },

    //reset password send again
    async resetCode() {
      this.state.loading = true
      await this.$axios.post(`/users/resend-otp-code?email=${this.$cookies.get('username')}`)
      this.$error('کد جدید  ' + '  ارسال شد.', '', 'success')
      this.$cookies.set('username', this.state.userInfo.email)
      //codes that will reset our code
      if (this.now >= this.end) {
        this.now = DateTime.local().set({milliseconds: 0});
        this.end = DateTime.local()
            .set({milliseconds: 0})
            .plus({seconds: 120});
        this.tick = setInterval(() => {
          this.now = DateTime.local().set({milliseconds: 0});
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss">
.forget {
  min-height: 86vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.password-input {
  padding: 0 15px;
  color: white;
  width: 100%;
  min-height: 36px;
  border: 0.5px solid #279bc0;
  border-radius: 4px;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: transparent;
}

.pass-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-pass {
  height: 36px;

  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #279bc0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 0;
}

@media screen and (max-width: 1230px) {
  .forget {
    justify-content: center;
    align-content: center;
  }
}
</style>
