<template>
  <div class="btc">
    <div class="btns">
      <button
          class="toman"
          @click.prevent="toman=true ; activeBtn() "
          :class="{ active: toman }"
      >
        تومان
      </button>
      <button
          class="tether"
          @click.prevent="toman=false ; activeBtn()"
          :class="{ active: !toman }"
      >
        تتر
      </button>
    </div>
    <!--    <vuescroll :ops="ops"  class="scroll-bar" >-->
    <div class="table-container">
      <table class="btc-table">
        <!--        <thead>-->
        <!--        <tr>-->
        <!--          <th class="th">نوع سفارشات</th>-->
        <!--          <th class="th tablet">رمز ارز</th>-->
        <!--          <th class="th small-pc">قیمت واحد-->
        <!--            <span>(تومان)</span>-->
        <!--          </th>-->
        <!--          <th class="th small-pc"> مقدار</th>-->
        <!--          <th class="th tablet">قیمت کل-->
        <!--            <span>(تومان)</span></th>-->
        <!--          <th class="th tablet">کارمزد</th>-->
        <!--          <th class="th tablet">زمان</th>-->
        <!--          <th class="th tablet">وضعیت</th>-->
        <!--        </tr>-->
        <!--        </thead>-->
        <tbody>
        <tr class="table-row" v-for="(coin , index) in tableContents" :key="index"
            @click="goto($coins[coin.destCoinSymbol].coin)">
          <td class="text  coin-name"><span>  <img :src="$coins[coin.destCoinSymbol].link" alt=""
                                                   width="20" height="20"></span>
            {{ $coins[coin.destCoinSymbol].persianName }}

            
          </td>
          <td class="text tablet"
              :class="{'negetive' :coin.price24hrChangePercent < 0  , 'positive': coin.price24hrChangePercent>0}">
            {{ parseFloat(Math.abs(coin.price24hrChangePercent)).toFixed(2) }}
          </td>
          <td class="text ">{{ $toLocal(coin.price, 3) }}</td>

        </tr>
        </tbody>
      </table>

    </div>
    <!--    </vuescroll>-->
  </div>
</template>

<script>

// import vuescroll from "vuescroll";
import {
  tradeTo,
  tradeFrom,
  Loop
} from "@/library/reuseableFunction";

export default {
  name: "pricesTable",
  components: {
    // vuescroll
  },
  data() {
    return {
      toman: true,

      tableContents: [],
      loading: false,
      loop: undefined,
      coins: [],
      TomanCoin: 'market_type=BITCOIN_TOMAN' +
          '&market_type=BITCOIN_CASH_TOMAN' +
          '&market_type=ETHEREUM_TOMAN' +
          '&market_type=LITE_COIN_TOMAN' +
          '&market_type=BINANCE_COIN_BSC_TOMAN' +
          '&market_type=STELLAR_TOMAN' +
          '&market_type=TRON_TOMAN' +
          '&market_type=CELO_TOMAN' +
          '&market_type=RIPPLE_TOMAN' +
          '&market_type=DOGE_COIN_TOMAN' +
          '&market_type=MATIC_TOMAN' +
          '&market_type=TETHER_TOMAN',
      TetherCoin: 'market_type=BITCOIN_TETHER' +
          '&market_type=BITCOIN_CASH_TETHER' +
          '&market_type=ETHEREUM_TETHER' +
          '&market_type=LITE_COIN_TETHER' +
          '&market_type=BINANCE_COIN_BSC_TETHER' +
          '&market_type=MATIC_TETHER' +

          '&market_type=STELLAR_TETHER' +
          '&market_type=TRON_TETHER' +
          '&market_type=RIPPLE_TETHER' +
          '&market_type=DOGE_COIN_TETHER' +
          '&market_type=CELO_TETHER',


    };
  },
  watch: {
    'toman'() {
      this.tab()
    }
  },
  computed: {
    tradeTo,
    tradeFrom
  },
  methods: {
    activeBtn() {

      this.$router.push('/profile/trade/' + (this.toman ? 'TOMAN' : 'TETHER') + '/' + this.tradeFrom)
      this.setData()
      this.getPrice()

    },
    getCurrency(value) {
      this.items[0].class = 'deActive'
      this.items[1].class = 'deActive'
      this.items[value].class = 'active'
      // this.currency=value
      this.setData()
      this.getPrice()
    },
    async setData() {

      this.state.loading = true
      const res = await this.$axios.get('/trades/market-info?', {
        params: {
          weeklyDataIncluded: true,
          // marketType: this.active ? this.TomanCoin : this.TetherCoin
        }
      })
      this.coins = res.content
      // this.tableContents = res.content;
      // this.$emit('get-data', res.content)
      // this.state.loading=false
    },
    async getPrice() {
      //    this.state.loading=true
      // this.state.loading=false
      const link = this.toman ? '/coins/price/usd' : '/coins/price/usdt'
      let [res,] = await this.$http.get(link, {
        params: {
          include24hrChange: true,
          includeWeeklyChange: false
        }
      })
      console.log(res)
      this.loading = false
      if (res) {
        this.tableContents = res
        this.sort()
      }

    },
    goto(e) {
      this.$router.push('/profile/trade/' + (this.toman ? 'TOMAN' : 'TETHER') + `/${e}`)
    },
    sort() {
      this.tableContents.sort((a, b) => this.$sortCoins.indexOf(a.sourceCoinSymbol) - this.$sortCoins.indexOf(b.sourceCoinSymbol))
    },
    tab() {
      // (this.loop ? this.loop.stop() : '')
      // this.loop.stop()
      this.tableContents = []
      if (this.state.loop) {
        this.beforeLoop()
      } else {
        this.state.loading = true
        this.getPrice()

      }
    },
    beforeLoop() {
      this.loading = true
      if (this.state.loop) {
        this.loop = new Loop(this.getPrice, 5000, 'realPrice' + (this.toman ? 'Toman' : 'Tether'))
        this.loop.start()
      } else {
        this.getPrice()
      }
    }
  },
  mounted() {
    this.beforeLoop()
  },
  beforeDestroy() {
    this.loop.stop()
  }
};
</script>

<style lang="scss" scoped>
.coin-name {
  cursor: pointer;
  display: flex;
  //justify-content: center;
  align-items: center;
  height: 50px;
  column-gap: 8px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.btc {
  background: #0f1640;
  border-radius: 16px;

  padding: 24px;
  height: 564px;
  max-height: 317px;

  .btns {
    display: flex;
    width: 100%;
    max-width: 280px;
    background: rgba(156, 152, 192, 0.1);
    min-height: 36px;
    border-radius: 8px;

    .toman,
    .tether {
      width: 100%;
      border-radius: 8px;
      background: transparent;
      color: white;
    }

    .active {
      background: #279bc0;
      border-radius: 8px;
    }
  }

  .table-container {
    height: 80%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &.table-container::-webkit-scrollbar {
      display: none;
    }
  }


  .btc-table {
    width: 100%;

    max-height: 524px;
    overflow: auto;


    .th {
      color: #febc00;
      font-size: clamp(12px, 1vw, 14px);
      overflow: auto;
      min-width: 50px;
    }

    .table-row {
      text-align: center;
      height: 50px;

      .text {
        font-size: clamp(12px, 1vw, 14px);
      }

      .tablet {
      }

      .phone {

      }

      .small-pc {
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .small-pc {
    display: none;
  }


}

@media screen and (max-width: 980px) {
  .btc {
    max-height: 300px;
  }
  .tablet {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .btc {
    min-width: 250px;

  }
  .phone {
    width: 90%;
    display: none;
  }
  .btns {
    width: 90%;
    min-width: 80px;
    max-width: 100px;
  }
}
</style>
