<template>
  <div class="document">
    <div class="first-col">
      <div>
        <div class="terms">
<h3>متن تعهدنامه هنگام ثبت نام</h3>
          من  ..................... با شماره ملی ................... با اطلاع کامل و پذیرش ریسکهای سرمایه گذاری در ارزهای دیجیتال، ضمن مطالعه و قبول کلیه قوانین استفاده از سایت و خدمات تاپ ننس، مدارک احراز هویت خود را به قصد انجام معاملات انواع ارزدیجیتال با موجودی حسابم که متعلق به خودم می باشد در topnance.com ارسال کرده و ثبت نام نموده ام. همچنین متعهد می‌شوم حساب کاربری، حساب بانکی و مدارک خود را در اختیار اشخاص غیر قرار ندهم و تاکید می کنم که در هر صورت مسئولیت و عواقب عدم رعایت قوانین سایت یا خلاف این اظهاراتم را در کلیه مراجع قضایی ، پلیسی، اداری و بانکی می پذیرم.
<p class="name">        نام و نام خانوادگی
  </p>
          <p class="name">تاریخ</p>

        </div>
      </div>
      <form class="forms" @submit.prevent="upload" @dragover.prevent @dragenter="hovered=!hovered">
        <label for="picture" class="input-file-btn">
          <div class="input-file-container" @dragleave="hovered=!hovered"
               @drop.stop.prevent="hovered=false;drop($event);" :style="{borderColor : hovered? 'blue' : ''}">
            <img v-if="path" class="img" :src="path" alt="" width="280" height="280">
            <img v-else class="img" src="@/assets/profile/cloud.png">
            <input type="file" name="picture" id="picture" style="opacity: 0" @change="upload($event.target)">
            <span v-if="!path"> بارگذاری تصویر</span>
          </div>
          <button class="btn" type="submit" @submit.prevent="upload" >اپلود</button>
        </label>
      </form>

    </div>
    <div class="second-col">
      <p>
        <img src="@/assets/profile/icons/warning.png" alt="">
        کاربر گرامی جهت تسریع در انجام فرایند احراز هویت، تصویر کارت ملی را در کنار چهره خودتان همراه با یک دست نوشته به
        صورت کاملا خوانا دقیقا مانند تصویر نمونه ، ارسال نمایید . دقت شود متن دست نوشته نبایستی تایپ شود. هم چنین توجه
        داشته باشید فرمت عکس ها png یا jpg باشد  و هنگام تهیه تصویر ، از واضح بودن متن دست نوشته و اطلاعات کارت ملی با بزرگنمایی تصویر اطمینان حاصل
        نموده و همچنین توجه داشته باشید که تصویر ارسالی به صورت برعکس و آینه ای ثبت نشود
      </p>
      <div class="pictures">
        <img src="../../../assets/profile/female.png" alt="">
        <img src="../../../assets/profile/male.png" alt="">
      </div>

      <div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Documents",
  components: {},
  props: ['data'],

  data() {
    return {
      path: '',
      loadedpicture: '',
      hovered: '',
    }
  },
  watch: {
    'data.image'() {
      if (this.data.image)
          if (typeof this.data.image === 'string'){
            this.path = this.data.image
          }

    }
  },
  methods: {
    drop(e) {
      let dt = e.dataTransfer
      this.upload(dt)
      console.log(e)
    },
    upload(evt) {
      let file = evt.files[0]
      this.data.image = file
      console.log(file)
      let fileReader = new FileReader();
      fileReader.addEventListener("loadstart", (event) => {
        this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
      })
      fileReader.addEventListener("progress", (event) => {
        this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
      });
      fileReader.addEventListener('load', (event) => {
        this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
        this.path = event.target.result
      });
      fileReader.readAsDataURL(file)

    },
    hover() {
      this.hovered = true
    }
  }
}
</script>

<style lang="scss" scoped>
.terms{
  display: flex;
  flex-direction: column;
  h3{
    color: red;
    align-self: center;
  }
  .name{
    display: flex;
    align-items: center;
    align-self: flex-end;
    text-align: center;
  }
}
.btn {
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.picture {
  height: 200px;
}
#picture{
  width: 100%;
  height: 100% ;
  position: absolute;
}

.img {
  height: 100%;
  width: 100%;
  max-width: 100px !important;
  max-height: 100px !important;

}

.forms {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}


.file {
  width: 100%;
  height: 100%;
}

.btn {
  width: 100%;
  background: #19196F;
  min-height: 40px;
  border-radius: 6px;
}

.pictures {
  display: flex;

  gap: 16px;

  > img {
    width: 50%;
  }
}

.first-col, .second-col {
  display: flex;
  flex-direction: column;
  row-gap: 64px;
}

.input-file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 21px 0px;
  border: 1px dashed white;
  border-radius: 6px;
}

.document {
  background: #0F1640;
  border-radius: 16px;
  width: 100%;
  min-height: 465px;
  display: flex;

  column-gap: 20%;
  padding: 33px 48px;

  div {
    min-width: 200px;
  }
}

@media (max-width: 650px) {
  .pictures {
    flex-direction: column;
    align-items: center;
  }
  .document {
    flex-direction: column;
    padding: 10px;
  }
}
</style>
