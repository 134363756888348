<template>
  <div class="main">
    <div class="first-container">
      <pro-trade/>
    </div>
    <div class="second-container">
      <trade-tables/>
    </div>
  </div>
</template>

<script>
import ProTrade from "@/components/profile/trade/ProTrade";
import TradeTables from "@/components/profile/trade/TradeTables";

export default {
  name: "Trade",
  components: {TradeTables, ProTrade},

};

</script>

<style lang="scss" scoped>
.main {
  display: flex;
  justify-content: center;

  gap: 8px;

  .first-container {
    // max-width: 889px;
    flex-basis: 100px;
    flex-grow: 2;
  }

  .second-container {
    max-width: 355px;
    flex-grow: 1;
    flex-basis: 100px;
  }
}

@media (max-width: 1047px) {
  .main {
    flex-wrap: wrap;
  }
  .second-container {
    max-width: none !important;
  }
}

@media (max-width: 870px) {
  .main {
    flex-wrap: wrap !important;
  }
}

@media (max-width: 963px) {
  .first-container {
    // height: 62.5%;
  }
}
</style>
