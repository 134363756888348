<template>
  <div class="trading">
    <vue-trading-view
        :key="tradeFrom"
        style="height: 430px"
        :options="{
                          symbol: tradeFrom !== 'TETHER' ? 'KUCOIN:' + $coins[tradeFrom].code + 'USDT' : 'BINANCEUS:USDTUSD',

              width:'100%',
             theme:'dark',
                      timezone: 'Asia/Tehran',
                      style: '1',
                      hide_top_toolbar:true,
                      hide_legend:true,
                      hide_bottom_toolbar:false,
                      hide_legend:false,
                      locale: 'fa_IR',
                      enable_publishing: false,
                      withdateranges: true,
                      range: 'ytd',
                      hide_side_toolbar: true,
                      allow_symbol_change: true,
                      enabled_features: [
                          'header_fullscreen_button'
                          ],
                      disabled_features: [
                          'border_around_the_chart',
                          'remove_library_container_border',
                          ],
                      loading_screen:{
                          backgroundColor:'#F2F6FF',
                          foregroundColor: '#F2F6FF',
                          },
                      overrides: {
                          background: '#F2F6FF'
                      }
          }"
    />
  </div>
</template>

<script>
import {tradeFrom, tradeTo} from "@/library/reuseableFunction"
import VueTradingView from 'vue-trading-view/src/vue-trading-view.vue'

export default {
  name: 'Tradingchart',
  components: {
    VueTradingView
  },
  computed: {
    tradeTo,
    tradeFrom
  },
  props: ['selectedCoin'],
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.trading {
  flex-grow: 100;
  //min-height: 430px;
  //max-height: 249px;
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid var(--border-color);
  //   min-width: 710px;
}

#vue-trading-view {
  //max-height: 249px !important;
}
</style>