<template>
  <transition name="modal-fade" v-if="closeIt">
    <div class="modal-backdrop" @click.self="closeModale">
      <div class="modal">

        <img
            @click="closeModale"
            class="close-icon"
            src="../../../assets/profile/closeIcon.svg"
            alt=""
        />
        <div class="message">
          <span class="message-title"> برداشت از کیف پول تومان </span>
        </div>
        <div class="amount">
          <input
              placeholder="مبلغ به تومان"
              name="wallet"
              type="text"
              v-model="amount"
              @input="amount = $toLocal(amount,2)"
              class="normal-input"
          />
          <p>
            موجودی <span class="price"> {{ $toLocal(cash, 0) }} </span> تومان
          </p>
        </div>
        <div class="dropdown">
          <button
              class="btn"
              @click.prevent="openDropdown = !openDropdown"
              id="openDrop"
          >
            <span> {{ select }}</span>
            <InlineSvg
                :src="require('../../../assets/profile/icons/dropdown.svg')"
            />
          </button>
          <label for="openDrop">
            <a @click="$router.push('/profile/account')" href="">+ افزودن حساب جدید</a>
          </label>
          <div class="list" v-if="openDropdown">
            <div
                v-for="(Bank, index) in Banks"
                :key="index"
                @click.prevent="selectItems(index)"
            >
              <p>{{ Bank.cardNumber }}</p>
            </div>
          </div>
        </div>
        <div class="code-sent">
          <div class="warning" v-if="!continu">
            <span
            ><svg
                width="24"
                height="22"
                viewBox="0 0 24 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_539_5498)">
                  <path
                      d="M12.9154 0C10.7233 0 8.58048 0.645139 6.75783 1.85383C4.93517 3.06253 3.51459 4.78049 2.67571 6.79048C1.83683 8.80047 1.61735 11.0122 2.045 13.146C2.47266 15.2798 3.52825 17.2398 5.07829 18.7782C6.62833 20.3166 8.6032 21.3642 10.7532 21.7886C12.9031 22.2131 15.1316 21.9952 17.1569 21.1627C19.1821 20.3301 20.9131 18.9202 22.1309 17.1113C23.3488 15.3023 23.9988 13.1756 23.9988 11C23.9957 8.08359 22.8269 5.28753 20.7491 3.22531C18.6712 1.16309 15.854 0.00315432 12.9154 0V0ZM12.9154 20.1667C11.0887 20.1667 9.30298 19.629 7.7841 18.6218C6.26522 17.6146 5.08139 16.1829 4.38233 14.5079C3.68327 12.8329 3.50036 10.9898 3.85674 9.21167C4.21312 7.43351 5.09278 5.80017 6.38448 4.51819C7.67618 3.23621 9.32191 2.36317 11.1135 2.00947C12.9052 1.65577 14.7623 1.8373 16.45 2.5311C18.1377 3.22491 19.5801 4.39982 20.595 5.90727C21.6099 7.41472 22.1516 9.187 22.1516 11C22.1489 13.4303 21.175 15.7604 19.4434 17.4789C17.7119 19.1974 15.3642 20.164 12.9154 20.1667Z"
                      fill="#E63756"
                  />
                  <path
                      d="M12.9158 4.5835C12.6708 4.5835 12.4359 4.68007 12.2627 4.85198C12.0895 5.02389 11.9922 5.25705 11.9922 5.50016V12.8335C11.9922 13.0766 12.0895 13.3098 12.2627 13.4817C12.4359 13.6536 12.6708 13.7502 12.9158 13.7502C13.1608 13.7502 13.3957 13.6536 13.5689 13.4817C13.7421 13.3098 13.8394 13.0766 13.8394 12.8335V5.50016C13.8394 5.25705 13.7421 5.02389 13.5689 4.85198C13.3957 4.68007 13.1608 4.5835 12.9158 4.5835Z"
                      fill="#E63756"
                  />
                  <path
                      d="M13.8394 16.5002C13.8394 15.9939 13.4259 15.5835 12.9158 15.5835C12.4057 15.5835 11.9922 15.9939 11.9922 16.5002C11.9922 17.0064 12.4057 17.4168 12.9158 17.4168C13.4259 17.4168 13.8394 17.0064 13.8394 16.5002Z"
                      fill="#E63756"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_539_5498">
                    <rect width="23.9984" height="22" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </span>
            <div class="warning-text">
              <p>درخواست‌های تسویه ریالی در روزهای تعطیل به دلیل</p>
              <p>تعطیلی بانک‌ها و عدم فعالیت سیستم پایا و ساتنا انجام</p>
              <p>نمی شود .</p>
            </div>
          </div>
          <input
              placeholder=" کد ارسال شده را وارد کنید "
              v-if="continu"
              class="normal-input"
              v-model="TwoStep_code"
              name="code"
              type="text"
          />
          <p v-if="continu" class="timer">{{ countDown }}</p>
        </div>
        <button v-if="!continu" class="cont" @click="withdraw" :disabled="!!disabled">
          {{ !!disabled ? disabled : 'ادامه' }}
        </button>
        <button v-if="continu&&countDown!==0" class="submit" @click="submitWithdrawRequest">
          ثبت
        </button>
        <button v-if="countDown===0" class="submit" @click="withdraw" :disabled="!!disabled">
          ارسال مجدد
        </button>
      </div>
    </div>
  </transition>
</template>

<script>


import InlineSvg from "vue-inline-svg";

export default {
  name: "RialWithdrawlModal",
  components: {


    InlineSvg,
  },


  props: {
    relatedCoin: {
      default: "",
    },

    wage: {
      default: 0,
    },

  },
  data() {
    return {
      closeIt: true,
      continu: false,
      submit: false,
      success: false,
      select: "",
      openDropdown: false,
      banksOptions: false,
      unit: "تومان",
      countDown: 120,
      timeOut: '',
      Banks: [],
      amount: 0,
      role: {},
      cash: 0,
      walletAddress: "",
      submitBTN: false,
      sendBTN: false,
      TwoStep_code: "",
      transaction: {
        code: this.TwoStep_code,
      },

      BankCart: [
        {
          cardNumber: "۵۸۹۲۱۰۱۱۵۹۰۳۰۷۸۸",
        },
        {
          cardNumber: "۶۱۰۴۳۳۷۷۶۲۳۴۵۲۰۱",
        },
        {
          cardNumber: "۵۸۹۲۱۰۱۱۵۹۰۳۰۷۸۸",
        },
        {
          cardNumber: "۱۲۴۵۱۲۳۶۵۴۱۲۳۶۵۴",
        },
      ],
    };
  },
  computed: {
    disabled() {
      let Error = ''

      if (!this.$S2N(this.amount)) {
        Error = 'مبلغ وارد شده صحیح نیست'
      } else if (this.$S2N(this.amount) > this.$S2N(this.cash)) {
        Error = "مبلغ وارد شده بیشتر از موجودی کیف پول شما می باشد"
      } else if (this.$S2N(this.amount) > this.$S2N(this.max)) {
        Error = "مبلغ وارد شده بیشتر از حداکثر برداشتی است"
      } else if (this.$S2N(this.amount) < this.$S2N(this.min)) {
        Error = "مبلغ وارد شده کمتر از حداقل برداشتی است"
      } else if (!this.walletAddress) {
        Error = "آدرس کیف پول وارد نشده است"
      }

      // return (
      //   !this.$S2N(this.amount) ||
      //   this.$S2N(this.amount) > this.$S2N(this.cash) ||
      //   this.$S2N(this.amount) > this.$S2N(this.max) ||
      //   this.$S2N(this.amount) < this.$S2N(this.min) ||
      //    !this.walletAddress 
      // );
      return Error
    },
    min() {
      return this.role.minWithdrawAmount;
    },
    max() {
      return this.role.maxWithdrawAmount;
    },
    danger() {
      return (
          "حداقل " +
          this.$toLocal(this.min) +
          " تومان" +
          "\n" +
          "حداکثر " +
          this.$toLocal(this.max) +
          " تومان"
      );
    },
  },
  methods: {
    countDownTimer() {
      this.continu = true;
      if (this.countDown > 0) {
        this.timeOut = setTimeout(() => {
          this.countDown--;
          this.countDownTimer();
        }, 1000);
      } else {
        this.$error("زمان شما به اتمام رسید", "");

      }
    },
    closeModale() {
      this.$emit("closeModal");
    },
    logOut() {
      this.$logout();
      // this.$router.push('/login')
    },
    selectItems(index) {
      this.select = this.Banks[index].cardNumber;
      this.openDropdown = false;
    },
    async getData() {
      this.state.loading = true;
      const res = await this.$axios.get("/users/verified-bank-accounts");
      this.Banks = res.content;
    },
    async getInventory() {
      this.state.loading = true;
      let res = await this.$axios('/wallets/customer-addresses?walletType=P2P')
      this.cash = res["TOMAN"].credit

    },
    async getDataCoins() {
      this.state.loading = true
      const res = await this.$axios.get('/coins/details')
      this.coins = res.content
      this.role = this.coins.find((a) => a.name === "TOMAN")

    },
    async withdraw() {
      this.state.loading = true;
      this.countDown = 120
      const res = await this.$axios.post("/users/send-code");
      if (res.message === "code sent successfully.") {
        this.sendBTN = true;
        this.continu = true
        this.countDownTimer();
        // this.$emit(
        //     "two-fa",
        //     [transaction, res.baseDTO.towStepAuthenticationType]
        // );
      }
    },
    async submitWithdrawRequest() {
      //  this.closemodale();
      this.state.loading = true;
      this.transaction.amount = this.$S2N(this.amount);
      this.transaction.destinationWalletAddress = this.walletAddress;
      this.transaction.relatedCoin = "TOMAN";
      this.transaction.code = this.TwoStep_code;
      const [res] = await this.$http.post(
          `/wallets/withdrawal-request`,
          this.transaction
      );
      if (res) {
        this.closeModale();
        this.$error("درخواست برداشت شما با موفقیت ثبت شد", "", "success");
        ++this.state.requestChanged;

      }
    },
  },
  // created: function () {
  //   window.addEventListener("click", (event) => {
  //     if (!event.target.closest(".modal,.diposit,.withdrawl,.list")) {
  //       this.$emit("closeModal", this.close);
  //     } else {
  //       return true;
  //     }
  //   });
  // },
  async mounted() {
    this.getDataCoins()
    this.getInventory()
    await this.getData()
    this.select = this.Banks[0].cardNumber
    this.walletAddress = this.select
  },
  beforeDestroy() {
    clearTimeout(this.timeOut)
  }
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(111, 111, 112, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}

.modal {
  display: flex;
  row-gap: 44.5px;
  flex-direction: column;
  align-items: center;

  min-width: 431px;
  max-width: 461px;
  min-height: 276px;
  background: #141b31;
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 15px;
}

.close-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.message {
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .message-title {
    text-align: center;
    font-size: 18px;
    color: #febc00;
  }

  .warning {
    display: flex;
    column-gap: 5px;

    .warning-text {
      display: flex;
      flex-direction: column;

      > p {
        font-size: 14px;
        color: #9c9fc0;
      }
    }
  }
}

.amount {
  display: flex;
  width: 100%;
  flex-direction: column;

  > p {
    font-size: 12px;
    color: #9c9fc0;
  }

  .price {
    color: #279bc0;
  }
}

.dropdown {
  position: relative;
  width: 100%;
  background: none !important;

  button {
    width: 100%;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #279bc0;
    border-radius: 4px;
    min-height: 36px;
    min-width: 174px;
  }

  > label {
    display: flex;
    justify-content: flex-end;

    > a {
      color: #febc00;
      font-size: 12px;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    position: absolute;
    top: 35px;
    margin-top: 2px;
    background: rgb(10, 17, 40);
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
  }
}

.code-sent {
  .warning {
    display: flex;
    column-gap: 5px;
    color: #999;
    font-size: clamp(12px, 1vw, 14px);
  }

  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 4px;
}

.cont {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 32px;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  background: #279bc0;
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 32px;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  background: #e63756;
}

@media screen and (max-width: 768px) {
  .modal {
    row-gap: 5px;
    max-height: unset;
    justify-content: flex-start;
    overflow-y: scroll;
    width: 90%;
    min-width: 300px;
    height: 80%;
  }
}
</style>
