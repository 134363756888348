<template>
  <div class="send-mail">
    <div class="form">
      <div class="" v-if="state.towStepAuthenticationType == 'EMAIL'">
        <h1>تایید آدرس ایمیل</h1>
        <p>کد شش رقمی ارسال شده به {{ otp.email }} را وارد کنید.</p>
      </div>
      <div class="" v-if="state.towStepAuthenticationType == 'SMS'">
        <h1>تایید تلفن همراه</h1>
        <p>کد ارسال شده به شماره {{ otp.mobile }}را وارد نمایید</p>
      </div>
      <div v-if="state.towStepAuthenticationType == 'GOOGLE_AUTHENTICATOR'">
        <h1>تایید آدرس GA</h1>
        <p>کد شش رقمی ارسال شده به Google authenticator را وارد کنید.</p>
      </div>
      <div class="verification">
        <div class="" @paste="paste">
          <CodeInput
            class="code-input"
            @change="onChange"
            @complete="submit"
            :fields="num"
            ref="code-input"
          />
        </div>
        <div class="timer">
          <div class="timer" v-if="finished">
            <a class="zero">0:00</a>
          </div>
          <div class="timer" v-else>
            {{ display }}
          </div>
        </div>
      </div>
      <button id="btn" v-if="!finished" @click.prevent="submit">تایید</button>
      <button id="btn2" v-if="finished" @click.prevent="resetCode">
        تلاش دوباره
      </button>
    </div>
  </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import { paste } from "@/library/reuseableFunction";
import { DateTime, Duration } from "luxon";

export default {
  components: {
    CodeInput,
  },
  data() {
    return {
      //timer
      now: DateTime.local().set({ milliseconds: 0 }),
      end: DateTime.local().set({ milliseconds: 0 }).plus({ seconds: 120 }),
      tick: null,
      num: 6,
      //otp
      otp: {
        code: "",
        email: this.state.userInfo.email || this.$cookies.get("username"),
        mobile: this.state.userInfo.mobile,
        // google: this.state.userInfo.google,
      },
    };
  },
  watch: {
    now() {
      if (this.finished) {
        clearInterval(this.tick);
      }
    },
  },
  computed: {
    remaining() {
      return this.end.diff(this.now).toObject();
    },
    display() {
      return Duration.fromObject(this.remaining).toFormat("mm:ss");
    },
    finished() {
      return this.now >= this.end;
    },
  },
  mounted() {
    this.tick = setInterval(() => {
      this.now = DateTime.local().set({ milliseconds: 0 });
    }, 1000);
    if (!this.otp.email) {
      this.$router.push("/login");
    }
    if (this.state.towStepAuthenticationType !== "GOOGLE_AUTHENTICATOR") {
      this.now = DateTime.local().set({ milliseconds: 0 });
      this.end = DateTime.local()
        .set({ milliseconds: 0 })
        .plus({ seconds: 120 });
      this.tick = setInterval(() => {
        this.now = DateTime.local().set({ milliseconds: 0 });
      }, 1000);
    }
  },
  methods: {
    paste,
    onChange(e) {
      this.otp.code = e;
    },
    async submit() {
      this.disablebtn = false;
      if (!this.finished) {
        this.state.loading = true;
        const res = await this.$axios.post("/users/check-otp-code", this.otp);
        if (res.token) {
          await this.$setToken(res.token);

          // await !this.state.authorized ? this.$router.push("/profile/account") : this.$router.push("/profile/dashboard");

          if (this.state.userInfo.authentication ==='AUTHORIZED') {
            await this.$router.push("/profile");
          } else {
           await  this.$router.push("/profile/account");
          }
        }
      }
    },
    async resetCode() {
      this.state.loading = true;
      await this.$axios.post(
        `/users/resend-otp-code?email=${this.$cookies.get("username")}`
      );
      this.$error("کد جدید  " + "  ارسال شد.", "", "success");
      this.$cookies.set("username", this.state.userInfo.email);

      //codes that will reset our code
      if (this.now >= this.end) {
        this.now = DateTime.local().set({ milliseconds: 0 });
        this.end = DateTime.local()
          .set({ milliseconds: 0 })
          .plus({ seconds: 120 });
        this.tick = setInterval(() => {
          this.now = DateTime.local().set({ milliseconds: 0 });
        }, 1000);
      }
      ``;

      // this.state.loading = true
      // this.otp.code = ''
      // await this.$axios.post(`/users/resend-otp-code?email=${this.$cookies.get('username')}`)
      // this.$error('کد جدید  ' +'  ارسال شد.', '', 'success')
      // this.$cookies.set('username', this.state.userInfo.email)
      // if (this.pathMatch !== 'google-auth') {
      //   if (this.now >= this.end) {
      //     console.log('hi')
      //
      //   }
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.zero {
  color: #febc00;
}

.send-mail {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
