var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-container"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":_vm.$coins[_vm.target].link,"width":"32","height":"32","alt":"coin"}}),_c('h1',{staticStyle:{"font-size":"24px","display":"inline"}},[_vm._v(" "+_vm._s(_vm.$coins[_vm.target].persianName))])]),_c('div',{staticClass:"price-info"},[_c('div',{staticClass:"info"},[_vm._l((_vm.info),function(item,index){return _c('div',{key:index,class:item.class},[_c('span',[_vm._v(_vm._s(item.title)+" :")]),_c('span',[_vm._v(_vm._s(_vm.$toLocal(item.value, 3)))])])}),_c('div',{staticClass:"buttons"},_vm._l((_vm.buttons),function(button,index){return _c('button',{key:index,staticClass:"btn",class:button.class,on:{"click":function($event){$event.preventDefault();return _vm.$router.push({name:'otc'})}}},[_vm._v(_vm._s(button.title)+" ")])}),0)],2),_c('vue-trading-view',{key:_vm.target,staticClass:"chart",attrs:{"options":{
                        symbol: _vm.target !== 'TETHER' ? 'KUCOIN:' + _vm.$coins[_vm.target].code + 'USDT' : 'BINANCEUS:USDTUSD',

            width:'100%',
            height:'400px',
           theme:'dark',
                    timezone: 'Asia/Tehran',
                    style: '1',
                    hide_top_toolbar:true,
                    hide_legend:true,
                    hide_bottom_toolbar:false,
                    hide_legend:false,
                    locale: 'fa_IR',
                    enable_publishing: false,
                    withdateranges: true,
                    range: 'ytd',
                    hide_side_toolbar: true,
                    allow_symbol_change: true,
                    enabled_features: [
                        'header_fullscreen_button'
                        ],
                    disabled_features: [
                        'border_around_the_chart',
                        'remove_library_container_border',
                        ],
                    loading_screen:{
                        backgroundColor:'#F2F6FF',
                        foregroundColor: '#F2F6FF',
                        },
                    overrides: {
                        background: '#F2F6FF'
                    }
        }}})],1),(_vm.blog)?_c('div',{staticClass:"blog",domProps:{"innerHTML":_vm._s(_vm.blog)}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }