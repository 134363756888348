<template class="trading-history">
  <div class="btc">
    <div class="btns">
      <button
          class="activeOrders"
          @click.prevent="activeOrders=true"
          :class="{ active: activeOrders }"
      >
        سفارشات فعال
      </button>
      <button
          class="withdrawl"
          @click.prevent="activeOrders=false"
          :class="{ active: !activeOrders }"
      >
        تاریخچه سفارشات

      </button>
    </div>
    <!--    <vuescroll :ops="ops"  class="scroll-bar" >-->
    <div class="table-container">
      <activeOrder v-if="activeOrders"
                   :key="$route.params.tradeTo + $route.params.tradeFrom + state.changeOrder +  'order'"/>
      <history-order v-if="!activeOrders"
                     :key=" $route.params.tradeTo + $route.params.tradeFrom + state.changeOrder +  'his'"/>
    </div>
    <!--    </vuescroll>-->
  </div>
</template>

<script>
// import InlineSvg from "vue-inline-svg";
// import vuescroll from "vuescroll";
import activeOrder from "@/components/profile/trade/tradinghistory/activeOrder";
import {
  tradeFrom,
  tradeTo,
  precision,
  Loop
} from "@/library/reuseableFunction";
import HistoryOrder from "@/components/profile/trade/tradinghistory/historyOrder";

export default {
  name: "TransActionBTC",
  components: {
    HistoryOrder,
    // InlineSvg,
    activeOrder
    // vuescroll
  },
  data() {
    return {
      activeOrders: true,
      withdrawl: false,
      tableContentsActive: [],
      cancelModal: false,
      modalshow: false,
      removeid: 0,
      tableContents: [],
      loading: false,
      completedModal: false,
      loop: '',


    };
  },
  computed: {
    precision,
    tradeFrom,
    tradeTo
  },
  methods: {
    closemodal() {
      this.modalshow = false
    },
    showModal(e) {
      this.loop.stop()
      this.cancelModal = true
      this.removeid = e.id
    },
    async removeorder() {
      //   this.state.loading = true
      this.cancelModal = false
      const [res,] = await this.$http.delete(`orders/${this.removeid}`)
      if (res) {
        ++this.state.orderChange
        this.modalshow = false
        this.completedModal = true
        this.$error('سفارش با موفقیت حذف شد', '', 'success')
        if (this.state.loop) {
          this.loop.start()
        } else {
          this.getActiveOrders()
        }
      }
    },
    async getActiveOrders() {
      // this.state.loading = true
      const res = await this.$axios.get('/orders', {
        params: {
          orderStatus: 'IS_OPEN',
          market: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
        }
      })
      this.tableContentsActive = res.content;
      this.loading = false
    },
    activeBtn() {
      if (this.activeOrders) {
        this.withdrawl = true;
        this.activeOrders = false;
      } else {
        this.activeOrders = true;
        this.withdrawl = false;
      }
    },
  },
  mounted() {
    this.loading = true
    if (this.state.loop) {
      this.loop = new Loop(this.getActiveOrders, 5000, 'activeOrder')
      this.loop.start()
    } else {
      this.getActiveOrders()
    }
  },
  beforeDestroy() {
    this.loop.stop()
  }
};
</script>

<style lang="scss" scoped>
.btc {
  background: #0f1640;
  border-radius: 16px;
  padding: 24px;

  .btns {
    display: flex;
    width: 100%;
    max-width: 280px;
    background: rgba(156, 152, 192, 0.1);
    min-height: 36px;
    border-radius: 8px;

    .activeOrders,
    .withdrawl {
      width: 100%;
      border-radius: 8px;
      background: transparent;
      color: white;
    }

    .active {
      background: #279bc0;
      border-radius: 8px;
    }
  }

  .table-container {
    height: 70%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

  }

  .table-container::-webkit-scrollbar {
    display: none;
  }

  .btc-table {
    width: 100%;
    padding: 20px;
    max-height: 524px;
    overflow: auto;

    table {
      max-height: 90%;
      overflow: auto;
    }

    .th {
      color: #febc00;
      font-size: clamp(12px, 1vw, 16px);
      overflow: auto;

      min-width: 50px;
    }

    .table-row {
      text-align: center;
      padding: 20px;
      height: 50px;

      .text {
        font-size: clamp(12px, 1vw, 16px);
      }

      .tablet {
      }

      .phone {
      }

      .small-pc {
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .small-pc {
    display: none;
  }
}

@media screen and (max-width: 980px) {
  .tablet {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .trading-history {
    max-height: 350px;
  }
  .btc {
    max-height: 350px;
  }
  .table-container {
    height: 90%;
  }
  .phone {
    display: none;
  }
  .btns {
    width: 90%;
    min-width: 80px;
    max-width: 100px;
  }
}
</style>
