<template>
  <div class="activeorders">
    <div class="order-container">
      <h3 class="text-center">تاریخچه سفارشات</h3>
      <div class="orders">
        <p class="red " v-if="!tableContents.length">سفارشی وجود ندارد</p>
        <template v-for="(order, index) in tableContents">
          <div
              class="order"
              :key="index"
          >
            <div class="order-time-store">
              <div class="coin-type">
                <img v-if="order.orderType.includes('BUY')" :src="$coins[order.destinationCoin].link"
                     :alt=" order.destinationCoin" width="24px">
                <img v-else :src="$coins[order.sourceCoin].link" :alt=" order.sourceCoin" width="24px">
                <p class="coin-color" v-if="order.orderType.includes('BUY')">{{ $coins[order.destinationCoin].persianName }}</p>
                <p class="coin-color" v-else>{{ $coins[order.sourceCoin].persianName }}</p>
                <p>-</p>
                <p :class="{'finish':order.orderType.includes('BUY') , 'cancel':order.orderType.includes('SELL') }">
                  {{ $orderType[order.orderType] }}</p>

              </div>
              <div class="coin-situation">
                <p
                    class="gray"

                >
                  {{ $orderStat[order.orderStatus] }}
                </p>
              </div>
              <div class="time">
                <p class="gray">
                  {{ $G2J(order.createdAtDateTime) }}
                </p>
              </div>
            </div>
            <div class="order-situation-type">
              <!--                            <p class="finish">{{order.dealtype}}</p>-->
            </div>
            <div class="details-table">
              <table>
                <thead>
                <tr>
                  <td class="gray">مقدار</td>
                  <td class="gray">قیمت</td>
                  <td class="remove gray">مجموع</td>
                  <td class="gray">اتمام شده</td>

                </tr>
                </thead>
                <tr class="item">
                  <td :class="{'finish':order.orderType.includes('BUY') , 'cancel':order.orderType.includes('SELL') }">
                    {{ order.amount }}
                  </td>
                  <td :class="{'finish':order.orderType.includes('BUY') , 'cancel':order.orderType.includes('SELL') }">
                    {{ order.unitPrice.toLocaleString() }}
                  </td>
                  <td :class="{'finish':order.orderType.includes('BUY') , 'cancel':order.orderType.includes('SELL') }"
                      class="remove">{{ $toLocal(order.executedAmount * order.unitPrice,0) }}
                  </td>
                  <td :class="{'finish':order.orderType.includes('BUY') , 'cancel':order.orderType.includes('SELL') }">
                    {{ order.executedPercent.toFixed(1) }} %
                  </td>

                </tr>
              </table>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Activeorders",
  data() {
    return {

      tableContents: [],
      loading: false,
      alive: false,
      loopIsAlive: false,
    };
  },
  methods: {
    async getActiveOrders() {
      const res = await this.$axios.get('/orders')
      console.log(res.content)
      this.tableContents = res.content.filter(order=>!order.orderStatus.includes('IS_OPEN')) || [];
      this.$emit('update:activeLength', this.tableContents.length)
    },
    async loop() {
      // this.state.loading = true
      this.loading = true

      while (this.alive) {
        this.loopIsAlive = true
        await this.getActiveOrders()
        this.loading = false
        await this.$sleep(5000)
      }
      this.loopIsAlive = false
    }
  },
  async mounted() {

    this.alive = true
    this.loading = true
    if (this.state.loop) {
      this.loop()
    } else {
      await this.getActiveOrders()
      this.loading = false
    }
  },
  beforeDestroy() {
    this.alive = false
  }
}


</script>
<style lang="scss" scoped>
.red{
  color:red;
  text-align: center;
}
table {
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: clamp(9px, 1vw, 12px);
  table-layout: fixed;
}

.coin-color {
  color: #febc00;
  width: 45%;
  font-size: 12px;
}

.gray {
  color: #9c9fc0;
}

.ispending {
  color: #9c9fc0;
  font-size: 12px;
}

.cancel {
  color: rgba(255, 0, 92, 1);
  font-size: 12px;
}

.finish {
  color: rgba(51, 158, 0, 1);
  font-size: 12px;
}

.item {
  color: rgba(51, 158, 0, 0.5);
}

.activeorders {
  height: 60%;
  flex-grow: 1;
}

.order-container {
  background: #0f1640;
  border-radius: 16px;
  //box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
  // max-width: 384px;
  min-height: 339px;
  max-height: 339px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  box-sizing: border-box;
  padding: 16px;
}

.orders {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 8px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.order {
  min-height: 90px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #0077cc49;
}

.order-targetmarket {
  font-family: "Yekan-en" !important;
}

.order-situation-type,
.order-situation-store,
.time,
.coin-type,
.coin-situation,
.order-time-store {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 3px;
}

button {
  padding: 0px;
  background: none;
  color: #0077cc;
  opacity: 0.5;
  font-size: clamp(10px, 1.5vw, 14px);
}

.enable {
  opacity: 1;
  cursor: pointer;
}

@media only screen and (max-width: 1150px) {
  .order-container {
    max-width: none;
  }
}

@media only screen and (max-width: 450px) {
  .details-table {
    display: none;
  }
  .remove {
    display: none;
  }
  .order-time-store,
  .order-situation-type,
  .order-situation-store {
    flex-direction: column;

    div,
    .order-targetmarket {
      justify-content: center;
      text-align: center;
    }
  }
}
</style>
