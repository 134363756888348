<template>
  <div class="btc">
    <div class="btns">
      <button
          class="diposit"
          @click.prevent="activeBtn"
          :class="{ active: diposit }"
      >
        برداشت
      </button>
      <button
          class="withdrawl"
          @click.prevent="activeBtn"
          :class="{ active: withdrawl }"
      >
        واریز
      </button>
    </div>
    <table class="btc-table" v-if="withdrawl">
      <thead>
      <tr>
        <th class="th">مقدار درخواستی</th>
        <th class="th tablet">شماره شبا درخواستی</th>
        <th class="th tablet">شماره پیگیری</th>
        <th class="th">زمان</th>
        <th class="th phone">وضعیت</th>
      </tr>
      </thead>
      <tbody>
      <tr class="table-row" v-for="(item, index) in depositList" :key="index">
        <td class="text">{{ !item.amount ? "یافت نشد" : $toLocal(item.amount, 2) }}</td>
        <td class="text tablet">{{ !item.bankAccount ? 'یافت نشد' : item.bankAccount }}</td>
        <td class="text tablet">{{ item.id }}</td>
        <td class="text">{{ $G2J(item.createdAtDateTime) }}</td>
        <td class="phone">
          <img v-if="item.isDone ===true" src="@/assets/profile/icons/tick.svg" alt="">
          <img v-if="item.isDone ===false" src="@/assets/profile/icons/xmark.svg" alt="">
        </td>
      </tr>
      </tbody>
    </table>
    <table class="btc-table" v-if="diposit">
      <thead>
      <tr>
        <th class="th">مقدار واریزی</th>
        <th class="th tablet">شماره شبا واریزی</th>
        <th class="th tablet">شماره پیگیری</th>
        <th class="th">زمان</th>
        <th class="th phone">وضعیت</th>
      </tr>
      </thead>
      <tbody>
      <tr class="table-row" v-for="(item, index) in withdraList" :key="index">
        <td class="text">{{ !item.amount ? "یافت نشد" : $toLocal(item.amount, 2) }}</td>
        <td class="text tablet">{{ !item.bankAccount ? 'یافت نشد' : item.bankAccount }}</td>
        <td class="text tablet">{{ item.id }}</td>
        <td class="text">{{ $G2J(item.createdAtDateTime) }}</td>
        <td class="phone">
          <img src="@/assets/profile/icons/tick.svg" alt="">

        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>


export default {
  name: "TransActionRial",

  data() {
    return {
      diposit: true,
      withdrawl: false,
      //diposit table
      wage: 0,
      withdraList: [],
      depositList: [],
      loading: false,
      setting: {
        suppressScrollX: false,
        swipeEasing: false,
        wheelPropagation: false,
      },
      label: {
        with: 'برداشت',
        dep: 'واریز'
      },
      lastPage: 1,
      page: 0,
      page2: 0,
      lastPage2: 1,
      modalshow: false,
      completedModal: false,
      removeid: '',
    };
  },
  methods: {
    activeBtn() {
      if (this.diposit) {
        this.withdrawl = true;
        this.diposit = false;
      } else {
        this.diposit = true;
        this.withdrawl = false;
      }
    },
    async getWithdrawHistory() {
      const address = 'wallets/withdrawal-requests'
      if (this.page <= this.lastPage) { //for scroll loading
        this.page++ //for scroll loading
        this.loading = true
        const [res,] = await this.$http.get(address, {
          params: {
            size: 10,
            relatedCoin:'TOMAN',
            page: this.page
          }
        })
        if (res) {
          this.withdraList = [...this.withdraList, ...res.content]
          this.lastPage = res.totalPages
        }
      }

    },
    async getDepositHistory() {

      const address = 'wallets/deposit'
      if (this.page2 <= this.lastPage2) { //for scroll loading
        this.page2++ //for scroll loading
        this.loading = true
        const [res,] = await this.$http.get(address, {
          params: {
            size: 10,
            relatedCoin:'TOMAN',
            page2: this.page2
          }
        })
        if (res) {
          console.log(res)
          this.depositList = [...this.depositList, ...res.content]
          // this.depositList = this.depositList.filter(a=>a.relatedCoin === 'TOMAN')
          this.lastPage = res.totalPages
        }

      }
    },
  },
  mounted() {
    this.getDepositHistory()
    this.getWithdrawHistory()
  }
};
</script>

<style lang="scss" scoped>

.table-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.table-container::-webkit-scrollbar {
  display: none;
}

.btc {
  min-height: 564px !important;
  max-height: 564px !important;
}

.btc {
  background: #0f1640;
  border-radius: 16px;
  max-height: 85%;
  padding: 24px;

  .btns {
    display: flex;
    width: 100%;
    max-width: 280px;
    background: rgba(156, 152, 192, 0.1);
    min-height: 36px;
    border-radius: 8px;

    .diposit,
    .withdrawl {
      width: 100%;
      border-radius: 8px;
      background: transparent;
      color: white;
    }

    .active {
      background: #279bc0;
      border-radius: 8px;
    }
  }

  .btc-table {
    tbody {
      display: block;
      height: 100%;
      max-height: 450px;
      overflow: scroll;

      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */


    }

    tbody::-webkit-scrollbar {
      display: none;
    }

    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed; /* even columns width , fix width of table too*/
    }

    thead {
      width: calc(100% - 1em) /* scrollbar is average 1em/16px width, remove it from thead width */
    }

    table {
      width: 400px;
      height: 524px;
    }

    width: 100%;
    padding: 20px;

    .th {
      color: #febc00;
      font-size: clamp(12px, 1vw, 16px);
    }

    .table-row {
      text-align: center;
      width: 100%;
      height: 50px;

      .text {
        font-size: clamp(12px, 1vw, 16px);
      }

      .tablet {
      }

      .phone {
      }

      .small-pc {
      }
    }
  }


}

@media screen and (max-width: 980px) {
  .tablet {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .phone {
    display: none;
  }
  .btns {
    width: 90%;
    min-width: 80px;
    max-width: 100px;
  }
}
</style>
