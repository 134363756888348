<template id="dummy">
  <div class="marquee">
    <div class="prices">
      <div class="price" v-for="(coin,index) in coins.filter(c=> c.relatedCoin !=='TETHER')" :key="index">

        <div class="selling">
          <img :src="'/coins/'+ $coin2Snake[coin.relatedCoin] + '.png'" :alt=" coin.relatedCoin" width="20" height="20">
          <p>فروش</p>
          <p class="text">{{ $coinLabel[coin.relatedCoin] }}: </p>
          <p>
            {{ coin.marketType.includes('TETHER') ? $toLocal(coin.sellPrice, $decimal["TETHER"]) : $toLocal(coin.sellPrice, $decimal["TOMAN"]) }}</p>
          <p v-if="coin.marketType.includes('TETHER')">(تتر)</p>
          <p v-if="coin.marketType.includes('TOMAN')">(تومان)</p>
        </div>

        <div class="buying">
          <img :src="'/coins/'+ $coin2Snake[coin.relatedCoin] + '.png'" :alt=" coin.relatedCoin" width="20" height="20">
          <p>خرید</p>
          <p class="text">{{ $coinLabel[coin.relatedCoin] }}: </p>
          <p>
            {{ coin.marketType.includes('TETHER') ? $toLocal(coin.buyPrice, $decimal["TETHER"]) : $toLocal(coin.buyPrice, $decimal["TOMAN"]) }}</p>
          <p v-if="coin.marketType.includes('TETHER')">(تتر)</p>
          <p v-if="coin.marketType.includes('TOMAN')">(تومان)</p>

        </div>


      </div>
    </div>


  </div>
</template>

<script>
import {Loop} from "@/library/reuseableFunction";

export default {
  name: "dummy",
  components: {},
  data() {
    return {
      coins: [],
      interval:5000,
      TomanCoin: 'market_type=BITCOIN_TOMAN' +
          '&market_type=BITCOIN_CASH_TOMAN' +
          '&market_type=ETHEREUM_TOMAN' +
          '&market_type=LITE_COIN_TOMAN' +
          '&market_type=BINANCE_COIN_BSC_TOMAN' +
          '&market_type=STELLAR_TOMAN' +
          '&market_type=TRON_TOMAN' +
          '&market_type=CELO_TOMAN' +
          '&market_type=RIPPLE_TOMAN' +
          '&market_type=DOGE_COIN_TOMAN' +
          '&market_type=MATIC_TOMAN'
      ,
    };
  },
  methods: {

    async getData() {
      const res = await this.$axios.get(`/trades/market-info?${this.TomanCoin}`,
          {
            params: {
              weeklyDataIncluded: false,
            }
          })
      //console.log('marquee',res.content)
      this.coins = res.content


//console.log(res)


    },
    beforeLoop() {
 const loop = new Loop(this.getData, this.interval,'marquee');
      loop.start();

    }
  },
  mounted() {
    this.getData()
    this.beforeLoop()
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>

<style lang="scss" scoped>


.text {

  display: flex;
  gap: 5px !important;
}


.image {


}

.marquee {

  //max-height: 26px;
  background: #0f1640 !important;
  width: 100%;
  //overflow: scroll;
  //text-space: nowrap;
  display: flex;
}


.prices {


  //animation: marquee 20s  infinite;
  overflow-x: hidden;
  display: flex;
  //justify-content: space-around;
  //text-space: nowrap;
}


.price {

  height: 40px;
  animation: marquee 80s linear infinite;
  overflow: hidden;
  //text-space: nowrap;
  max-height: 40px;
  display: flex;
  min-width: 30%;
  gap: 10px;

  img {

    align-self: center;
  }

}

.buying, .selling {

  align-self: center;
  flex: 1;
  //margin: 0 40px;
  row-gap: 10px;
  column-gap: 5px;
  // min-width: 210px;
  display: flex;

  p {
    font-size: clamp(14px, 1vw, 15px);
  }
}

@keyframes marquee{
  0% {
    transform: translateX(600%)
  }
  100% {
    transform: translateX(-300%)
  }
}
@-webkit-keyframes marquee {
  0% {
    transform: translateX(600%)
  }
  100% {
    transform: translateX(-300%)
  }
}


@media (max-width: 1650px) {
  .price {
    min-width: 50%;
  }
}

@media (max-width: 850px) {
  .price {
    min-width: 120%;
  }
}


</style>
