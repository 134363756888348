<template>
  <div class="main">
    <div class="view">
      <div class="market-view">

        <div class="market">
          <!-- <DashboardChartVue class="chart" /> -->
          <apexchart type="donut" width="250" height="280" :options="chartOptions" :series="series"
                     :key="series.length + 'P2P' + chartOptions.labels.length + change"/>
          <div class="market-container">
            <div class="market-table">
              <span class="red align-self-center" v-if="connection&&!wallets.length">کیف پول خالی است</span>
              <span class="red align-self-center" v-if="!connection" >در حال اتصال به کیف پول...</span>
              <template>
                <div class="row" v-for="(content, index) in wallets" :key="index"
                     @click="goto($coins[content[0]].coin)">
              <span>
                <img :src="$coins[content[0]].link" alt="" width="22" height="22"/>
                <p>{{ $coins[content[0]].persianName }}</p>
              </span>
                  <p class="amounts">
                    {{ $toLocal(credit(content), $decimal[content[0]]) }} <span> {{ $coins[content[0]].code }} </span>
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="access">
        <div class="invite">
          <p class="title">دعوت از دوستان</p>
          <img src="../../../assets/profile/inv-dashboard.png" alt=""/>
          <p class="invited">تعداد افراد معرفی شده:

            {{ $toLocal(statistics.countInvitees) || 0 }}

          </p>
          <p class="reward">مجموع سود کسب شده:


            {{ $toLocal(statistics.profit) || 0 }}

            تومان</p>
          <button
              class="submit"
              @click.prevent="$router.push('/profile/invite')"
          >
            اضافه کردن دوستان بیشتر
          </button>
        </div>
        <div class="account-status">
          <p class="title">وضعیت حساب کاربری</p>
          <img src="../../../assets/profile/level.png" alt="level"/>
          <p class="level"> {{ state.userInfo.email }} {{ $levels[state.userInfo.userAccountLevel] }} </p>
          <button v-if="!state.userInfo.authorized" class="submit" @click.prevent="$router.push({name:'Account'})">تکمیل حساب</button>
        </div>
      </div>
    </div>
    <div class="orders">
      <div class="active-orders">
        <activeorders/>
      </div>
      <div class="history-orders">
        <orderhistory/>
      </div>
    </div>
  </div>
</template>

<script>
// import WalletChart from "@/components/profile/WalletChart";
// import Crypto from "../../../library/crypto";
import activeorders from "@/components/profile/dashboard/Activeorders";
import orderhistory from "@/components/profile/dashboard/Orderhistory";
// import DashboardChartVue from "./DashboardChart.vue";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "DashboardLayout",
  components: {
    // WalletChart,
    activeorders,
    orderhistory,

    // DashboardChartVue,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      balance: null,
      statistics: '',
      max: null,

      open: '',
      contents: [],
      contentsExchange: [],
      chartData: '',
      chartDataExchange: '',
      series: [0],
      seriesExchange: [0],
      change: 0,
      wallets: {},
      walletsExchange: {},
      chartOptions: {
        chart: {
          width: '50px',
          fontFamily: 'iransansweb',
        },
        colors: [
          '#f9ce1d', '#FF9800', '#00E396', '#FF4560', '#775DD0', '#5C4742', '#2b908f', '#A300D6', '#C5D86D','#3f51b5', '#03a9f4', '#4caf50',

        ],
        theme: {
          mode: 'light',
          palette: 'palette2'
        },
        stroke: {
          width: 1,
          colors: undefined
        },
        labels: [],
        dataLabels: {
          style: {
            fontFamily: 'iransansweb'
          }
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            offsetX: 0,
            offsetY: 0,
            donut: {
              size: '80%',
              background: '#001E3D',
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '12px',
                  fontFamily: 'iransansweb',
                  fontWeight: 600,
                  color: '#F2F2F2',
                  offsetY: -17,
                  formatter: function (val) {
                    return val.name || val
                  }
                },
                value: {
                  show: true,
                  fontSize: '12px',
                  fontFamily: 'iransansweb',
                  fontWeight: 400,
                  color: '#F2F2F2',
                  offsetY: 10,
                  formatter: function (val) {
                    return parseFloat(val).toLocaleString()
                  }
                },
                total: {
                  show: true,
                  showAlways: false,
                  label: 'ارزش کل دارایی',
                  fontSize: '12px',
                  fontFamily: 'iransansweb',
                  fontWeight: 600,
                  color: '#F2F2F2',
                  formatter: function (w) {
                    w = w.globals.seriesTotals.reduce((a, b) => a + b)
                    return w.toLocaleString() + '\n' + 'تومان'
                  }
                }
              }
            },
          }
        },
        tooltip: {
          enabled: true,
          fillSeriesColor: true,
          custom: function ({
                              seriesIndex,
                              w
                            }) {
            return ` ${w.globals.seriesNames[seriesIndex].value.toLocaleString()} ${w.globals.seriesNames[seriesIndex].name} `
          }
        }
      },

    }
  },
  methods: {
    async getWalletData() {
       this.connection=false;
      const res = await this.$axios(`/wallets/customer-addresses?walletType=P2P`)
       this.connection =true;
      let entireRes = Object.entries(res)
      const sortCoins = this.$walletSortCoins
      entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
      this.contents = entireRes
      this.chartData = this.contents.map(e => e[0] === 'TOMAN' ? e[1].totalBalance : e[0].wholePriceOfCredit)
entireRes= entireRes.filter(item=> item[1].credit !=0)
      console.log('your res',entireRes)
      this.wallets = entireRes.sort((a,b)=> a[1].totalBalance < b[1].totalBalance)
    },

    chart() {
      let index = 0
      this.wallets.forEach((e) => {
        this.chartOptions.labels[index] = {
          'name': this.$coins[e[0]].persianName,
          'value': e[1].credit || 0
        }
        this.series[index] = e[1].wholePriceOfCredit || e[1].totalBalance || 0
        index++
      })
      ++this.change
    },
    routing(e) {
      if (this.$route.params.tradeTo !== e)
        this.$router.push('/dashboard/' + this.$route.params.tradeTo + '/' + e)
    },
    goto(e) {
      e==='TOMAN'?null:this.$router.push('/profile/trade/' + 'TOMAN' + `/${e}`)
    },

  },
  async mounted() {

    // window.addEventListener('online',()=>{this.state.loading=false})
    this.state.loading = true
    await this.getWalletData()
    this.chart()
    this.state.loading = false
    const res = await this.$axios.get('/users/invites')
    this.statistics = res.baseDTO
  },
  computed: {
    credit() {
      return e => this.$toLocal(e[0] === 'TOMAN' ? e[1].credit.toFixed(0) : e[1].credit) || 0
    }
  }
};
</script>

<style lang="scss" scoped>
.red{
position: absolute;
  color: red;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main {
  margin-top: 5px;
}

.market-view {
  background-color: #0f1640;
  border-radius: 16px;
  padding: 13px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.market {

  display: flex;
  flex-wrap: wrap;


  // align-items: center;
  justify-content: space-around;
  max-height: 345px;

  .chart {
    width: 30% !important;
  }

  .market-container {
    width: 41%;
    height: 84%;

    .market-table {
      display: flex;
      position: relative;
      height: 100%;
      max-height: 250px;
      width: 100%;
      padding: 10px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      //max-height: 280px;
      display: flex;
      flex-direction: column;
      background: #9c9fc013;
      border-radius: 16px;

      .row {
        padding: 12px;
        display: flex;
        justify-content: space-between;
        gap: 21px;
        width: 100%;
        cursor: pointer;

        span {
          display: flex;
          column-gap: 8px;
          align-items: center;
        }
      }
    }
  }
}


.btns {
  display: flex;
  width: 100%;
  align-self: center;
  max-width: 280px;
  background: rgba(156, 152, 192, 0.1);
  min-height: 36px;
  border-radius: 8px;
  align-self: center;

  .diposit,
  .withdrawl {
    width: 100%;
    border-radius: 8px;
    background: transparent;
    color: white;
  }

  .active {
    background: #279bc0;
    border-radius: 8px;
  }
}

//amounts of coins
.amounts {
  display: flex;
  gap: 10px;
}

//main page layout
.main {
  display: flex;
  gap: 8px;
  justify-content: center;

  flex-wrap: wrap;

  .view {
    // max-width: 829px;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    gap: 8px;
    //first column

    .access {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      //first column of access
      .invite {
        padding: 27px 0px;
        max-height: 341px;
        gap: 20px;
        align-items: center;
        justify-content: center;
        width: 49.5%;
        display: flex;
        flex-direction: column;
        background: #0f1640;
        border-radius: 16px;

        .title {
          font-size: clamp(14px, 1vw, 16px);
          font-weight: 500;
        }

        .invited {
          color: #9c9fc0;
        }

        .reward {
          font-weight: 500;
        }
      }

      //second column of access

      .account-status {
        min-height: 341px;
        justify-content: center;
        align-items: center;
        width: 49.5%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        background: #0f1640;
        border-radius: 16px;
        gap: 20px;

        .title {
          font-size: clamp(14px, 1vw, 16px);
          font-weight: 500;
        }

        .level {
          color: #9c9fc0;
        }
      }
    }
  }

  .orders {
    display: flex;
    flex-direction: column;
    row-gap: 8px !important;
    max-width: 411px;
    flex-grow: 1;
  }
}

.submit {
  width: 90%;
  min-height: 36px;
  background-color: #19196f;
  border-radius: 8px;
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 650px) {
  .market {
    display: block !important;
    max-height: unset !important;
  }

  .market-container,
  .chart {
    width: 90% !important;
    margin: 0 auto;
  }
  .market-container {
    max-height: 300px !important;
  }
}

@media screen and (max-width: 1416px) {
  .invite {
    width: 100% !important;
  }
  .account-status {
    width: 100% !important;
  }
}
</style>
