<template>
  <div class="">
    <div class="about-us">
      <div class="about">
        <div class="card">
          <h2>درباره تاپ ننس</h2>
          <p>
            تاپ ننس با بهره گیری از توان، تخصص و تجربه گروهی از متخصصان اقتصادی
            و مهندسان مالی در سال 1399 فعالیت خود را در حوزه ارزهای دیجیتال شروع
            کرده و در ادامه با تشکیل تیم های خلاق و علاقمند از جوانان متفکر و
            ایده پرداز، با هدف مشتری مداری، حفظ اسرار، مراقبت و محافظت از سرمایه
            کاربران، بستری امن، سریع و آسان را برای کاربران ایرانی به منظور
            انجام معاملات ارزهای دیجیتال فراهم نموده است. در این بستر ضمن بهره
            گیری از فن آوری های نوین و به روز دنیا و ارتقای مستمر امکانات و
            تجهیزات، امکان خرید و فروش سریع انواع ارزهای دیجیتال مطرح دنیا در
            بازارهای جهانی وجود داشته و به مرور و بر اساس اعلام نیاز کاربران
            محترم، سایر ارزهای پر طرفدار را نیز به لیست ارزهای قابل خرید و فروش
            اضافه می نماید.
          </p>
        </div>
        <div class="icon">
          <InlineSvg
            :src="require('../../assets/public/aboutus.svg')"
          ></InlineSvg>
        </div>
      </div>
      <div class="analysis">
        <CircularStat :amount="userscount" title="تعداد کاربران" />
        <CircularStat
          :amount="$toLocal(tradprice, 0)"
          title="ارزش معاملات انجام شده"
        />
        <CircularStat :amount="totaltrad" title="تعداد معاملات" />
      </div>
      <div class="about-team">
        <h2>ویژگی های ما</h2>
        <p>
          امکان انجام معامله سریع و راحت با مناسب ترین قیمتها در این پلتفرم برای
          کلیه خریداران و فروشندگان وجود داشته و کاربران می توانند پس از ثبت نام
          و تکمیل اطلاعات فردی، با اطمینان از امنیت فراهم شده در این بستر، نسبت
          به واریز وجه و خرید انواع ارزهای دیجیتال اقدام نمایند. کاربران تاپ ننس
          می توانند تمامی رمزارزهای خریداری شده را در حساب کاربری شخصی و در کیف
          پول خود نگه داشته و هر زمان از 24 ساعت که بخواهند، حتی در روزهای
          تعطیل، نسبت به فروش آن و یا تبدیل به سایر رمزارزها اقدام نمایند.
          همچنین کاربران می توانند بدون پرداخت کارمزد، هر میزان ارز را از کیف
          پول خود به کیف پول سایر کاربران در تاپ ننس منتقل کرده یا با پرداخت
          کارمزدی بسیار اندک و با توجه به محدودیت های شبکه، نسبت به انتقال ارز
          خود به سایر کیف پولها اقدام نمایند. بستر امن تاپ ننس ضمن فراهم نمودن
          امکان معاملات آنلاین، قابلیت ثبت سفارشات خرید و فروش حرفه ای با مشخص
          نمودن حد ضرر، حد سود، مدت زمان سفارش، تعداد و قیمتهای مورد نظر کاربر
          را داشته و این امکان را فراهم می نماید تا کاربران ضمن توجه به محدودیت
          های شبکه، در هر زمان که لازم بدانند نسبت به ثبت یا لغو سفارش خود اقدام
          نمایند. پس از ثبت سفارشات حرفه ای، رصد لحظه ای بازار توسط تاپ ننس شکل
          گرفته و با رسیدن قیمت بازار به مبلغ مورد نظر کاربر، معامله به صورت
          اتوماتیک انجام شده و حتی در صورت آنلاین نبودن کاربر، نتایج سفارش مذکور
          به کیف پول کاربر منتقل می گردد. در تاپ ننس و در بخش سوالات متداول سعی
          شده است تا به کلیه پرسشها و ابهامات احتمالی کاربران به صورت ساده و
          روان پاسخ داده شده تا کاربران بتوانند ضمن مطالعه آنها، با دریافت
          اطلاعات کافی نسبت به ثبت نام و شروع فعالیت خود در تاپ ننس اقدام
          نمایند. در این زمینه تیم پشتیبانی تاپ ننس نیز همواره آماده ارائه خدمات
          پشتیبانی به کاربران محترم می‌باشند.
        </p>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import Footer from "../sharing-components/footer";
import CircularStat from "../tools/CircularStat.vue";

export default {
  name: "AboutUs",

  metaInfo:{
    title:'بازارها',
    links:[{rel:'canonical', href:'https://topnance.com/about-us'}],
    meta:[{property:'og:title', content:'درباره ی تاپننس'},
      {name:'description',content:'تاپ ننس با بهره گیری از توان، تخصص و تجربه گروهی از متخصصان اقتصادی و مهندسان مالی در سال 1399 فعالیت خود را در حوزه ارزهای دیجیتال شروع کرده و در ادامه با تشکیل تیم های خلاق و علاقمند از جوانان متفکر و ایده پرداز، با هدف مشتری مداری، حفظ اسرار، مراقبت و محافظت از سرمایه کاربران، بستری امن، سریع و آسان را برای کاربران ایرانی به منظور انجام معاملات ارزهای دیجیتال فراهم نموده است. در این بستر ضمن بهره گیری از فن آوری های نوین و به روز دنیا و ارتقای مستمر امکانات و تجهیزات، امکان خرید و فروش سریع انواع ارزهای دیجیتال مطرح دنیا در بازارهای جهانی وجود داشته و به مرور و بر اساس اعلام نیاز کاربران محترم، سایر ارزهای پر طرفدار را نیز به لیست ارزهای قابل خرید و فروش اضافه می نماید.'},
      {property:'og:title', content:'درباره ی تاپننس'},
      {name:'twitter:card', content:'summary'},
      {name:'twitter:description', content:'تاپ ننس با بهره گیری از توان، تخصص و تجربه گروهی از متخصصان اقتصادی و مهندسان مالی در سال 1399 فعالیت خود را در حوزه ارزهای دیجیتال شروع کرده و در ادامه با تشکیل تیم های خلاق و علاقمند از جوانان متفکر و ایده پرداز، با هدف مشتری مداری، حفظ اسرار، مراقبت و محافظت از سرمایه کاربران، بستری امن، سریع و آسان را برای کاربران ایرانی به منظور انجام معاملات ارزهای دیجیتال فراهم نموده است. در این بستر ضمن بهره گیری از فن آوری های نوین و به روز دنیا و ارتقای مستمر امکانات و تجهیزات، امکان خرید و فروش سریع انواع ارزهای دیجیتال مطرح دنیا در بازارهای جهانی وجود داشته و به مرور و بر اساس اعلام نیاز کاربران محترم، سایر ارزهای پر طرفدار را نیز به لیست ارزهای قابل خرید و فروش اضافه می نماید.'},
      {name:'twitter:title', content:'summary'},
      {name:'twitter:image', content:'https://topnance.com/logo.png'},
      {property:'og:locale', content:'fa_IR'},
      {property:'og:type', content:'website'},
      {property:'og:title', content:'درباره ی تاپننس'},
      {property:'og:url', content:'https://topnance.com/about-us'},
    ]
  },
  data() {
    return {
      userscount: "0",
      tradprice: "0",
      totaltrad: "0",
    };
  },
  components: {
    InlineSvg,
    Footer,
    CircularStat,
  },
  methods: {
    async getInfo() {
      const res = await this.$axios("trades/market-info-home");
      this.userscount = res.baseDTO.userCount;
      this.tradprice = res.baseDTO.tradesValue;
      this.totaltrad = res.baseDTO.tradesAmount;
    },
  },
  mounted() {
    this.getInfo();
  },
};
</script>

<style lang="scss" scoped>
.about-us {
  min-height: 65vh;
  background: #0a1128;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .about {
    margin: 100px 120px;
    row-gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .card {
      max-width: 595px;
      padding: 50px 86px;
      background: #141b31;
      border-radius: 16px;

      > h2 {
        font-size: clamp(20px, 1.7vw, 40px);
        color: #febc00;
      }

      > p {
        color: white;
        font-weight: 500;
        font-size: clamp(12px, 2vw, 18px);
      }
    }

    .icon {
    }
  }

  .analysis {
    gap: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-width: 1440px;
    margin: 100px auto;
    flex-wrap: wrap;
  }

  .about-team {
    margin: 100px 0px;
    background: #141b31;
    border-radius: 16px;

    > h2 {
      padding: 0px 120px;
      color: #febc00;
      font-weight: 500;
      font-size: clamp(20px, 1.7vw, 40px);
    }

    > p {
      padding: 60px 120px;
      font-weight: 500;
      font-size: clamp(14px, 1vw, 18px);
      color: white;
    }
  }
}

@media screen and (max-width: 1300px) {
  .about-us {
    justify-content: center;
    align-items: center;

    .about {
      width: 100%;
      margin: 5px;
      justify-content: center;
      align-items: center;
      padding: 20px;

      .card {
        padding: 5px;
      }

      .icon {
        transform: scale(0.5);
      }
    }

    .analysis {
      margin: 20px auto;
      justify-content: center;
    }

    .about-team {
      width: 90%;
      padding: 5px;

      > h2 {
        padding: 5px;
      }

      > p {
        padding: 5px;
      }
    }
  }
}

@media (max-width: 500px) {
  .about-team {
    margin: 10px 0 !important;
  }
  .icon {
    display: none;
  }
  .analysis {
    .stat {
      min-width: unset;
      min-height: unset;
      width: 150px;
      height: 150px;
    }
  }
}
</style>
