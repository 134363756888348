<template>
  <div class="trade-section">
    <div class="last-trades">
      <last-trades/>
    </div>
    <div class="trade-menu">
      <trade-menu :key="state.orderChange"/>
    </div>
  </div>
</template>

<script>
import LastTrades from "@/components/profile/trade/LastTrades";
import TradeMenu from "@/components/profile/trade/shop/shopMenu";

export default {
  name: "TradeSection",
  components: {TradeMenu, LastTrades}
}
</script>

<style lang="scss" scoped>
.trade-section {
  display: flex;

  gap: 8px;

  .last-trades {
    max-width: 250px;
    border-radius: 8px;
    flex-grow: 1;
    background: var(--blue-bg);
  }

  .trade-menu {
    flex-grow: 4;
    min-height: 404px;
  }
}

@media (max-width: 870px) {
  .trade-section {
    flex-direction: column;
  }
  .last-trades {
    width: 100%;
    max-width: none !important;
  }
}

@media (max-width: 870px) {
  trade-section {
    width: 100%;
  }
  .last-trades {
    order: 2;
  }
}
</style>