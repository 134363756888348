<template>
  <div class="form">
    <h1>{{ type }}</h1>
    <p>{{ title }}</p>
    <slot class="form"></slot>
    <button id="btn" @click.prevent="$router.push(target)">تایید</button>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    type: String,

    showingPass: {
      type: Boolean,
      default: false,
    },
    inputType: String,
    inputPlaceholder: String,
    target: String,
  },
  components: {},
};
</script>

<style lang="scss">

#btn {
  color: white;
  border: 0;
  margin-top: 20px;
  min-height: 36px;
  width: 100%;
  background-color: #19196f;
  border-radius: 4px;
}

.form {
  min-width: 486px;
  width: 486px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 104px 75px;
  margin: 20px;
  background: #141b31;
  border-radius: 16px;
  row-gap: 20px;
  column-gap: 75px;
  justify-content: center;

  > h1 {
    color: white;
    text-align: center;
    font-size: clamp(24px, 1vw, 16px);
  }

  > p {
    width: 100%;
    color: white;
    font-size: clamp(16px, 1vw, 12px);
  }

}


@media screen and (max-width: 1420px) {


  .form {


  }
}


@media screen and (max-width: 500px) {

  .form {
    width: 95%;

    min-width: 60%;
    padding: 5%;

  }
}

</style>
