<template>
  <div class="auth container">
    <div class="forms">
      <login v-if="$route.path === '/login'"/>
      <sign-up v-if="$route.path === '/sign-up'"/>
      <send-phone v-if="$route.path === '/login/send-phone'"/>
      <send-mail v-if="$route.path === '/login/send-mail'"/>
      <email-verification
          v-if="$route.path === '/sign-up/email-verification'"
      />
      <forget v-if="$route.path==='/login/forget'"/>
      <forget-otp v-if="$route.path === '/login/forget-otp'"/>
      <new-password v-if="$route.path === '/login/forget/verify'"/>
    </div>
    <div class="icon">
      <InlineSvg
          v-if="$route.path === '/login'"
          :src="require('../../assets/login-signup/login.svg')"
      ></InlineSvg>
      <InlineSvg
          v-if="$route.path === '/sign-up'"
          :src="require('../../assets/login-signup/signup.svg')"
      ></InlineSvg>
      <InlineSvg
          v-if="
          $route.path === '/login/send-phone' ||
          $route.path === '/login/send-mail' ||
          $route.path === '/sign-up/email-verification'
        "
          :src="require('../../assets/login-signup/emailcode.svg')"
      ></InlineSvg>
      <InlineSvg
          v-if="
          $route.path === '/login/forget-otp' ||
          $route.path === '/login/forget' ||
          $route.path === '/login/forget/verify'
        "
          :src="require('../../assets/login-signup/forget.svg')"
      ></InlineSvg>
    </div>
  </div>
</template>

<script>
import Login from "@/components/login-signup/Login";
import SignUp from "@/components/login-signup/SignUp";
import InlineSvg from "vue-inline-svg";
import SendPhone from "@/components/login-signup/SendPhone";
import SendMail from "@/components/login-signup/OTP";
import EmailVerification from "@/components/login-signup/EmailVerification";
import ForgetOtp from "@/components/login-signup/ForgetOTP";
import NewPassword from "@/components/login-signup/NewPassword";
import Forget from "@/components/login-signup/Forget";

export default {
  name: "authenticationComponent",
  components: {
    Forget,
    NewPassword,
    ForgetOtp,
    EmailVerification,
    SendMail,
    SendPhone,
    InlineSvg,
    SignUp,
    Login,
  },
  data() {
    return {
      signUp: false,
      login: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.auth {
  height: calc(100vh - 60px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .forms {
    display: flex;
    justify-content: center;

    max-width: 486px;
  }

  .icon {
  }
}

@media screen and (max-width: 1290px) {
  .icon {
    width: 600px;

    > svg {
      width: 80%;
    }
  }
}

@media screen and (max-width: 1105px) {
  .auth {
    .forms {
      margin: 0 auto;
      max-height: 630px;
    }
  }

  .icon {
    display: none;
  }
}

@media (max-width: 500px) {
  .auth {
    display: block;
  }
  .form {
    margin-top: 50px;
  }
}
</style>
