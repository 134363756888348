<template>
  <perfect-scrollbar @ps-y-reach-end="getAdvancedHistory" ref="scroll">
    <div class="table-container">

      <table class="exchange-table" :key="p2pContents" v-if="filterCoins.length">
        <thead>
        <tr>
          <th class="th">نوع سفارش</th>
          <th class="th">رمز ارز</th>
          <th class="th tablet">قیمت واحد</th>
          <th class="th phone">مقدار</th>
          <th class="th phone">قیمت کل</th>
          <th class="th tablet">شناسه تراکنش</th>
          <th class="th tablet">زمان</th>
          <th class="th tablet">وضعیت</th>
        </tr>
        </thead>

        <tbody>
        <tr
            class="table-row"
            v-for="(item, index) in filterCoins"
            :key="index"
        >
          <td
              v-if="item.orderType.includes('BUY')"
              class="text"
              :class="{
                red: item.orderType.includes('SELL'),
                green: item.orderType.includes('BUY'),
              }"
          >
            خرید
          </td>
          <td
              v-if="item.orderType.includes('SELL')"
              class="text"
              :class="{
                red: item.orderType.includes('SELL'),
                green: item.orderType.includes('BUY'),
              }"
          >
            فروش
          </td>
          <td class="text" v-if="item.orderType.includes('BUY')">
            {{ $coins[item.destinationCoin].persianName }} -
            {{ $coins[item.sourceCoin].persianName }}
          </td>
          <td class="text" v-else>
            {{ $coins[item.sourceCoin].persianName }} -
            {{ $coins[item.destinationCoin].persianName }}
          </td>
          <td class="text tablet">{{ $toLocal(item.unitPrice, 2) }}</td>
          <td class="text phone">{{ item.amount }}</td>
          <td class="text phone">{{ $toLocal(item.wholePrice, 1) }}</td>
          <td class="text tablet">{{ item.id }}</td>
          <td class="text tablet">{{ $G2J(item.createdAtDateTime) }}</td>
          <td class="text tablet">
            <img src="@/assets/profile/icons/tick.svg" alt=""/>
          </td>
        </tr>
        </tbody>

      </table>
      <div class="text-center sell" v-else>تاریخچه ای یافت نشد</div>

    </div>
  </perfect-scrollbar>
</template>

<script>
export default {
  name:'AllTable',
  data() {
    return {
      p2pContents: [],
      page: 0,
      lastPage: 1,
    };
  },
  props: ["coin"],
  watch: {
    coin() {
      this.page = 0;
      this.getAdvancedHistory();
    },
  },
  computed: {
    filterCoins() {
      if(this.coin){
        let coins
        coins = this.p2pContents.filter(a => a.destinationCoin == this.coin)
        console.log(coins)
        return coins
      }
      else{
        return this.p2pContents;
      }
    }
  },
  methods: {
    // this function is for get advanced trade history
    async getAdvancedHistory() {
      this.state.loading = true;
      if (this.page <= this.lastPage && !this.loading) {
        //for scroll loading
        this.loading = true;
        this.page++; //for scroll loading

        if(!this.coin){
          const [res] = await this.$http.get("/trades", {
            params: {
              size: 10,
              page: this.page,

              // destinationCoin: this.coin,
            },

          });

          this.page!=1?this.p2pContents = [...this.p2pContents, ...res.content]:this.p2pContents=res.content;
          // if (res) {
          //   this.lastPage = res.totalPages;
          //   this.p2pContents = [...this.p2pContents, ...res.content]; //for scroll loading
          //   // this.tableContents = res.content //for pagination
          // }
        }
        if(this.coin){
          const [res] = await this.$http.get("/trades", {
            params: {
              size: 10,
              page: this.page,
              destinationCoin: this.coin,
            },
          });
          if (res) {
            this.lastPage = res.totalPages;
            this.page!=1?this.p2pContents = [...this.p2pContents, ...res.content]:this.p2pContents=res.content; //for scroll loading
            // this.tableContents = res.content //for pagination
          }
        }

        // if (res) {
        //   this.lastPage = res.totalPages;
        //   this.p2pContents = [...this.p2pContents, ...res.content]; //for scroll loading
        //   // this.tableContents = res.content //for pagination
        // }
        this.loading = false;
      } //for scroll loading
      this.state.loading = false;
      // console.table(this.p2pContents)
    },
  },

  mounted() {
    this.getAdvancedHistory();
  },
};
</script>

<style lang="scss" scoped>
.trade {
  height: 564px;
  max-height: 564px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  background: #0f1640;
  border-radius: 16px;
  padding: 24px;
}

.dropdowns {
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;
  width: 100%;

  justify-content: space-between;

  .trades {
    position: relative;

    button {
      padding: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #279bc0;
      border-radius: 4px;
      min-height: 36px;
      min-width: 174px;
    }

    .list {
      //   overflow: scroll ;
      max-height: 200px;
      position: absolute;
      margin-top: 2px;
      background: rgb(10, 17, 40);
      padding: 4px;
      border-radius: 4px;
      cursor: pointer;
      width: 100%;
    }
  }

  .crypto {
    position: relative;

    button {
      padding: 6px;
      justify-content: space-between;
      display: flex;
      align-items: center;
      column-gap: 26px;
      border: 1px solid #279bc0;
      border-radius: 4px;
      min-height: 36px;
      min-width: 140px;

      span {
        display: flex;
        column-gap: 4px;
        align-items: center;
      }
    }

    .crypto-list {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 2px;
      background: rgb(10, 17, 40);
      padding: 4px;
      border-radius: 4px;
      cursor: pointer;
      width: 100%;
      //   overflow: scroll ;
      max-height: 150px;

      .list-items {
        padding: 5px;

        span {
          display: flex;

          img {
            align-self: center;
          }
        }

        > p {
          font-size: clamp(12px, 1vw, 16px);
          display: flex;
          align-items: center;
          min-height: 20px;
          gap: 8px;
        }
      }
    }
  }
}

.p2p-table,
.exchange-table {
  width: 100%;

  tbody {
    display: block;
    height: 100%;
    max-height: 350px;
    // overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  tbody::-webkit-scrollbar {
    display: none;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
  }

  thead {
    width: calc(
        100% - 1em
    ); /* scrollbar is average 1em/16px width, remove it from thead width */
  }

  table {
    width: 400px;
    height: 524px;
  }

  .th {
    color: #febc00;
    font-size: clamp(12px, 1vw, 16px);
    min-width: 50px;
  }

  .table-row {
    text-align: center;
    padding: 20px;

    .text {
      font-size: clamp(12px, 1vw, 16px);
    }

    .tablet {
    }

    .phone {
    }
  }
}

.p2p-table {
  .table-row {
    .green {
      color: #26c22c;
    }

    .red {
      color: #e53756;
    }
  }
}

.exchange-table {
  .table-row {
    .green {
      color: #26c22c;
    }

    .red {
      color: #e53756;
    }
  }
}

@media screen and (max-width: 1100px) {
  .tablet {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .phone {
    display: none;
  }
}
</style>
