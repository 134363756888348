<template>
  <div class="tables">
    <CancelOrder v-if='cancelModal==true' @close="cancelModal=false" @remove="removeorder"/>
    <table class="btc-table">

      <tr class="tr-head">
        <td class="th">نوع سفارشات</td>
        <td class="th tablet">رمز ارز</td>
        <td class="th small-pc">
          قیمت واحد
          <span>(تومان)</span>
        </td>
        <td class="th small-pc">مقدار</td>
        <td class="th tablet">قیمت کل <span>(تومان)</span></td>
        <td class="th tablet">کارمزد</td>
        <td class="th">زمان</td>
        <td class="th tablet">انجام شده</td>
      </tr>

      <tbody>
      <tr
          class="table-row"
          v-for="(tableContent , index) in tableContents" :key="index"

      >
        <td
            class="text negetive"
            :class="{ positive: tableContent.orderType.includes('BUY') , negative:tableContent.orderType.includes('SELL')}"
        >
                           <span v-if="tableContent.orderType.includes('SELL')">
                    فروش
                </span>
          <span v-if="tableContent.orderType.includes('BUY')">
                    خرید
                </span>
        </td>
        <td class="text tablet">

          {{ $coins[tradeFrom].persianName }} -
          {{ $coins[tradeTo].persianName }}

        </td>
        <td class="text small-pc"> {{ $toLocal(tableContent.unitPrice, precision) }}</td>
        <td class="text small-pc"> {{ $toLocal(tableContent.amount) }}</td>
        <td class="text tablet">{{ $toLocal(tableContent.overallValue, precision) }}</td>
        <td class="text tablet">{{ state.userInfo.fee }}</td>
        <td class="text"> {{ $G2J(tableContent.createdAtDateTime) }}</td>
        <td class="tablet delete-order">
          % {{ tableContent.executedPercent.toFixed(1) }}
  <div class=" delete-order d-flex flex-column">

    <Tooltip
        :elementId="index"
        :mode="'hover'"
        :classes="'tooltip-delete-order'"
    >
      <template v-slot:content>
        <p>لغو سفارش</p>
      </template>
    </Tooltip>
    <img :id="index" class="delete-image" src="@/assets/public/bin.svg" alt="" @click="showModal(tableContent)" width="30" height="30">

<!--    <small class="cancel">لغو سفارش</small>-->
  </div>
        </td>
      </tr>
      </tbody>
    </table>
    <div v-if="!tableContents ">
      <p style="width:100%;text-align:center;color:red">در حال حاضر اطلاعاتی وجود ندارد</p>
    </div>
  </div>
</template>

<script>
import {
  tradeFrom,
  tradeTo,
  precision,
  Loop
} from "@/library/reuseableFunction";
import CancelOrder from './CancelOrder.vue'
import Tooltip from 'vue2-tooltip-simple'
export default {
  name: 'OpenOrders',
  components: {
    CancelOrder,
    Tooltip
  },
  props: ['base'],
  data() {
    return {
      cancelModal: false,
      modalshow: false,
      removeid: 0,
      tableContents: [],
      loading: false,
      completedModal: false,
      loop: '',

    }
  },
  computed: {
    precision,
    tradeFrom,
    tradeTo
  },

  methods: {
    closemodal() {
      this.modalshow = false
    },
    showModal(e) {
      this.loop.stop()
      this.cancelModal = true
      this.removeid = e.id

    },
    async removeorder() {
      //   this.state.loading = true
      this.cancelModal = false
      const [res,] = await this.$http.delete(`orders/${this.removeid}`)
      if (res) {
        ++this.state.orderChange
        this.modalshow = false
        this.completedModal = true
        this.$error('سفارش با موفقیت حذف شد', '', 'success')
        if (this.state.loop) {
          this.loop.start()
        } else {
          this.getActiveOrders()
        }
      }
    },
    async getActiveOrders() {
      // this.state.loading = true
      let res = await this.$axios.get('/orders', {
        params: {
          orderStatus: 'IS_OPEN',
          market: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
        }
      })

      this.tableContents = res.content;
      this.loading = false
    },
  },
  async mounted() {
    this.loading = true
    if (this.state.loop) {
      this.loop = new Loop(this.getActiveOrders, 5000, 'activeOrder')
      this.loop.start()
    } else {
      await this.getActiveOrders()
    }
  },
  beforeDestroy() {
    this.loop.stop()
  }

}
</script>

<style lang="scss" scoped>
.tooltip-delete-order{
  color: red;
  background: #0f1640;
  border-radius: 8px;
  padding: 8px;
}
.delete-order{
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 8px;
}
.cancel{
  position: absolute;
  top: 13px;

}
.delete-image{
  margin: 0 4px;
  margin-bottom: 4px;
  width: 20px !important;
  height: 20px !important;
  image-rendering: pixelated;
  cursor: pointer;
}
.tr-head {
  position: sticky;
  top: 0;
  text-align: center;
  background: #0f1640;

}

.tables {
  position: relative;
  overflow: scroll;
  max-height: 200px;
}

.btc {
  background: #0f1640;
  border-radius: 16px;
  padding: 24px;

  .btns {
    display: flex;
    width: 100%;
    max-width: 280px;
    background: rgba(156, 152, 192, 0.1);
    min-height: 36px;
    border-radius: 8px;

    .diposit,
    .withdrawl {
      width: 100%;
      border-radius: 8px;
      background: transparent;
      color: white;
    }

    .active {
      background: #279bc0;
      border-radius: 8px;
    }
  }

  .table-container {
    height: 70%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

  }

  .table-container::-webkit-scrollbar {
    display: none;
  }

  .btc-table {
    width: 100%;
    padding: 20px 0;
    max-height: 524px;
    overflow: auto;

    table {
      max-height: 90%;
      overflow: auto;
    }

    .th {
      color: #febc00;
      font-size: clamp(12px, 1vw, 16px);
      overflow: auto;

      min-width: 50px;
    }

    .table-row {
      text-align: center;
      padding: 20px;
      height: 50px;

      .text {
        font-size: clamp(12px, 1vw, 16px);
      }

      .tablet {
      }

      .phone {
      }

      .small-pc {
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .small-pc {
    display: none;
  }
}

@media screen and (max-width: 980px) {
  .tablet {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .trading-history {
    max-height: 350px;
  }
  .btc {
    max-height: 350px;
  }
  .table-container {
    height: 90%;
  }
  .phone {
    display: none;
  }
  .btns {
    width: 90%;
    min-width: 80px;
    max-width: 100px;
  }
}
</style>