<template>
  <div class="verification">
    <CodeInput
        class="code-input"
        @change="onChange"
        @complete="submit"
        :fields="num"
        ref="code-input"
    />
    <Timer v-if="timer"/>
  </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import Timer from "./Timer.vue";

export default {
  components: {
    CodeInput,
    Timer,
  },
  props: {
    timer: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">

</style>
