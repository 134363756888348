<template>
  <dashboard-layout/>
</template>
<script>

import DashboardLayout from "@/components/profile/dashboard/DashboardLayout";

export default {
  name: "Dashboard",
  components: {DashboardLayout},
};
</script>


