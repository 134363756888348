<template>
  <transition name="modal-fade" v-if="closeIt">
    <div class="modal-backdrop" @click.self="closeModale">
      <div class="modal">
        <img
            @click="closeModale"
            class="close-icon"
            src="../../../assets/profile/closeIcon.svg"
            alt=""
        />
        <div class="message">
          <span class="message-title"> واریز به کیف پول تومان </span>
          <div class="warning">
            <div class="logo">
              <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_559_4792)">
                  <path
                      d="M11.8402 0C9.83061 0 7.86618 0.645139 6.1953 1.85383C4.52442 3.06253 3.22213 4.78049 2.45311 6.79048C1.68409 8.80047 1.48288 11.0122 1.87492 13.146C2.26697 15.2798 3.23466 17.2398 4.65562 18.7782C6.07659 20.3166 7.88701 21.3642 9.85795 21.7886C11.8289 22.2131 13.8718 21.9952 15.7284 21.1627C17.585 20.3301 19.1718 18.9202 20.2883 17.1113C21.4047 15.3023 22.0006 13.1756 22.0006 11C21.9977 8.08359 20.9263 5.28753 19.0215 3.22531C17.1166 1.16309 14.534 0.00315432 11.8402 0V0ZM11.8402 20.1667C10.1655 20.1667 8.52851 19.629 7.13611 18.6218C5.74371 17.6146 4.65847 16.1829 4.01762 14.5079C3.37677 12.8329 3.20909 10.9898 3.53579 9.21167C3.8625 7.43351 4.66891 5.80017 5.85305 4.51819C7.03718 3.23621 8.54587 2.36317 10.1883 2.00947C11.8308 1.65577 13.5332 1.8373 15.0804 2.5311C16.6275 3.22491 17.9499 4.39982 18.8803 5.90727C19.8106 7.41472 20.3072 9.187 20.3072 11C20.3047 13.4303 19.4119 15.7604 17.8245 17.4789C16.2372 19.1974 14.085 20.164 11.8402 20.1667Z"
                      fill="#E63756"
                  />
                  <path
                      d="M11.8408 4.5835C11.6163 4.5835 11.4009 4.68007 11.2421 4.85198C11.0833 5.02389 10.9941 5.25705 10.9941 5.50016V12.8335C10.9941 13.0766 11.0833 13.3098 11.2421 13.4817C11.4009 13.6536 11.6163 13.7502 11.8408 13.7502C12.0654 13.7502 12.2808 13.6536 12.4396 13.4817C12.5983 13.3098 12.6875 13.0766 12.6875 12.8335V5.50016C12.6875 5.25705 12.5983 5.02389 12.4396 4.85198C12.2808 4.68007 12.0654 4.5835 11.8408 4.5835Z"
                      fill="#E63756"
                  />
                  <path
                      d="M12.6875 16.5002C12.6875 15.9939 12.3085 15.5835 11.8408 15.5835C11.3732 15.5835 10.9941 15.9939 10.9941 16.5002C10.9941 17.0064 11.3732 17.4168 11.8408 17.4168C12.3085 17.4168 12.6875 17.0064 12.6875 16.5002Z"
                      fill="#E63756"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_559_4792">
                    <rect width="22" height="22" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div class="warning-text">
              <p>جهت افزایش اعتبار کیف پول تومان خود با استفاده از کارت</p>
              <p>‌های بانکی عضو شبکه شتاب و از طریق درگاه پرداخت، حتما باید</p>
              <p>
                از کارت‌های بانکی به نام خودتان که در پروفایلتان ثبت و تأیید
              </p>
              <p>شده است استفاده نمایید</p>
            </div>
          </div>
          <div class="amount">
            <input type="text"
                   class="normal-input"
                   @input="amount = $toLocal(amount)"
                   v-model="amount"
                   placeholder="میزان به تومان"
            >
            <label >
              موجودی <span class="price"> {{ $toLocal(cash, 1) }}</span> تومان
            </label>
          </div>
        </div>
        <div class="dropdown">
          <button
              class="btn"
              @click.prevent="openDropdown = !openDropdown"
              id="openDrop"
          >
            <span> {{ select }}</span>
            <InlineSvg
                :src="require('../../../assets/profile/icons/dropdown.svg')"
            />
          </button>
          <label for="openDrop">
            <a @click.prevent="$router.push('/profile/account')">+ افزودن حساب جدید</a>
          </label>
          <div class="list" v-if="openDropdown">
            <div
                v-for="(Bank,index) in Banks" :key="index"
                @click.prevent="selectItems(index)"
            >
              <p>{{ Bank.cardNumber }}</p>
            </div>
          </div>
        </div>

        <button v-if="!continu" class="submit" :disabled="disabled" hidden type="submit" @click="deposit">
          ثبت
        </button>
        <button v-if="continu" class="submit" @click="deposit">
          ثبت
        </button>
      </div>

    </div>
  </transition>
</template>

<script>
import InlineSvg from "vue-inline-svg";


export default {
  name: "ExitModal",
  components: {

    InlineSvg,

  },
  computed: {
    disabled() {
      return !this.$S2N(this.amount) ||
          // (this.$S2N(this.amount) > this.$S2N(this.cash)) ||
          (this.$S2N(this.amount) > this.$S2N(this.max)) ||
          (this.$S2N(this.amount) < this.$S2N(this.min)) ||
          !this.walletAddress
    },
    min() {
      return this.role.minWithdrawAmount
    },
    max() {
      return this.role.maxWithdrawAmount
    },
    danger() {
      return 'حداقل ' + this.$toLocal(this.min) + ' تومان' +
          '\n' + 'حداکثر ' + this.$toLocal(this.max) + ' تومان'
    }
  },
  data() {
    return {
      inventory: [],
      closeIt: true,
      continu: false,
      submit: false,
      success: false,
      select: "",
      coins: [],
      openDropdown: false,
      Banks: [],
      amount: 0,
      role: {},
      cash: 0,
      // amount: '',
      // Banks: [],
      walletAddress: '',
      walletInfo: {
        amount: 0,
        // cardNumber: this.Bank.cardNumber,
        paymentGateway: 'ZARRINPAL'
      }


    };
  },
  props: {
    relatedCoin: {
      default: "",
    },

    wage: {
      default: 0,
    },

  },
  methods: {
    closeModale() {
      this.$emit("closeModal", false);
    },
    async goToBankPage() {
      this.state.loading = true
      let walletInfo = {
        amount: this.$S2N(this.amount),
        paymentGateway: 'ZARRINPAL',
        cardNumber: this.walletAddress,
        callBackURL: this.$callBackURL
      }

      const res = await this.$axios.post('/wallets/deposit-toman', walletInfo)

      if (res.message === "Deposit Request Created Successfully") {
        this.$emit('close');
        window.open(res.baseDTO.redirectSite, "_blank")
      }
    },
    logOut() {
      this.$logout();
      // this.$router.push('/login')
    },
    selectItems(index) {
      this.select = this.Banks[index].cardNumber;
      this.walletAddress = this.select
      this.openDropdown = false;
    },
    async getInventory() {
      this.state.loading = true;
      let res = await this.$axios('/wallets/customer-addresses?walletType=P2P')
      this.cash = res["TOMAN"].credit

    },
    async getData() {
      this.state.loading = true
      const res = await this.$axios.get('/users/verified-bank-accounts')
      this.Banks = res.content


    },
    async getDataCoins() {
      this.state.loading = true
      const res = await this.$axios.get('/coins/details')
      this.coins = res.content
      this.role = this.coins.find((a) => a.name === "TOMAN")

    },
    async deposit() {
      this.state.loading = true
      let walletInfo = {
        amount: this.$S2N(this.amount),
        paymentGateway: 'ZARRINPAL',
        cardNumber: this.walletAddress,
        callBackURL: this.$callBackURL
      }

      const res = await this.$axios.post('/wallets/deposit-toman', walletInfo)

      if (res.message === "Deposit Request Created Successfully") {
        this.$emit('close');
        window.open(res.baseDTO.redirectSite, "_self")
      }
    },
  },
  // created: function () {
  //   window.addEventListener("click", (event) => {
  //     if (!event.target.closest(".modal,.diposit ,.submit")) {
  //       this.$emit("closeModal", this.close);
  //     } else {
  //       return true;
  //     }
  //   });
  // },
  async mounted() {
    this.getDataCoins()
    this.getInventory()
    await this.getData()
    this.select = this.Banks[0].cardNumber
    this.walletAddress = this.select

  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(111, 111, 112, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}

.modal {
  display: flex;
  row-gap: 44.5px;
  flex-direction: column;
  align-items: center;

  min-width: 431px;
  max-width: 461px;
  min-height: 276px;
  background: #141b31;
  box-shadow: 0 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 15px;
}

.close-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.message {
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .message-title {
    text-align: center;
    font-size: 18px;
    color: #febc00;
  }

  .warning {
    display: flex;
    column-gap: 5px;

    .warning-text {
      display: flex;
      flex-direction: column;

      > p {
        font-size: clamp(10px, 1vw, 14px);
        color: #9c9fc0;
      }
    }
  }
}

.amount {
  width: 100%;

  > label {
    font-size: 12px;
    color: #9c9fc0;
  }

  .price {
    color: #279bc0;
  }
}

.dropdown {
  position: relative;
  width: 100%;
  background: none !important;
  z-index: 19;

  button {
    width: 100%;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #279bc0;
    border-radius: 4px;
    min-height: 36px;
    min-width: 174px;
  }

  > label {
    display: flex;
    justify-content: flex-end;

    > a {
      color: #febc00;
      font-size: 12px;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    position: absolute;
    top: 35px;
    margin-top: 2px;
    background: rgb(10, 17, 40);
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    overflow-y: auto;
  }
}

.code-sent {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 4px;
}

.cont {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 32px;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  background: #279bc0;
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 32px;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  background: #03c70b;
}

@media screen and (max-width: 768px) {
  .modal {
    row-gap: 5px;
    max-height: unset;
    justify-content: flex-start;
    overflow-y: scroll;
    width: 90%;
    min-width: 300px;
    height: 87%;
  }
  .dropdown {
    row-gap: 0px;
    margin-bottom: 35px;
    overflow: unset;
  }
}
</style>
