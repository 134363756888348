<template>
  <div class="">
    <div class="container">
      {{ regex }}
      <div class="faq">
        <div class="text">
          <div class="icon">
            <img src="../../assets/public/faq.svg" alt="">
          </div>
          <div class="title">
            <h2>راهنمای استفاده</h2>
          </div>
        </div>
        <div class="accordians">
          <div class="search-bar">
            <input type="search" placeholder="جستجو" v-model="regex"/>
            <button>
              <svg
                  width="27"
                  height="27"
                  viewBox="0 0 30 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M11.1007 19.9394C13.1081 21.8035 15.7566 22.7873 18.5165 22.694C21.2763 22.6006 23.944 21.4371 25.9857 19.4362C28.0274 17.4352 29.2926 14.7444 29.5282 11.902C29.7638 9.0597 28.9524 6.27544 27.2563 4.1063C25.5603 1.93716 23.1046 0.543108 20.3807 0.203043C17.6568 -0.137022 14.8654 0.60198 12.5651 2.2722C10.2648 3.94242 8.62517 6.42069 7.97425 9.21118C7.32334 12.0017 7.70913 14.8986 9.05446 17.3224L9.05467 17.3207C8.98154 17.3669 8.91134 17.4169 8.84198 17.474L1.60302 23.5902C1.25023 23.8881 1.0209 24.3134 0.965475 24.7725C0.910051 25.2317 1.03307 25.6871 1.30747 26.0385C1.58187 26.39 1.98517 26.6088 2.42866 26.6467C2.87215 26.6846 3.31949 26.5385 3.67227 26.2407L10.9112 20.1244C10.9792 20.0677 11.0431 20.0059 11.1024 19.9396L11.1007 19.9394ZM17.4737 20.933C16.2662 20.8302 15.1001 20.4822 14.042 19.9088C12.984 19.3354 12.0547 18.5479 11.3072 17.5912C10.5597 16.6345 10.0087 15.5274 9.68564 14.333C9.36256 13.1387 9.27372 11.8805 9.4242 10.6303C9.57469 9.38011 9.96154 8.16239 10.5627 7.04669C11.1638 5.93099 11.9675 4.93915 12.9278 4.1278C13.8881 3.31644 14.9862 2.70147 16.1594 2.31799C17.3326 1.93451 18.558 1.79002 19.7656 1.89279C22.2044 2.10035 24.4225 3.3024 25.9321 5.23453C27.4417 7.16665 28.119 9.67057 27.8151 12.1955C27.5112 14.7203 26.2509 17.0594 24.3115 18.698C22.3722 20.3366 19.9125 21.1405 17.4737 20.933Z"
                    fill="#9C9FC0"
                />
              </svg>
            </button>
          </div>
          <div
              class="accordian"
              v-for="(item, index) in searchQuestion"
              :key="index"
          >
            <button class="btn" @click.prevent="activeAccordian(index)">
              {{ item.title }}
              <svg
                  class="handler"
                  v-if="activeIndex !== index"
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M8.39918 13.4695C8.47675 13.585 8.58053 13.6795 8.70161 13.7447C8.82268 13.81 8.9574 13.8441 9.09418 13.8441C9.23096 13.8441 9.36568 13.81 9.48675 13.7447C9.60783 13.6795 9.71161 13.585 9.78918 13.4695L17.3987 2.19781C17.4868 2.0678 17.5384 1.91552 17.548 1.75752C17.5576 1.59952 17.5248 1.44184 17.4532 1.30162C17.3816 1.16139 17.2738 1.04398 17.1417 0.962135C17.0095 0.880294 16.858 0.837154 16.7037 0.837403H1.48468C1.33067 0.838056 1.17974 0.88175 1.04813 0.963787C0.916527 1.04582 0.809215 1.1631 0.737742 1.303C0.666268 1.44291 0.633337 1.60014 0.642488 1.75781C0.65164 1.91547 0.702529 2.06759 0.789682 2.19781L8.39918 13.4695Z"
                    fill="white"
                />
              </svg>
              <inline-svg class="handler" :src="require('../../assets/public/close-acc.svg')"
                          v-if="activeIndex == index"/>
            </button>

            <transition name="fade">
              <div class="answer" v-if="activeIndex == index">
                <div v-html="item.html">

                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// import Accordian from "../tools/Accordian.vue";
import InlineSvg from "vue-inline-svg";
import Footer from "../sharing-components/footer";
// import axios from 'axios'
export default {
  name: "FAQ",
  components: {
    // Accordian,
    InlineSvg,
    Footer,
  },
  data() {
    return {
      activeIndex: null,
      questions: [],
      regex: '',

      Accordian: [
        {
          id: 1,
          question: "تاپننس چیست؟",
          show: false,
          answer:
              "   لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده\n" +
              "          از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد. لورم\n" +
              "          ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از\n" +
              "          طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.\n" +
              "    ",
        },
        {
          id: 2,
          question: "تاپننس چیست؟",
          show: false,
          answer:
              "   لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده\n" +
              "          از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد. لورم\n" +
              "          ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از\n" +
              "          طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.\n" +
              "    ",
        },
        {
          id: 3,
          question: "تاپننس چیست؟",
          show: false,
          answer:
              "   لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده\n" +
              "          از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد. لورم\n" +
              "          ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از\n" +
              "          طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.\n" +
              "    ",
        },
        {
          id: 4,
          question: "تاپننس چیست؟",
          show: false,
          answer:
              "   لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده\n" +
              "          از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد. لورم\n" +
              "          ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از\n" +
              "          طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.\n" +
              "    ",
        },
        {
          id: 5,
          question: "تاپننس چیست؟",
          show: false,
          answer:
              "   لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده\n" +
              "          از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد. لورم\n" +
              "          ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از\n" +
              "          طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.\n" +
              "    ",
        },
        {
          id: 6,
          question: "تاپننس چیست؟",
          show: false,
          answer:
              "   لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده\n" +
              "          از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد. لورم\n" +
              "          ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از\n" +
              "          طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.\n" +
              "    ",
        },
        {
          id: 7,
          question: "تاپننس چیست؟",
          show: false,
          answer:
              "   لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده\n" +
              "          از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد. لورم\n" +
              "          ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از\n" +
              "          طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.\n" +
              "    ",
        },
        {
          id: 8,
          question: "تاپننس چیست؟",
          show: false,
          answer:
              "   لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده\n" +
              "          از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد. لورم\n" +
              "          ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از\n" +
              "          طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.\n" +
              "    ",
        },
      ],
    };
  },
  computed: {
    searchQuestion() {

      return this.questions.filter(
          question => {
            console.log(this.regex)
            console.log(question)
            if (question.title.includes(this.regex)) {

              return question
            }
          }
      )


    },
  },
  methods: {
    activeAccordian(Index) {
      this.activeIndex = this.activeIndex == Index ? null : Index;
    },
    async getFaq() {
      this.state.loading=true;
      const res = await this.$axios('/user-manual')
//  console.log(res)
      this.questions = res

    },

  },
  mounted() {
    this.getFaq()

  }
};
</script>

<style lang="scss" scoped>
.answer {
  padding: 20px;
}

.close {
  svg {
    transform: rotate(180deg);
  }
}

.accordian {
  column-gap: 15px;
  padding: 0px 16px;
  color: white;
  font-size: clamp(16px, 2vw, 10px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 0.4px solid rgba(255, 255, 255, 0.5);

  border-radius: 16px;

  .btn,
  .close {
    font-size: clamp(12px, 1vw, 24px);
    font-weight: 500;
    background: transparent;
    border: 0;
    min-width: 100%;
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    min-height: 87px;
    padding: clamp(4px, 1vw, 8px);
  }

  .answer {
    > p {
      font-size: clamp(10px, 2vw, 16px);
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease-out;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}

.container {
  max-width: 1440px;
  margin-bottom: 15%;
}

.faq {
  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    column-gap: 7%;

    .icon {
    }

    .title {
      > h2 {
        color: #febc00;
        font-weight: 500;
        font-size: clamp(40px, 1vw, 20px);
      }
    }
  }

  .accordians {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .search-bar {
      padding: 2px 10px;
      margin-top: 10px;
      margin-bottom: 12px;
      width: 40%;
      min-height: 40px;
      justify-content: center;
      align-items: center;
      border: 1px solid #279bc0;
      box-sizing: border-box;
      border-radius: 16px;
      display: flex;

      > input {
        background: transparent;
        border: 0;
        width: 100%;
      }

      > button {
        background: transparent;
        border: 0;

        > svg {
          margin-top: 2px;
          margin-left: 5px;
        }
      }
    }

    padding: 16px 26px;
    padding-bottom: 74px;
    width: 100%;
    background: #141b31;
    border-radius: 16px;
  }
}

@media screen and (max-width: 1000px) {
  .icon {
    transform: scale(0.8);
  }
}

@media screen and (max-width: 831px) {
  .search-bar {
    width: 100% !important;
  }

  .icon {
    display: none;
  }

  .container {
    .faq {
      .accordian {

      }
    }
  }
}

@media (max-width: 500px) {
  .handler {
    position: absolute;
    left: 48px;
  }
  .btn {
    min-height: unset !important;
    height: 45px !important;

    svg {
      width: 10px;
    }
  }

}
</style>
