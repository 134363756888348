<template>
  <div class="personal-info">

    <label class="input-container">
      <label >نام:</label>
      <input
          class="normal-input account-input"
          @input="data.firstName = $S2Fa(data.firstName)"
          type="text"
          placeholder="نام"
          v-model="data.firstName"
          :disabled="readonly"
      />
    </label>
    <label class="input-container">
      <label >نام خانوادگی:</label>
      <input
          class="normal-input account-input"
          type="text"
          @input="data.lastName = $S2Fa(data.lastName)"
          placeholder="نام خانوادگی"
          v-model="data.lastName"
          :disabled="readonly"
      />
    </label>
    <label class="input-container">
      <label >کد ملی:</label>
      <input
          class="normal-input account-input"
          type="text"
          @input="data.nationalCode = $A2N(data.nationalCode)"
          placeholder="کد ملی"
          v-model="data.nationalCode"
          :disabled="readonly"
          maxlength="10"
      />
    </label>


    <div class="input-container">
      <label>تاریخ تولد:</label>
      <date-picker
          class="main-box"
          v-model="data.birthDay"
          :format="$dateFormat['en']"
          :display-format="$dateFormat['fa']"
          :max="$jmoment().format($dateFormat['en'])"
          :clearable="!state.userInfo.authorized"
          :disabled="readonly"
          type="date"
      />
    </div>

    <label class="input-container">
      <label > شماره ثابت:</label>
      <input
          class="normal-input account-input"
          type="text"
          @input="data.telephone = $A2N(data.telephone)"
          placeholder="شماره ثابت"
          v-model="data.telephone"
          maxlength="11"
      />
    </label >
    <label class="input-container">
      <label >شماره موبایل:</label>
      <input
          class="normal-input account-input"
          @input="data.mobileNumber = $A2N(data.mobileNumber)"
          type="text"
          placeholder="شماره موبایل"
          v-model="data.mobileNumber"
          maxlength="11"
      />
    </label>
    <label class="textarea-lable">
      <label class="small" >آدرس:</label>
      <textarea
          class="textarea"
          name="address"
          id="address"
          style="resize: none"
          placeholder="آدرس ثابت:"
          v-model="data.address"
      />
    </label>
  </div>
</template>

<script>
import "../../../style/birthdayPicker.scss";

export default {
  name: "PersonalInfo",
  props: ["data"],
  computed: {
    readonly() {
      return [
        "AUTHORIZED",
        "INFORMATION_CHANGED",
        "INFORMATION_CHANGED_PENDING",
      ].includes(this.state.userInfo.authenticationType);
    },
  },
};
</script>

<style lang="scss" scoped>


.input-container{
  display: flex;
  flex-direction: column;
  label{
    font-size: clamp(10px,1vw,12px);
  }
}


.textarea:focus {
  outline: none !important;
  border: 0.5px solid #279bc0 !important;
}

.personal-info {
  background: #0f1640;
  border-radius: 16px;
  width: 100%;
  min-height: 372px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 115px;
  row-gap: 40px;
  padding: 20px;
}

/* label {
  max-height: 36px !important;
} */
.account-input {
  max-width: 351px;
  min-width: 351px;
  flex-grow: 1;
}

.textarea-lable {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80px;
}

.textarea {
  outline: none !important;
  color: white;
  padding: 10px 15px;
  width: 100%;
  min-height: 36px;
  border: 0.5px solid #279bc0 !important;
  border-radius: 4px;
  background: transparent;
  height: 100%;

}

@media (max-width: 846px) {
  .normal-input {
    min-width: unset;
    width: 100%;
  }
}

@media (max-width: 607px) {
  label {
    width: 100%;
  }
  .normal-input {
    min-width: unset;
    width: 100%;
  }
}

@media(min-width:1440px ){
  .normal-input{
    max-height: 40px;
    height: 30px;
  }
//  .txt-label{
//    $topPercent:42%;
//position: absolute;
//    top: $topPercent;
//  }
}
</style>
