<template>
  <div class="invite-friends">
    <div class="box">
      <div class="title">
        <h2>دوستان خود را به تاپننس دعوت کنید!</h2>
        <p>با دعوت دوستان خود به تاپننس ۳۰% از کارمزد آنان را دریافت کنید .</p>
      </div>
      <div class="pictures">
        <div class="capa">
          <InlineSvg
              :src="require('../../../assets/profile/capa.svg')"
          ></InlineSvg>
        </div>
        <div class="invited">
          <InlineSvg
              :src="require('../../../assets/profile/invited.svg')"
          ></InlineSvg>
        </div>
      </div>
      <div class="invited-text">
        <h2>مجموع سود جمع شده: {{ profit }} تومان</h2>
        <h2>تعداد افراد معرفی شده : {{ invited }} نفر</h2>
        <div class="alert-link" v-if="alertLink">کپی شد</div>
      </div>

      <div class="referals">

        <div class="invite-ref">
          <p>کد دعوت</p>
          <button class="copy" @click.prevent="copyCode">
            <span> {{ refferalsCode }}</span>
            <span
            ><svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4 4.03535L5 3.03696H10.414L14 6.61719V14.0193L13 15.0177H5L4 14.0193V4.03535ZM13 7.03053L10 4.03535H5V14.0193H13V7.03053Z"
                    fill="white"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3 1.04016L2 2.03855V12.0225L3 13.0209V2.03855H9.414L8.414 1.04016H3Z"
                    fill="white"
                />
              </svg>
            </span>
          </button>
        </div>

        <div class="ref-link">
          <p>لینک دعوت</p>
          <button class="copy" @click.prevent="copyLink">
            <span class="ref-text">   {{ reffreals }}</span>
            <span
            ><svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4 4.03535L5 3.03696H10.414L14 6.61719V14.0193L13 15.0177H5L4 14.0193V4.03535ZM13 7.03053L10 4.03535H5V14.0193H13V7.03053Z"
                    fill="white"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3 1.04016L2 2.03855V12.0225L3 13.0209V2.03855H9.414L8.414 1.04016H3Z"
                    fill="white"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  components: {InlineSvg},
  data() {
    return {
      alertLink: false,
      invited: 1,
      profit: 1000,
      reffreals: "topnance.com/sign-up?ref=",
      refferalsCode: "",
    };
  },
  methods: {
    async getCode() {
      const res = await this.$axios('/users/invites')
      console.table(res);
      this.refferalsCode = res.baseDTO.referralCode
      this.reffreals = this.reffreals + this.refferalsCode
      this.invited = res.baseDTO.countInvitees
      this.profit = res.baseDTO.profit

    },
    copyLink() {
      navigator.clipboard.writeText(this.reffreals);
      this.alertLink = true;
 
    },
    copyCode() {
      navigator.clipboard.writeText(this.refferalsCode);
      this.alertLink = true;
      setTimeout(() => {
        this.alertLink = false;
      }, 1500);
    },
  },
  mounted() {
    this.getCode()
  }
};
</script>

<style lang="scss" scoped>
.invite-friends {
  width: 100%;
  padding: 0 5%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .box {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #0f1640;
    padding: 20px;
    border-radius: 16px;
    gap: 50px;

    .title {
      > h2 {
        font-weight: 500;
        font-size: (20px, 1vw, 24px);
        color: #febc00;
      }
    }

    .pictures {
      flex-wrap: wrap;
      display: flex;
      column-gap: 40px;
      justify-content: space-around;

      .invited {
        display: flex;
        flex-direction: column;
      }

      .capa {
        display: flex;
        flex-direction: column;
      }
    }

    .invited-text {
      position: relative;
      column-gap: 60px;
      flex-wrap: wrap;
      display: flex;
      justify-content: space-around;

      > h2 {
        color: #febc00;
        font-size: clamp(12px, 1vw, 16px);
        font-weight: 500;
      }
    }

    .alert-link {
      top: 100%;
      bottom: 0;
      position: absolute;
      display: flex;
      width: 100%;
      justify-content: center;
    }

    .referals {
      position: relative;
      display: flex;
      width: 100%;
      gap: 13px;
      padding: 0 40px;
      flex-wrap: wrap;

      .invite-ref {
        > p {
          padding: 16px;
        }

        flex: 1;

        max-height: 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //padding: 0, 16px;
        border: 1px solid #279bc0;
        border-radius: 4px;

        .copy {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 16px;
          background: transparent;
          border: 0;
          color: #9c9fc0;

          > span {
            margin-top: 5px;
            margin-right: 5px;
          }
        }
      }

      .ref-link {
        > p {
          padding: 16px;
        }

        flex: 1;
        //min-height: 36px;
        max-height: 35px;
        //flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //padding: 0, 16px;
        border: 1px solid #279bc0;
        border-radius: 4px;

        .copy {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 16px;
          background: transparent;
          border: 0;
          color: #9c9fc0;

          > span {
            margin-top: 5px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 602px) {
  .invite-friends {
    justify-content: center;
    padding: 10px;

    .box {

      width: 100%;
      padding: 10px;

      .pictures {
        display: none;
      }

      .referals {

        align-items: center;
        justify-content: center;
        padding: 5px;

        .ref-link {
          max-height: unset !important;
          flex-direction: column;

          .copy {
            display: flex;
            flex-direction: column;
          }

          .ref-text {
            display: none;
          }
        }

      }
    }
  }
}
</style>
