<template>
  <form class="sell-column" @input='calculate'>
    <transactionModal v-if="show" @close="show = false" :unitPrice="unitPrice" :totalPrice="totalPrice" :amount="amount"
                      type="SELL" :date="eDate" :limit="limit"/>
    <successModal v-if="SuccessSell" @closeSuccess="SuccessSell=false"/>
    <!-- created a box that contains amount inventory and base price -->
    <div class="first-row">

      <input
          type="text"
          :id="'amount' + id"
          :placeholder="danger1?'مقدار اجباریست':'مقدار' + '(' + $coins[tradeFrom].persianName + ')'"
:style="{borderColor:danger1?'red':''}"
          @input="amount = $toLocal(amount, $decimal[tradeFrom])"
          v-model="amount"
      />


      <div class="inventory">
        <div class="inventory-row">
          <div class="balance">
            <span class="gray">موجودی: </span>
            <span class="light-blue"
            >{{
                $toLocal(cash, $decimal[tradeFrom])
              }}
          </span>
            <span class="gray">{{
                $coins[tradeFrom].persianName
              }} </span>
            <a>
              <img src="../../../../assets/profile/icons/refresh.png" alt=""/>
            </a>
          </div>
          <span class="red" v-if="danger2">موجودی کافی نمی باشد.</span>
        </div>
        <div class="range-container">
          <InputRange @change="setAmount"/>

        </div>
      </div>
      <div class="teter">
        <input
            type="text"
            :placeholder="danger?'قیمت واحد اجباریست':' قیمت واحد' + '(' + $coins[tradeTo].persianName + ')'"
            @input="unitPrice = $toLocal(unitPrice, 3)"
            v-model="unitPrice"
            :style="{ borderColor: danger ? 'red' : '' }"
            :disabled="!limit"
        />
        <label class="teter-row">
          <span class="gray"> بالاترین پیشنهاد خرید: </span>
          <span class="light-blue">
            {{
              $toLocal(
                  state.shopInfo.maxBuy,
                  tradeTo === "TOMAN" ? 0 : $prcesion[tradeFrom]
              )
            }}
          </span>
          <span class="gray"> {{
              $coins[tradeTo].persianName
            }}</span>
        </label>
      </div>
    </div>
    <!-- a box that contains limit amount and submit -->
    <div class="second-row">
      <date-picker class="main-boxes" placeholder="تاریخ انقضا" v-if="limit" type="datetime"
                   v-model="eDate"
                   :format="$dateFormat['en']"
                   :display-format="$dateFormat['faDateTime']"
                   :min="$jmoment().format($dateFormat['en'])"


      />
      <div class="total-price">
        <p>مبلغ کل سفارش <span>: {{
            totalPrice
          }}</span><span>{{
            $coins[tradeTo].persianName
          }}</span></p>

        <div class="fees">
          <span>
          <span class="gray">کارمزد: </span>
          <span class="light-blue">{{
              wage
            }} </span>
        </span>
          <span v-if="danger3">مقدار ارز کمتر از حد مجاز است</span>
          <span v-if="danger4">'مقدار ارز بیشتر از حد مجاز است'</span>

        </div>
      </div>
      <button class="submit sell-btn" @click.prevent="showmodal" >ثبت فروش


      </button>
    </div>
  </form>
</template>

<script>
import {tradeTo, tradeFrom, precision, Loop} from "@/library/reuseableFunction";
import transactionModal from './transactionModal.vue';
import successModal from './successModal.vue';
import InputRange from './InputRange.vue';

export default {
  name: "",
  components: {
    transactionModal,
    successModal,
    InputRange
  },
  props: ["limit", "wage", "decimal", "id", "cash", "role"],
  data() {
    return {
      // diposit: true,
      withdrawl: false,
      label2: null,
      SuccessSell: false,
      thisModal: true,
      //  min:54654654654,
      //  fee:0.4,
      date_disable: true,
      eDate: "",
      percent: 0,
      unitPrice: '',
      amount: "",
      totalPrice: "",
      danger: false,
      danger1: false,
      danger2: false,
      danger3:false,
      danger4:false,
      show: false,
      value: "",
      checked: "",
      calculating: false,
      getAmount: false,
      amountTemp: "",
      count: 0,
      alive: true,
      loop: undefined,
      coins: "",
      priceError: "",
      amountError: "",
      minAmountOrder: null,
      maxAmountOrder: null,
      disableBuyBtn: true,
      rangeValue: 0,
    };
  },
  computed: {

    now() {
      return this.$jmoment().add(5, 'minute').format(this.$dateFormat['en'])
    },
    readonly() {
      let priceError = ""
      if (!this.state.userInfo.supervisor) {
        if (this.limit && !this.$S2N(this.unitPrice)) {
          priceError = 'قیمت واحد اجباریست'
        } else if (!this.$S2N(this.amount)) {
          priceError = 'مقدار ارز اجباریست'
        } else if (this.$S2N(this.amount) < this.min) {
          priceError = 'مقدار ارز کمتر از حد مجاز است'
        } else if (this.$S2N(this.amount) > this.max) {
          priceError = 'مقدار ارز بیشتر از حد مجاز است'
        }
        else if (this.$S2N(this.amount) > this.$S2N(this.cash)) {
          priceError = 'موجودی کافی نیست'
        }
            // else if (!this.state.userInfo.supervisor) {
            //     priceError = 'موجودی کافی نیست'
            // }
            //  else if (this.load) {
            //     priceError = 'لطفا صبر کنید...'
        // }
        else if (!this.limit && this.state.userInfo.supervisor) {
          priceError = 'بازارگردان قادر به انجام سفارش بازار نیست'
        }
      }
      if(this.state.userInfo.supervisor){
        if (this.limit && !this.$S2N(this.unitPrice)) {
          priceError = 'قیمت واحد اجباریست'
        } else if (!this.$S2N(this.amount)) {
          priceError = 'مقدار ارز اجباریست'
        } else if (this.$S2N(this.amount) < this.min) {
          priceError = 'مقدار ارز کمتر از حد مجاز است'
        } else if (this.$S2N(this.amount) > this.max) {
          priceError = 'مقدار ارز بیشتر از حد مجاز است'
        }
      }
      return priceError
    },
    load() {
      return this.calculating || this.getAmount
    },
    min() {
      return this.role.minAmount
    },
    max() {
      return this.role.maxAmount
    },
    range() {
      return 'حداقل ' + this.$toLocal(this.min) + ' ' + this.$coinLabel[this.tradeFrom] +
          '\n' + 'حداکثر ' + this.$toLocal(this.max) + ' ' + this.$coinLabel[this.tradeFrom]
    },
    tradeTo,
    tradeFrom,
    precision
  },
  watch: {
    'state.sellAmount'() {
      this.amount = this.$toLocal(this.state.sellAmount, this.decimal)
      this.unitPrice = this.$toLocal(this.state.sellPrice, this.tradeTo === 'TOMAN' ? 0 : this.$prcision[this.tradeFrom])
      this.calculate()
    },
    'state.sellPrice'() {
      this.amount = this.$toLocal(this.state.sellAmount, this.decimal)
      this.unitPrice = this.$toLocal(this.state.sellPrice, this.tradeTo === 'TOMAN' ? 0 : this.$prcision[this.tradeFrom])
      this.calculate()
    }
  },

  methods: {
    //function that changes range in the label
    setValue(e) {
      this.label = e.target.value / 100;
    },
    disableBtn() {
      if (this.$S2N(this.amount) < this.minAmountOrder) {
        this.amountError = 'حداقل میزان معامله رعایت نشده'
        this.disableBuyBtn = true
      } else if (this.$S2N(this.amount) > this.maxAmountOrder) {
        this.amountError = 'حداکثر میزان معامله رعایت نشده'
        this.disableBuyBtn = true
      } else if (((this.percent > 1 || this.percent <= 0) && !this.state.userInfo.supervisor) ||
          this.load ||
          (!this.limit && this.state.userInfo.supervisor)) {
        this.disableBuyBtn = true
      } else if ((this.$S2N(this.totalPrice) > this.$S2N(this.cash)) && !this.state.userInfo.supervisor) {
        this.priceError = 'مقدار بیش از موجودی شماست'
        this.disableBuyBtn = true
      } else if (!this.amount || !this.unitPrice) {
        this.disableBuyBtn = true
      } else {
        this.disableBuyBtn = false
        this.amountError = ''
        this.priceError = ''
      }
    },

    setAmount(value) {
      if (this.cash >= 0) {
        this.amount = this.$toLocal((this.$S2N(this.cash)) * value, this.$decimal[this.tradeFrom])
      } else this.amount = 0
      this.calculate()
      // this.disableBtn()
    },
    putcash(p) {
      this.percent = p
      let e = this.percent * this.cash
      this.amount = this.$toLocal(e, this.decimal)
      this.calculate()
    },
    setMin() {
      if (this.limit) {
        this.unitPrice = this.$toLocal(this.state.shopInfo.maxBuy, this.tradeTo === 'TOMAN' ? 0 : this.$prcision[this.tradeFrom])
        this.danger = false
        this.calculate()
      }
    },
    localCalculate() {
      const up = this.$S2N(this.unitPrice)
      const a = this.$S2N(this.amount)
      this.totalPrice = this.$toLocal((up * a), 3)
      this.percent = this.$S2N(this.amount) / this.cash
    },
    async serverCalculate() {
      this.loop ? this.loop.stop() : ''
      this.calculating = true
      const a = this.amount
      await this.$sleep(500)
      if (a === this.amount) {
        this.loop = new Loop(this.getPriceFromServer, this.state.time, 'getPriceSell')
        this.loop.start()
      }
    },
    async getPriceFromServer() {

      let amount = this.$S2N(this.amount);
      console.log(amount)
      const [res] = await this.$http.get("/orders/market-buy-sell-whole", {
        params: {
          amount: amount,
          marketType: this.tradeFrom + "_" + this.tradeTo,
          orderType: "MARKET_SELL",
        },
      });
      if (res) {
        // console.log('response server',res)
        this.totalPrice = this.$toLocal(
            res.baseDTO.wholePrice,
            this.precision
        );
        let totalPrice = this.$S2N(this.totalPrice)
        console.log('totalprice', totalPrice / amount)
        this.unitPrice = this.$toLocal(
            this.$S2N(this.totalPrice) / amount,
            this.precision
            // this.tradeTo === "TOMAN" ? 0 : this.$prcision[this.tradeFrom]
        );
        this.percent = this.$S2N(this.totalPrice) / this.cash;
      }
      this.calculating = false;

      console.log('amount', amount)
    },
    calculate() {
      if (this.limit && this.unitPrice && this.amount) {
        this.localCalculate()
      } else if (!this.limit && this.$S2N(this.amount)) {
        this.serverCalculate()
      }
    },
    showmodal() {
      if (!(!this.limit || this.$S2N(this.unitPrice))) {
        this.danger = true;
        this.danger1 = false;
        this.danger2 = false;
        this.danger3 = false;
        this.danger4 = false;
      } else if (!this.$S2N(this.amount)) {
        this.danger1 = true;
        this.danger2 = false;
        this.danger = false;
        this.danger3 = false;
        this.danger4 = false;
      } else if (
          ((this.$S2N(this.amount) > this.$S2N(this.cash)) && !this.state.userInfo.supervisor)){


        this.danger = false;
        this.danger1 = false;
        this.danger2 = true;
        this.danger3 = false;
        this.danger4 = false;

      }
      else if (this.$S2N(this.amount) < this.min) {
        this.danger2 = false;
        this.danger1 = false;
        this.danger3 = true;
        this.danger4 = false;
        this.danger = false;

      } else if (this.$S2N(this.amount) > this.max) {
        this.danger2 = false;
        this.danger1 = false;
        this.danger3 = false;
        this.danger4 = true;
        this.danger = false;

      } else {
        this.show = true;
        this.danger = false;
        this.danger1 = false;
        this.danger2 = false;
        this.danger4 = false;
        this.danger3 = false;
      }
    },
    async getCoins() {
      this.state.loading = true
      const res = await this.$axios.get('/coins/details')
      this.coins = res.content
      this.minAmountOrder = this.coins.find(a => a.name == this.tradeFrom).minAmount
      this.maxAmountOrder = this.coins.find(a => a.name == this.tradeFrom).maxAmount
    },
  },
  mounted() {
    this.label = 0.5;
    this.label2 = 0.5;
    this.getCoins()
  },
  beforeDestroy() {
    (this.loop ? this.loop.stop() : '')
  }
};
</script>

<style lang="scss" scoped>
.fees{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.red{
  color: red;
}
.number-font {
  border: none !important;
}

input[type="text"] {
  color: white;
  padding: 0 15px;
  width: 100%;
  min-height: 36px;
  border: 0.5px solid #279bc0;
  border-radius: 4px;
  background: transparent;
}

//layout styles
.trade-menu {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 8px 8px 16px 8px;
  width: 100%;
  min-height: 417.5px;
  height: 100%;
  border-radius: 16px;
  background-color: var(--blue-bg);
}

//limit and bazar buttons
.btns {
  display: flex;
  width: 100%;
  max-width: 280px;
  background: rgba(156, 152, 192, 0.1);
  min-height: 36px;
  border-radius: 8px;

  .diposit,
  .withdrawl {
    width: 100%;
    border-radius: 8px;
    background: transparent;
    color: white;
  }

  .active {
    background: #279bc0;
    border-radius: 8px;
  }
}

//inputs represents trade market menu
.inputs {
  display: flex;
  gap: 24px;

  //2 columns of the market menu box
  .buy-column,
  .sell-column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 48%;
    row-gap: 20px;
  }
}

//first-row box in sell and buy box that includes 3 el
//firs input and inventory and base price
.first-row {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  //inventory shows the volume radio button and inventory
  .inventory {
    display: flex;
    gap: 18px;
    flex-direction: column;
    align-items: flex-start;
    //the row that contains prices and range under price
    .inventory-row {
      flex-wrap: wrap;
      display: flex;
      width: 100%;
      gap: 4px;
      font-size: 12px;
      align-items: center;
      margin-top: 5px;
      .balance {
        display: flex;
        gap: 8px;
      }
      a {
        display: flex;

        img {
          align-self: center;
        }
      }
    }
  }

  //controlling the input range
  .range-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    input {
      width: 100%;
    }

    label {
      font-size: 8px;
    }
  }

  //teter input
  .teter {
    display: flex;
    flex-direction: column;

    .teter-row {
      font-size: 12px;
    }
  }
}

//a box that contains limit amount and submit
.second-row {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .total-price {
    color: var(--gray-color);
    border: 1px solid #279ac077;
    border-radius: 4px;
    padding: 8px 15px;

    span {
      align-self: center;
      font-size: 12px;
    }

    p {
      display: flex;
      gap: 4px;

      span {
        align-self: center;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 1324px) {
  .inventory {
    flex-direction: column !important;
  }

  @media (max-width: 1068px) {
    .trade-menu {
      max-width: none;
    }
  }
  @media (max-width: 502px) {
    .inputs {
      flex-wrap: wrap;
    }
  }
}
</style>
