<template>
  <div class="steps">
    <div class="container">
      <div class="title">
        <p>مراحل خرید و فروش در تاپننس</p>
        <div class="svg">
          <InlineSvg
              :src="require('../../assets/public/steps-bg.svg')"
              width="910"
          >
          </InlineSvg>
        </div>
      </div>
      <div class="steps-boxes">
        <div class="box-even">
          <div class="box-title">
            <InlineSvg
                :src="require('../../assets/public/firstbox.svg')"
                width="54"
                height="54"
            >
            </InlineSvg>
            <p>ثبت نام</p>
          </div>
          <div class="box-text">

            <p>
              فرآیند ساده ثبت نام در تاپ ننس با درج آدرس ایمیل و رمز عبور شروع شده و پس از دریافت کد تایید به صفحه درج
              مشخصات فردی و سایر اطلاعات مربوطه منتقل می شوید.
            </p>
          </div>
        </div>
        <div class="box-odd">
          <div class="box-title">
            <InlineSvg
                :src="require('../../assets/public/secondbox.svg')"
                width="51"
                height="51"
            >
            </InlineSvg>
            <p>احرازهویت</p>
          </div>
          <div class="box-text">
            <p>
              برای انجام معاملات مطمئن در تاپ ننس ابتدا باید با ورود اطلاعات فردی و بانکی و همچنین نوشتن و امضای متن دست
              نویس و ارسال آن، مراحل احراز هویت را تکمیل کنی
            </p>
          </div>
        </div>
        <div class="box-even">
          <div class="box-title">
            <InlineSvg
                :src="require('../../assets/public/thirdbox.svg')"
                width="54"
                height="54"
            >
            </InlineSvg>
            <p>انتخاب ارز</p>
          </div>
          <div class="box-text">
            <p>
              تاپ ننس با پشتیبانی از عمده ارزهای دیجیتال بازار جهانی، این امکان را به شما می دهد تا با کمترین محدودیت
              ارز مورد نظر را انتخاب کرده و معاملات خود را انجام دهید.
            </p>
          </div>
        </div>
        <div class="box-odd">
          <div class="box-title">
            <InlineSvg
                :src="require('../../assets/public/forthbox.svg')"
                width="54"
                height="54"
            >
            </InlineSvg>
            <p>خریدیافروش</p>
          </div>
          <div class="box-text">
            <p>
              خدمات آنلاین تاپننس این امکان را برای شما فراهم می کند تا با خیال راحت بتوانید در هر زمان به خرید یا فروش
              رمزارزها اقدام نمایید. </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "Steps",
  components: {
    InlineSvg,
  },
};
</script>

<style lang="scss" scoped>
.steps {
  margin-top: 40px;

  .container {
    padding-top: 120px;
    background: #0a1128;
    display: flex;
    min-height: 800px;
    flex-direction: column;
    row-gap: 30px;

    .title {
      flex-wrap: wrap;
      padding: 0 120px;
      display: flex;
      row-gap: 48px;
      align-items: center;
      position: relative;

      > p {
        color: #febc00;
        font-weight: 500;
        font-size: clamp(18px, 1vw, 24px);
        text-align: center;
      }

      .svg {
        position: absolute;
        left: 0;
      }
    }

    .steps-boxes {
      padding-top: 20px;
      width: 100%;

      display: flex;
      flex-wrap: wrap;
      row-gap: 20px;
      justify-content: center;

      .box-even {
        transition: 1s;

        flex: 1;
        max-width: 278px;
        min-height: 317px;
        margin: 0px;
        max-height: 318px;
        border: 1px solid #279bc0;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        .box-title {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding: 24px;
          padding-bottom: 0px;

          > p {
            padding: 0;
            font-weight: 500;
            font-size: clamp(24px, 1vw, 18px);
            color: #ffffff;
            margin-right: 8px;
          }
        }

        .box-text {
          > p {
            text-align: center;
            color: #9c9fc0;
            font-size: clamp(14px, 1vw, 12px);
            padding: 10px;
          }
        }

        &:hover {
          transform: translateY(25px);
        }
      }

      .box-odd {
        transition: 1s;
        flex: 1;
        max-width: 278px;
        margin: 100px 29px;
        min-height: 317px;
        border: 1px solid #279bc0;
        border-radius: 8px;
        display: flex;
        row-gap: 24px;
        flex-direction: column;

        .box-title {
          display: flex;
          align-items: center;
          padding: 24px;
          padding-bottom: 0px;

          > p {
            padding: 0;
            font-weight: 500;
            font-size: clamp(24px, 1vw, 18px);
            color: #ffffff;
          }
        }

        .box-text {
          > p {
            color: #9c9fc0;
            font-size: clamp(14px, 1vw, 12px);
            text-align: center;
            padding: 10px;
          }
        }

        &:hover {
          transform: translateY(25px);
        }
      }

      .box-odd:last-child {
        align-self: center;
      }
    }
  }
}

@media screen and (max-width: 1426px) {
  .steps {
    padding-bottom: 20px;
    row-gap: 10px;

    .steps-boxes {
      .box-odd:last-child {
        margin-top: 0px;
      }
    }
  }
}

@media screen and (max-width: 730px) {
  .steps {
    .container {
      padding: 10px;

      .title {
        width: 100%;
        justify-content: center;
        padding: 10px;

        .svg {
          display: none;
        }
      }

      .steps-boxes {
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        row-gap: 0;
        column-gap: 0;

        height: auto;

        .box-even {
          margin: 20px auto;
          flex: 0;
          width: 100%;
        }

        .box-odd {
          margin: 20px auto;
          flex: 0;
          width: 100%;
        }
      }
    }
  }
}
</style>
