<template>
  <div class="change">
    <span class="change-title"> تغییر رمز عبور </span>
    <span class="describe">
      برای تغییر رمزعبور ابتدا رمز عبور فعلی خود را وارد کنید
    </span>
    <form @submit.prevent="submit" class="form-control">
      <div class="inputs-container">
        <label >
          <input
              :type="password0 ? 'password' : 'text'"
              placeholder="رمز عبور فعلی"
              @input="passError = ''"
              v-model="oldpassword"
          />
          <!-- <img class="pass-img" src="@/assets/Home/showPass.png" alt="" @click="password0 = !password0"> -->
          <inline-svg
              class="pass-img"
              :class="{ 'show-pass-img': !password0 }"
              :src="require('@/assets/Home/showPass.svg')"
              alt=""
              @click="password0 = !password0"
          ></inline-svg>
          <span class="line" :class="{ 'line-hide': !password0 }"></span>
          <!-- <a>{{passError}}</a> -->
        </label>
        <label >
          <input
              :type="password1 ? 'password' : 'text'"
              placeholder="رمز عبور جدید"
              @input="pass1Error = ''"
              v-model="newpassword1"
          />
          <!-- <img class="pass-img" src="@/assets/Home/showPass.png" alt="" @click="password1=!password1"> -->
          <inline-svg
              class="pass-img"
              :class="{ 'show-pass-img': !password1 }"
              :src="require('@/assets/Home/showPass.svg')"
              alt=""
              @click="password1 = !password1"
          ></inline-svg>
          <span class="line" :class="{ 'line-hide': !password1 }"></span>
          <!-- <a>{{pass1Error}}</a> -->
        </label>
       <label >
          <input
              :type="password2 ? 'password' : 'text'"
              placeholder=" تکرار رمز عبور جدید "
              @input="pass2Error = ''"
              v-model="newpassword2"
          />
          <!-- <img class="pass-img" src="@/assets/Home/showPass.png" alt="" @click="password2=!password2"> -->
          <inline-svg
              class="pass-img"
              :class="{ 'show-pass-img': !password2 }"
              :src="require('@/assets/Home/showPass.svg')"
              alt=""
              @click="password2 = !password2"
          ></inline-svg>
          <span class="line" :class="{ 'line-hide': !password2 }"></span>
          <!-- <a>{{pass2Error}}</a> -->
        </label>
      </div>
      <button
          class="change-btn"
          type="submit"
          :disabled="!oldpassword || !newpassword1 || !newpassword2"
      >
        ثبت
      </button>
    </form>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "ChangePass",
  components: {
    InlineSvg,
  },

  data() {
    return {
      oldpassword: "",
      newpassword1: "",
      newpassword2: "",
      passError: "",
      pass1Error: "",
      pass2Error: "",
      password0: true,
      password1: true,
      password2: true,
    };
  },
  methods: {
    async submit() {
      let passSent = {
        oldPassword: this.oldpassword,
        password: this.newpassword1,
      };
      await this.check2Pass();
      if (this.newpassword1 && this.newpassword2) {
        if (this.checkPass()) this.state.loading = true;
        try {
          const res = await this.$axios.post(
              `/users/change-password`,
              passSent
          );
          if (res.message === "password changed successfully") {
            this.$error("رمز شما تغییر یافت", "", "success");
            this.oldpassword = "";
            this.newpassword1 = "";
            this.newpassword2 = "";
          }
        } catch (error) {
          if (error.response.data.message === "Old Password Is Incorrect") {
            this.passError = "رمز قبلی اشتباه است.";
          }
        }
      }
    },
    checkPass() {
      if (!this.oldpassword) {
        this.passError = "رمز فعلی اجباری";
      } else {
        this.passError = "";
      }
      if (this.newpassword1) {
        if (this.newpassword1.length < 8) {
          this.pass1Error = "حداقل 8 کاراکتر";
        } else if (!this.validPass(this.newpassword1)) {
          this.pass1Error = "ترکیبی از حروف کوچک و بزرگ و عدد";
        } else if (!this.newpassword2) {
          this.pass1Error = "";
          this.pass2Error = "تکرار رمز اجباری";
        } else if (this.newpassword2 !== this.newpassword1) {
          this.pass1Error = "";
          this.pass2Error = "تکرار رمز مطابقت ندارد";
        } else {
          this.pass2Error = "";
        }
      }
      if (
          !this.passError.length &&
          !this.pass1Error.length &&
          !this.pass2Error.length
      ) {
        return true;
      }
    },
    async check2Pass() {
      if (!this.$validPass(this.oldpassword) || this.oldpassword.length < 8) {
        this.$error(
            "اطلاعات وارد شده اشتباه است",
            "رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد"
        );
        return Promise.reject("wrong pass : " + this.oldpassword);
      }
      if (this.newpassword1 !== this.newpassword2) {
        this.$error("اطلاعات وارد شده اشتباه است", "تکرار رمز اشتباه است");
        return Promise.reject(
            "repeat failed : " + this.newpassword1 + "!==" + this.newpassword2
        );
      }
    },
    validPass(password) {
      const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
      return re.test(password);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  display: flex;
  flex-direction: column;
  // gap: 16px;
}

.change {
  flex-grow: 3;
  // max-height: 181px;
  // background: #001E3D;
  border-radius: 8px;
  // max-width: 704px;
  // height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  box-sizing: border-box;
  //   padding: 24px 16px;
  // flex-basis: 300px;

  .change-title {
    font-weight: bold;
    font-size: clamp(18px, 2vw, 24px);
    color: #FEBC00;
  }

  .describe {
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
  }

  .inputs-container {
    display: flex;
    align-items: center;
    column-gap: 16px;
    row-gap: 16px;
    margin: 16px 0;
    flex-wrap: wrap;

    // justify-content: center;
    input {
      width: 100%;
      height: 100%;
      background: transparent;
      border: 0;

    }

    label {
      border: 0.5px solid #279bc0;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      //   max-width: 218px;
      //   min-width: 150px;
      flex-grow: 1;
      padding: 5px;
    }
  }
}

.pass-img {
  align-self: center;
}

.change-btn {
  align-self: flex-end;
  background: #279BC0;
  border-radius: 4px;
  border-radius: 6px;
  color: #fcfcfc;
  width: 203px;
  max-height: 36px;
  flex-grow: 1;
  max-width: 168px;
  min-width: 100px;
  flex-basis: 130px;
}

// .pass-img {
//     top: 7px;
// }
</style>
