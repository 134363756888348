<template>
  <div class="banner">
    <div class="container">
      <div class="text-banner">
        <h1>خرید و فروش بیت کوین و ارز های دیجیتال</h1>
        <p>به امن ترین بازار ارز دیجیتال ایران بپیوندید</p>
        <a
            v-if="!state.token"
            class="start-btn"
            href="/sign-up"
            @click.prevent="$router.push('/sign-up')"
        >شروع کنید</a
        >
        <a
            v-else
            class="start-btn"
            href="/sign-up"
            @click.prevent="$router.push('/profile')"
        >شروع کنید</a
        >
      </div>
      <div class="buy-crypto">
        <buy-crypto/>
      </div>
    </div>

  </div>
</template>

<script>
import BuyCrypto from "./BuyCrypto.vue";

export default {
  name: "banner",
  components: {
    // Dummy,
    BuyCrypto
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>

//.slider {
//  z-index: 8 !important;
//  background: #0f1640 !important;
//
//  position: absolute;
//  bottom: 0;
//}


.banner {
  width: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 10px;
  min-height: 723px;
  position: relative;
  display: flex;
  justify-content: center;
  background-image: url("../../assets/public/banner-background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 100px 10px 0;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
  }

  .text-banner {
    margin-top: 55px;
    display: flex;
    flex-direction: column;

    h1 {
      color: #febc00;
      font-weight: 500;
      font-size: clamp(20px, 2vw, 40px);
      margin-top: 50px;
      margin-bottom: 16px;
    }

    p {
      color: white;
      font-weight: 500;
      font-size: clamp(14px, 2vw, 24px);
      margin-bottom: 16px;
    }

    .start-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 220px;
      min-height: 48px;
      background: linear-gradient(0deg, #febc00, #febc00), #19196f;
      border-radius: 16px;
      color: black;
      font-size: clamp(12px, 2vw, 24px);
    }

    .buy-crypto {
      display: flex;
      justify-content: flex-end;
    }
  }

  .banner::after {
    //z-index: -1;
    content: "";
    background-color: rgb(0, 0, 0);
    background-image: url("../../assets/public/banner-background.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}


@media screen and (max-width: 900px) {
  .banner {
    justify-content: center;

    .container {
      margin: 0px;

      .text-banner {
        margin-top: 0;

        .start-btn {
          align-self: center;
          width: 100%;
        }
      }

      justify-content: center;

      .buy-crypto {
        justify-content: center;
      }
    }
  }
}
</style>
