<template>
  <div class="depth">
    <div class="title">
      <p class="sell">فروشندگان</p>
    </div>

    <div class="table-head">

      <p class="gray">مقدار({{ $coins[tradeFrom].code }})</p>
      <p class="gray" id="price">قیمت واحد({{ $coins[tradeTo].persianName }})</p>
      <p class="gray">قیمت کل({{ $coins[tradeTo].persianName }})</p>
    </div>
    <div v-if="!tableContentsSell.length">
      <p style="width:100%;text-align:center;color:red">در حال حاضر اطلاعاتی وجود ندارد</p>
    </div>
    <div class="content-container" v-for="(tableContent, index) in tableContentsSell" :key="index" @click.prevent="getSell(tableContent)">

      <div class="background" :style="{ width: tableContent.executedPercent + '%' }"></div>
      <div class="content">
        <p>{{ $toLocal(tableContent.volume, $decimal[tradeFrom]) }}</p>
        <p class="text-center" id="price-value">
          {{ $toLocal(tableContent.unitPrice, tradeTo === 'TOMAN' ? 3: 3) }}</p>
        <p> {{ $toLocal(tableContent.wholePrice, 3) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  tradeTo,
  tradeFrom,
  // precision,
  Loop
} from "@/library/reuseableFunction";

export default {
  name: "DepthSel",
  data() {
    return {
      tableContentsSell: [],
      loading: false,
      loop: '',
    };
  },
  computed: {
    tradeFrom,
    tradeTo,
    precision() {
      return this.$decimal[this.tradeFrom] || 8
    }
  },
  methods: {
    getSell:function(item){
      console.log(item)
      this.state.buyAmount = item.volume;
      this.state.buyPrice = item.unitPrice;
    },
    async getMarketDepth() {
      // this.state.loading=true
      const res = await axios.get('/orders/order-book', {
        params: {
          marketType: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo
        }
      })
      if (res) {
        this.tableContentsSell = res.sellOrderBook.slice(0, 11).sort((a, b) => {
          a.unitPrice > b.unitPrice
        })
        this.state.shopInfo.minSell = res.sellOrderBook.length ? res.sellOrderBook[0].unitPrice : 0
      }
      // this.state.loading=false
    }

  },
  mounted() {
    this.state.loading = true;
    this.loading = true
    if (this.state.loop) {
      this.loop = new Loop(this.getMarketDepth, this.state.time, 'marketDepth:' + this.tradeFrom + '-' + this.tradeTo)
      this.loop.start()
    } else {
      this.getMarketDepth()
    }
    this.getMarketDepth()
    this.state.loading = false;
  },
  beforeDestroy() {
    this.loop.stop()
  },
};
</script>

<style lang="scss" scoped>
.depth {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  padding: 8px 12px;
  position: relative;
  background: #0f1640;
  border-radius: 8px;
  overflow: scroll;
  max-height: 305px;
  min-height:  305px;
}

.title {
  height: 38px;

  p {
    text-align: right !important;
    font-size: 16px;
  }
}

.table-head {
  background: #0f1640 !important;
  position: sticky;
  top: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  z-index: 10;
}

//all the content is here
.content {
  align-items: center;
  display: grid;
  width: 100%;
  height: 24px;
  row-gap: 0px;
  grid-template-columns: 1fr 1fr 1fr;
  z-index: 2;

  p {
    z-index: 3;
  }

  p:last-child {
    text-align: left;
  }
}

.background {
  border-radius: 4px;
  position: absolute;
  background-color: #e5375771;
  height: 24px;
  left: 0;
  z-index: 0;
}

.content-container {
  position: relative;
  height: 24px;
  cursor: pointer;
}

@media (max-width: 860px) {
  #price, #price-value {
    display: none;
  }
  .content {
    grid-template-columns: 1fr 1fr;
  }
}
</style>





